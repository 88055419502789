import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ProductToAdd } from '../product-to-add';
import { FormControl, FormGroup } from '@angular/forms';
import { ProductTagManager } from 'src/app/product-tag-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { LanguagesManager } from 'src/app/languages-manager.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-product-tag-page',
  templateUrl: './edit-product-tag-page.component.html',
  styleUrls: ['./edit-product-tag-page.component.scss']
})
export class EditProductTagPageComponent implements OnInit {
  tabs: MenuItem[] | undefined;

  activeTab: MenuItem | undefined;

  sourceProducts: ProductToAdd[] = [];
  targetProducts: ProductToAdd[] = [];

  public formGroup!: FormGroup;
  public nameFormGroup!: FormGroup;

  public isLoading: boolean = true;

  public productTagId: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productTagManager: ProductTagManager,
    private langaugeManager: LanguagesManager,
    private translate: TranslateService,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;
    
    this.formGroup = new FormGroup({});

    this.tabs = [
        { label: 'Nazwa', icon: 'pi pi-fw pi-language', id: 'name' },
        { label: 'Produkty', icon: 'pi pi-fw pi-table', id: 'products' },
    ];

    this.activeTab = this.tabs[0];
    
    this.nameFormGroup = new FormGroup({}, [
      atLeastOne,
    ]);
    
    const { productTagId } = await firstValueFrom(this.route.params)    

    this.productTagId = productTagId;

    const languages = await this.langaugeManager.getLanguages(1, 0);

    const { id, name } = await this.productTagManager.getProductTag(this.productTagId);
    
    for(let lang of languages) {
      const translations = await firstValueFrom(this.translate.getTranslation(lang.alpha2));
      const value = translations[name] ?? '';
      this.nameFormGroup.addControl(lang.alpha2, new FormControl(value));
    }

    this.formGroup.addControl('names', this.nameFormGroup);

    this.isLoading = false;
  }

  public getEntriesOfNames() {
    return Object.keys(this.nameFormGroup.controls);
  }

  public async submit() {
    try {
      this.isLoading = true;

      const names = Object.keys(this.nameFormGroup.controls).reduce<Record<string, string>>((acc, controlName) => {
        const formControl = this.nameFormGroup.get(controlName);
        if(formControl?.dirty) acc[controlName] = formControl.value;
        return acc;
      }, {});

      const options: { names?: Record<string, string>; } = {};

      if(Object.keys(names).length > 0) options['names'] = names;

      const response = await this.productTagManager.updateProductTag(this.productTagId, options);

      if(response) await this.router.navigate(['/product-tags']);

    } catch (err) {

    } finally {
      this.isLoading = false;
    }
  }
  
  onTabChange(event: MenuItem) {
    this.activeTab = event;
  }
}
