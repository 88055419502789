import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { LanguagesManager } from 'src/app/languages-manager.service';
import { ServerErrorMessage } from 'src/app/messages';
import { TranslationManager } from 'src/app/translation-manager.service';

@Component({
  selector: 'app-add-translation-page',
  templateUrl: './add-translation-page.component.html',
  styleUrls: ['./add-translation-page.component.scss']
})
export class AddTranslationPageComponent implements OnInit {
  
  public isLoading: boolean = true;

  public formGroup!: FormGroup;

  constructor(
    private languagesManager: LanguagesManager,
    private translationManager: TranslationManager,
    private router: Router,
    private messageService: MessageService
  ) {}

  public ngOnInit(): void {
    this.formGroup = new FormGroup({
      id: new FormControl('', [
        Validators.required,
      ]),
      translations: new FormGroup({}, {
        validators: [
          atLeastOne,
        ],
      }),
    });

    this.refreshLanguage();
  }

  public async refreshLanguage() {
    const languages = await this.languagesManager.getLanguages(1, 0);
    
    const group: FormGroup = this.formGroup.get('translations')! as FormGroup;

    group.controls = {};

    languages.forEach((language: any) => {
      group.addControl(language.alpha2, new FormControl());
    });
  }

  public getTranslationFields() {
    const group = this.formGroup.get('translations')! as FormGroup;
    return Object.keys(group.controls);
  }

  public async submit() {
    try {
      this.isLoading = true;
      const translationsGroup = this.formGroup.get('translations')! as FormGroup;

      const formControlId = this.formGroup.get('id')!;

      const translations = Object.keys(translationsGroup.controls).reduce<Record<string,string>>((acc, key) => {
        const value = translationsGroup.get(key)?.value;
        if(value === null || value === '' || typeof value === 'undefined') return acc;
        acc[key] = value;
        return acc;
      }, {});

      const response = await this.translationManager.createTranslation({
        id: formControlId.value,
        translations: translations,
      });

      if(response) this.router.navigate(['/translations']);
    } catch (err) {
      this.messageService.add(ServerErrorMessage);
    } finally {
      this.isLoading = false;
    }
  }

}
