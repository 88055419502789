import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { MessageService } from 'primeng/api';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private messageService: MessageService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
      if(error.status !== 401) return throwError(() => error);
      if(this.router.url === '/login') return throwError(() => error);
      
      this.logger.debug('REDIRECT BECAUSE OF RESPONSE:', error.status);
      this.messageService.add({
        severity: 'error',
        summary: 'Błąd',
        detail: 'Sesja została zakończona',
      });
      
      this.router.navigate(['/login']);
      return throwError(() => error);
    }));
  }
}
