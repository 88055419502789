import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveUserManager {

  public id: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';

  constructor(
    private apiClient: ApiClientService,
  ) {
    this.getActiveUser()
  }

  public async getActiveUser() {
    const {
      id,
      firstName,
      lastName,
      email
    } = await this.apiClient.get<{id: string; email: string; firstName: string; lastName: string; organizationName: string;}>('/users/self');

    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }
}
