import { Component, OnInit } from '@angular/core';
import { TablePageEvent } from 'primeng/table';
import { SubscriptionManager } from 'src/app/subscription-manager.service';

@Component({
  selector: 'app-subscription-list-page',
  templateUrl: './subscription-list-page.component.html',
  styleUrls: ['./subscription-list-page.component.scss']
})
export class SubscriptionListPageComponent implements OnInit {

  public page: number = 1;
  public itemsPerPage: number = 25;
  public totalRecords: number = 0;

  public isLoading: boolean = false;

  public subscriptions: {
    isLoading: false;
    ID: string;
    name: string;
    users: number;
  }[] = [];

  constructor(
    private subscriptionManager: SubscriptionManager,
  ) {}

  public ngOnInit(): void {
    this.refreshList();
  }

  public async refreshList() {
    try {
      this.isLoading = true;
      const {data, totalAmount} = await this.subscriptionManager.getSubscriptions(this.page, this.itemsPerPage);
      this.subscriptions = data;
      this.totalRecords = totalAmount;
    } catch (err) {
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  public onPageChange(event: TablePageEvent) {
    this.page = event.first/this.itemsPerPage + 1;
    this.refreshList();
  }

  public async deleteSubscription(subscriptionId: string) {
    this.subscriptionManager.deleteSubscriptions([subscriptionId]);
  }
}
