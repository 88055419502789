import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { LanguagesManager } from 'src/app/languages-manager.service';
import { ServerErrorMessage } from 'src/app/messages';
import { PostsManager } from 'src/app/posts-manager.service';

@Component({
  selector: 'app-edit-post-page',
  templateUrl: './edit-post-page.component.html',
  styleUrls: ['./edit-post-page.component.scss']
})
export class EditPostPageComponent {
  public tabs: MenuItem[] | undefined;

  public activeTab: MenuItem | undefined;

  public sourcePostTags: any[] = [];
  public targetPostTags: any[] = [];

  public formGroup!: FormGroup;
  public mainImageFormControl!: FormControl;

  public isLoading: boolean = true;
  public isSaving: boolean = false;

  public postId: string = '';

  constructor(
    private langaugeManager: LanguagesManager,
    private messageService: MessageService,
    private postsManager: PostsManager,
    private route: ActivatedRoute,
    private router: Router,
    private translator: TranslateService,
    private http: HttpClient,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;

    const { postId } = await firstValueFrom(this.route.params);

    this.postId = postId;

    const data = await this.postsManager.getPost(postId);

    this.formGroup = new FormGroup({
      'author': new FormControl(data.author, [Validators.required]),
      'url': new FormControl(data.url, []),
    });

    const languages = await this.langaugeManager.getLanguages(1, 0);

    const selectedTags = this.targetPostTags = data.tags;
    const tagsPromise = this.postsManager.getPostsTags().then(({data}) => {
      this.sourcePostTags = data;
      this.sourcePostTags = this.sourcePostTags.filter((sourceTag) => {
        return !this.targetPostTags.some(targetTag => sourceTag.id === targetTag.id );
      });
    });

    const nameFormGroup = new FormGroup({}, [atLeastOne]);
    const contentFormGroup = new FormGroup({}, [atLeastOne]);
    const summaryFormGroup = new FormGroup({}, [atLeastOne]);
    const leadingsFormGroup = new FormGroup({}, [atLeastOne]);
    this.mainImageFormControl = new FormControl('', [
      Validators.required,
    ]);

    const logoPromise = firstValueFrom(this.http.get(data.leadingImageUrl, {observe: 'response', responseType: 'blob'})).then((response) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(response.body!);

      fileReader.onload = (ev) => {
        this.mainImageFormControl.setValue(ev.target?.result!);
      }
    });

    for (const language of languages) {
      const translations = await firstValueFrom(this.translator.getTranslation(language.alpha2));
      console.log(data.editContent[language.alpha2]);
      nameFormGroup.addControl(language.alpha2, new FormControl(translations[data.name]));
      contentFormGroup.addControl(language.alpha2, new FormControl(data.editContent[language.alpha2]));
      summaryFormGroup.addControl(language.alpha2, new FormControl(translations[data.summary]));
      leadingsFormGroup.addControl(language.alpha2, new FormControl(translations[data.leading]));
    }

    this.formGroup.addControl('names', nameFormGroup);
    this.formGroup.addControl('contents', contentFormGroup);
    this.formGroup.addControl('summaries', summaryFormGroup);
    this.formGroup.addControl('leadings', leadingsFormGroup);
    this.formGroup.addControl('imageContent', this.mainImageFormControl);

    this.tabs = [
      {
        label: 'Główny obrazek',
        icon: 'pi pi-fw pi-image',
        id: 'leadingImage',
      },
      {
        label: 'Tytuł',
        icon: 'pi pi-fw pi-language',
        id: 'name'
      },
      {
        label: 'Podsumowanie',
        icon: 'pi pi-fw pi-language',
        id: 'summary'
      },
      {
        label: 'Zajawka',
        icon: 'pi pi-fw pi-language',
        id: 'leading'
      },
      {
        label: 'Treść',
        icon: 'pi pi-fw pi-language',
        id: 'content'
      },
      {
        label: 'Autor',
        icon: 'pi pi-fw pi-user',
        id: 'author'
      },
      {
        label: 'Url',
        icon: 'pi pi-fw pi-link',
        id: 'url'
      },
      {
        label: 'Tagi',
        icon: 'pi pi-fw pi-hashtag',
        id: 'tags'
      },
    ];

    this.activeTab = this.tabs[0];

    await Promise.all([tagsPromise, logoPromise]);

    this.isLoading = false;
  }

  public async submit() {
    try {
      this.isSaving = true;

      const nameFormGroup = this.formGroup.get('names') as FormGroup;
      const summaryFormGroup = this.formGroup.get('summaries') as FormGroup;
      const leadingFormGroup = this.formGroup.get('leadings') as FormGroup;
      const authorFormControl = this.formGroup.get('author') as FormControl;
      const urlFormControl = this.formGroup.get('url') as FormControl;
      const contentFormGroup = this.formGroup.get('contents') as FormGroup;

      const data: any = {
        id: this.postId,
        tagIds: this.targetPostTags.map((tag) => tag.id),
      };

      if(nameFormGroup.dirty) {
        data.name = Object.entries(nameFormGroup.controls).reduce<Record<string, string>>((acc, [key, formControl]) => {
          if(formControl.value) acc[key] = formControl.value;
          return acc;
        }, {});
      }

      if(summaryFormGroup.dirty) {
        data.summary = Object.entries(summaryFormGroup.controls).reduce<Record<string, string>>((acc, [key, formControl]) => {
          if(formControl.value) acc[key] = formControl.value;
          return acc;
        }, {});
      }

      if(leadingFormGroup.dirty) {
        data.leading = Object.entries(leadingFormGroup.controls).reduce<Record<string, string>>((acc, [key, formControl]) => {
          if(formControl.value) acc[key] = formControl.value;
          return acc;
        }, {});
      }

      if(urlFormControl.dirty) {
        data.customUrl = urlFormControl.value;
      }

      if(authorFormControl.dirty) {
        data.author = authorFormControl.value;
      }

      if(this.mainImageFormControl.dirty) {
        data.image = this.mainImageFormControl.value;
      }

      if(contentFormGroup.dirty) {
        data.content = Object.entries(contentFormGroup.controls).reduce<Record<string, object>>((acc, [key, formControl]) => {
          if(formControl.value?.ops?.length > 0) acc[key] = formControl.value;
          return acc;
        }, {});
      }

      const response = await this.postsManager.updatePost(data);
      if (response) this.router.navigate(['/posts']);
    } catch (err) {
      console.error(err);
      this.messageService.add(ServerErrorMessage);
    } finally {
      this.isSaving = false;
    }
  }

  public onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  public getFormControlsByName(name: string) {
    return Object.keys((this.formGroup.get(name) as FormGroup).controls);
  }

  public onMainImageUpload(ev: Event) {
    this.mainImageFormControl.markAsTouched();
    const event = ev as (Event & {target: HTMLInputElement & { files: FileList | null}})

    if(!event?.target?.files) return;

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (ev) => {
      this.mainImageFormControl.setValue(ev.target?.result!);
      this.mainImageFormControl.markAsDirty();
    }

    event.target.value = '';
  }
}
