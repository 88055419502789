import { Injector } from "@angular/core";
import { TranslateLoader, TranslateService } from "@ngx-translate/core";
import { Observable, ReplaySubject, Subject } from "rxjs";

export class SseTranslationLoader implements TranslateLoader {

  private observable: Record<string, ReplaySubject<any>>;
  private eventSource: Record<string, EventSource>;

  constructor(
    private httpAddress: string,
    private injector: Injector,
  ) {
    this.observable = {};
    this.eventSource = {};
  }

  getTranslation(lang: string): Observable<any> {
    if(this.observable[lang]) return this.observable[lang];

    let eventSource = this.eventSource[lang];

    if(!eventSource) {
      eventSource = this.eventSource[lang] = new EventSource(`${this.httpAddress}${lang}`);
    }

    this.observable[lang] = new ReplaySubject(1);

    eventSource.onmessage = (event) => {
      const translateService: TranslateService = this.injector.get(TranslateService);

      const messageData: any = JSON.parse(event.data);

      translateService.setTranslation(lang, messageData);

      this.observable[lang].next(messageData)
    }

    return this.observable[lang];
  }
}