import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostsManager {

  constructor(
    private apiClient: ApiClientService,
  ) {}

  public getPost(postId: string) {
    return this.apiClient.get<{
      author: string;
      id: string;
      leadingImageUrl: string;
      editContent: Record<string, object>;
      name: string;
      summary: string;
      leading: string;
      url?: string | undefined;
      tags: {
        id: string;
        name: string;
      }[];
    }>(`/admin/posts/${postId}`);
  }

  public getPosts(options: {
    itemsPerPage?: number;
    page?: number;
    searchBy?: {
      fullTextSearch?: string;
    }
  }) {
    const httpParams = new URLSearchParams();

    if(options.itemsPerPage && options.page) {
      httpParams.append('itemsPerPage', options.itemsPerPage.toString());
      httpParams.append('page', options.page.toString());
    }

    if(options.searchBy?.fullTextSearch) {
      httpParams.append('search', options.searchBy.fullTextSearch);
    }

    return this.apiClient.get<{
      totalAmount: number;
      data: {
        id: string;
        name: string;
        langauges: string[];
        tags: string[];
      }[];
    }>('/admin/posts', httpParams);

  }

  public updatePost(options: {
    id: string;
    name?: Record<string, string>;
    summary?: Record<string, string>;
    leading: Record<string, string>;
    url?: string | undefined;
    content?: Record<string, object>;
    image?: string;
    author?: string;
    tagIds?: string[];
  }) {
    return this.apiClient.put(`/admin/posts/${options.id}`, options);
  }

  public addPost(options: {
    name: Record<string, string>;
    summary: Record<string, string>;
    leading: Record<string, string>;
    url?: string | undefined;
    content: Record<string, object>;
    image: string;
    author: string;
    tagIds: string[];
  }) {
    return this.apiClient.post(`/admin/posts`, options);
  }

  public deletePost(postId: string) {
    return this.apiClient.delete(`/admin/posts/${postId}`);
  }

  public editPost(postId: string, options: {
    name?: Record<string, string>;
    summary?: Record<string, string>;
    content?: Record<string, object>;
    image?: string;
    author?: string;
    tagIds?: string[];
  }) {
    return this.apiClient.put(`/admin/posts/${postId}`, options);
  }

  public getPostsTag(id: string) {
    return this.apiClient.get<{
      id: string;
      name: string;
      image: string;
    }>(`/admin/posts-tags/${id}`);
  }

  public getPostsTags(options?: {
    itemsPerPage?: number;
    page?: number;
    searchBy?: {
      fullTextSearch?: string;
    };
  }) {
    const httpParams = new URLSearchParams();

    if(options?.itemsPerPage && options?.page) {
      httpParams.append('itemsPerPage', options.itemsPerPage.toString());
      httpParams.append('page', options.page.toString());
    }

    if(options?.searchBy?.fullTextSearch) {
      httpParams.append('search', options.searchBy.fullTextSearch);
    }

    return this.apiClient.get<{
      data: {
        id: string;
        name: string;
        postsCount: number;
      }[];
      totalAmount: number;
    }>('/admin/posts-tags', httpParams);
  }

  public addPostTag(options: {
    name: Record<string, string>;
    image: string;
  }) {
    return this.apiClient.post('/admin/posts-tags', options);
  }

  public deletePostTag(postTagId: string) {
    return this.apiClient.delete(`/admin/posts-tags/${postTagId}`);
  }

  public editPostTag(postTagId: string, options: {
    name: Record<string, string>;
    image: string;
  }) {
    return this.apiClient.put(`/admin/posts-tags/${postTagId}`, options);
  }

}
