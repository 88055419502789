import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ApiClientService } from '../api-client.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {
  items: MenuItem[] | undefined;

  activeItem!: MenuItem;

  constructor(
    private apiClient: ApiClientService, 
    private messageService: MessageService,
  ) {}

  ngOnInit() {
      this.items = [
          { label: 'Główne', id: 'general', icon: 'pi pi-fw pi-cog' },
          { label: 'Informacje o serwerze', id: 'server_info', icon: 'pi pi-fw pi-server' },
      ];

      this.activeItem = this.items[0];
  }

  onTabChange(event: MenuItem) {
    this.activeItem = event;
  }

  public async resetCache() {
    await this.apiClient.post('/jobs/clear-cache');
    this.messageService.add({
      summary: 'Zadanie wyczyszczenia cache dodane do kolejki',
      detail: 'Teraz poczekaj chwilę i Redis powinien być pusty',
      severity: 'info',
    });
  }
}
