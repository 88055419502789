import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(private apiClient: ApiClientService) {}

  public getStatistics() {
    return this.apiClient.get<{
      deploymentsTypes: number;
      languages: number;
      productTags: number;
      products: number;
      subscriptions: number;
      translations: number;
      users: number;
    }>('/admin/statistics');
  }
}
