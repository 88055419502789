<div class="flex flex-column h-full flex-auto">
  <p-table styleClass="white-header" [value]="languages" [loading]="isLoading" ngClass="h-full" [scrollable]="true" scrollHeight="flex" [paginator]="true" [rows]="itemsPerPage" [totalRecords]="totalRecords" (onPage)="onPageChange($event)" dataKey="id">
      <ng-template pTemplate="caption">
          <div class="flex flex-row justify-content-between">
            <div class="flex align-items-center">
              <p class="m-0 font-bold text-2xl">Języki</p>
            </div>
            <div class="flex flex-row">
              <a [routerLink]="['add']" class="no-underline flex flex-row gap-2 align-items-center border-1 border-round-md py-2 px-3 bg-primary">
                <i class="pi pi-plus"></i>
                <span>Dodaj</span>
              </a>
              <button title="Wyczyść" type="button" pButton label="Wyczyść" class="p-button-outlined ml-2" icon="pi pi-filter-slash"></button>
              <span class="p-input-icon-left ml-2">
                  <i class="pi pi-search"></i>
                  <input class="input-bg-grey" pInputText type="text" placeholder="Wyszukuj język" />
              </span>
            </div>
          </div>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="200" class="text-center">
            Nie znaleziono języków
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="header">
          <tr>
              <th>
                lp.
              </th>
              <th>
                Kod Alfa-2
              </th>
              <th>
                Kod Alfa-3
              </th>
              <th>
                Nazwa
              </th>
              <th>
                Opcje
              </th>
          </tr>
      </ng-template>

      <ng-template pTemplate="body" let-language let-rowIndex="rowIndex">
        <tr>
            <td>
              {{ rowIndex + 1 }}
            </td>
            <td>
              {{ language.alpha2 }}
            </td>
            <td>
              {{ language.alpha3 }}
            </td>
            <td>
              {{ language.name }}
            </td>
            <td class="flex flex-row flex-shrink">
                <p-button icon="pi pi-trash" [rounded]="true" [text]="true" severity="danger" (onClick)="deleteLanguage(language.alpha2)"></p-button>
            </td>
        </tr>
      </ng-template>
  
  </p-table>
</div>
