import { Component, OnInit } from '@angular/core';
import { TablePageEvent } from 'primeng/table';
import { PostsManager } from 'src/app/posts-manager.service';

@Component({
  selector: 'app-post-list-page',
  templateUrl: './post-list-page.component.html',
  styleUrls: ['./post-list-page.component.scss']
})
export class PostListPageComponent implements OnInit {
  public isLoading: boolean = false;

  public page: number = 1;
  public itemsPerPage: number = 25;
  public totalRecords: number = 0;

  public posts: any[] = [];
  
  constructor(
    private postsManager: PostsManager,
  ) {}

  public ngOnInit() {
    this.refreshList();
  }

  private async refreshList() {
    this.isLoading = true;
    const { data, totalAmount } = await this.postsManager.getPosts({
      itemsPerPage: this.itemsPerPage,
      page: this.page,
    });
    this.totalRecords = totalAmount;
    this.posts = data;
    this.isLoading = false;
  }

  public pageChange(event: TablePageEvent) {
    this.page = event.first/this.itemsPerPage + 1;
    this.refreshList();
  }

  public async deletePost(postId: string) {
    this.isLoading = true;
    await this.postsManager.deletePost(postId);
    this.refreshList();
  }
}
