import { Component, OnInit } from '@angular/core';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { TranslateService } from '@ngx-translate/core';
import { ActiveUserManager } from '../active-user-manager.service';

interface Language {
  name: string;
  code: string;
}

@Component({
  selector: 'app-logged-page',
  templateUrl: './logged-page.component.html',
  styleUrls: ['./logged-page.component.scss']
})
export class LoggedPageComponent implements OnInit {
  items: MenuItem[] | undefined;
  languages!: any[];
  selectedLanguage: any;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private activeUser: ActiveUserManager,
  ) {}

  public async logout() {
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  ngOnInit() {

    this.selectedLanguage = {
      name: 'Polski',
      code: 'pl',
    };

    this.languages = [
      {
        name: 'Polski',
        code: 'pl'
      },
      {
        name: 'English',
        code: 'en',
      },
    ];

    this.items = [
      {
        label: 'Profil',
        icon: 'pi pi-user',
        routerLink: '/profile'
      },
      {
        separator: true,
      },
      {
        label: 'Wyloguj',
        icon: 'pi pi-power-off',
        command: (event: MenuItemCommandEvent) => {
          this.logout();
        }
      },
    ];
  }

  public getActiveUsername() {
    if(this.activeUser.firstName && this.activeUser.lastName) return `${this.activeUser.firstName} ${this.activeUser.lastName}`;
    return `Profil`;
  }

  public onLangChange(event: DropdownChangeEvent) {
    if(!event.value) return;
    this.translate.use(event.value.code);

  }

}
