<div class="flex flex-column w-full h-full justify-content-center align-items-center surface-200" *ngIf="isLoading">
  <span class="font-bold text-4xl">
    Ładowanie danych
  </span>
</div>

<form class="flex flex-column w-full" [formGroup]="formGroup" (ngSubmit)="submit()" *ngIf="!isLoading">
  <div class="flex flex-row align-items-center justify-content-between px-3 pt-3 bg-white pb-2">
    <div class="flex flex-row align-items-center">
      <a [routerLink]="'..'" class="hover:text-blue-400 transition-colors transition-duration-200">
        <i class="pi pi-arrow-left text-lg"></i>
      </a>
      <p class="m-0 ml-3 font-bold text-2xl">Dodaj tag postów</p>
    </div>
    <div>
      <button pButton pRipple label="Zapisz" class="p-button-success" type="submit" [disabled]="formGroup.invalid"></button>
    </div>
  </div>
  <div class="w-full bg-white">
    <p-tabMenu [model]="tabs" [activeItem]="activeTab" (activeItemChange)="onTabChange($event)"></p-tabMenu>
  </div>

  <!-- Name section -->
  <div *ngIf="activeTab && activeTab.id == 'name'" class="mt-3 flex flex-column w-full gap-3 px-3" formGroupName="names">
    <small class="text-red-500" *ngIf="nameFormGroup.invalid">Przynajmniej jedno pole musi zostać uzupełnione.</small>
    <div class="flex flex-row w-full justify-content-between align-items-center gap-3" *ngFor="let language of getFormControlsByName('names')">
      <div class="flex flex-column w-3rem">
        <span class="font-semibold text-3xl">
          {{ language }}
        </span>
      </div>
      <div class="flex flex-column flex-grow-1">
        <span class="p-input-icon-left ml-2 w-full">
          <i class="pi pi-language"></i>
          <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('name_' + language) | lowercase" [formControlName]="language" />
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="activeTab && activeTab.id == 'logo'" class="mt-3 flex flex-column w-full gap-3 px-3">
    <div class="flex flex-row w-3">
      <input id="screenshotUpload" type="file" class="file-upload" (change)="onImageUpload($event)" accept="image/*" class="hidden">
      <label for="screenshotUpload" class="cursor-pointer border-3 border-primary button border-round-lg font-semibold">Wybierz obrazek</label>
    </div>
    <div class="flex flex-row w-6">
      <p-image *ngIf="imageFormControl.value" [src]="imageFormControl.value" [preview]="true" alt="logo_preview" imageClass="w-full">
        <ng-template pTemplate="indicator">
            <i class="pi pi-eye"></i>
        </ng-template>
      </p-image>
    </div>
  </div>
</form>