import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoginErrorMessage, ServerErrorMessage } from '../messages';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {

  public isLoading: boolean = false;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private messageService: MessageService,
  ) {}

  formGroup!: FormGroup;

  public ngOnInit() {
    this.formGroup = new FormGroup({
        password: new FormControl('', [
          Validators.required,
        ]),
        email: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
    });
  }

  public async submitLogin() {
    try {
      this.isLoading = true;
      const password = this.formGroup.get('password')!.value;
      const email = this.formGroup.get('email')!.value;
      const response = await this.auth.login(email, password);
      if(!response) {
        this.messageService.add(LoginErrorMessage);
      }
      if(response) await this.router.navigate(['/']);
    } catch (err) {
      this.messageService.add(ServerErrorMessage);
    } finally {
      this.isLoading = false;
    }
    
  }
}
