import { AfterViewInit, Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent {
  @Output()
  onColorPick: EventEmitter<string> = new EventEmitter();

  @ViewChild('colorPanel')
  colorPanel!: OverlayPanel;

  public colors: {
    name: string;
    hex: string;
  }[] = [
    { "name": "White", "hex": "#FFFFFF" },
    { "name": "Black", "hex": "#000000" },
    { "name": "Light Gray", "hex": "#D3D3D3" },
    { "name": "Dark Gray", "hex": "#2E2E2E" },
    { "name": "Soft Yellow", "hex": "#F8F8F2" },
    { "name": "Pale Blue", "hex": "#A8B9D1" },
    { "name": "Green", "hex": "#00FF00" },
    { "name": "Red", "hex": "#FF5555" },
    { "name": "Soft Pink", "hex": "#F78C6C" },
    { "name": "Purple", "hex": "#C678DD" },
    { "name": "Blue", "hex": "#61AFEF" },
    { "name": "Orange", "hex": "#E5C07B" },
    { "name": "Cyan", "hex": "#56B6C2" },
    { "name": "Dark Blue", "hex": "#282C34" },
    { "name": "Soft Green", "hex": "#98C379" },
    { "name": "Bright Red", "hex": "#E06C75" },
    { "name": "Soft White", "hex": "#ABB2BF" }
  ];

  public showPicker(event: MouseEvent) {
    this.colorPanel.show(event);
  }

  public closePicker() {
    this.colorPanel.hide();
  }

  public pickColor(HEXColor: string) {
    this.onColorPick.next(HEXColor);
    this.colorPanel.hide();
  }
}
