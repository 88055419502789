import { Injector, NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from 'primeng/button';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { UserProfilePageComponent } from './user-profile-page/user-profile-page.component';
import { MenuModule } from 'primeng/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoggedPageComponent } from './logged-page/logged-page.component';
import { RemindPasswordPageComponent } from './remind-password/remind-password-page.component';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { TabMenuModule } from 'primeng/tabmenu';
import { CardModule } from 'primeng/card';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ServerListPageComponent } from './server-list-page/server-list-page.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AuthenticationService } from './authentication.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { LanguagesManager } from './languages-manager.service';
import { UserManager } from './user-manager.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SessionInterceptor } from './session.interceptor';
import { UserPageModule } from './user-page/user-page.module';
import { LanguagePageModule } from './language-page/language-page.module';
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { SubscriptionPageModule } from './subscription-page/subscription-page.module';
import { SubscriptionManager } from './subscription-manager.service';
import { DeploymentTypePageModule } from './deployment-type-page/deployment-type-page.module';
import { TranslationPageModule } from './translation-page/translation-page.module';
import { ProductPageModule } from './product-page/product-page.module';
import { ProductTagsPageModule } from './product-tags-page/product-tags-page.module';
import { DeploymentTypesManager } from './deployment-types-manager.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProductTagManager } from './product-tag-manager.service';
import { ComponentsManager } from './components-manager.service';
import { ActiveUserManager } from './active-user-manager.service';
import { SseTranslationLoader } from './sse-translation-loader';
import { environment } from 'src/environments/environment';
import { PostsTagsPageModule } from './posts-tags-page/posts-tags-page.module';
import { PostsPageModule } from './posts-page/posts-page.module';

@NgModule({
  declarations: [
    AppComponent,
    SettingsPageComponent,
    DashboardPageComponent,
    UserProfilePageComponent,
    LoginPageComponent,
    LoggedPageComponent,
    RemindPasswordPageComponent,
    ServerListPageComponent,
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (injector: Injector) => (new SseTranslationLoader(`${environment.secure ? 'https://' : 'http://'}${environment.apiUrl}/translations/`, injector)),
        deps: [Injector],
      },
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    MenuModule,
    BrowserAnimationsModule,
    TableModule,
    InputTextModule,
    TabMenuModule,
    CardModule,
    PasswordModule,
    DropdownModule,
    PickListModule,
    InputTextareaModule,
    ToastModule,
    MessagesModule,
    ConfirmDialogModule,
    UserPageModule,
    LanguagePageModule,
    SubscriptionPageModule,
    DeploymentTypePageModule,
    TranslationPageModule,
    ProductPageModule,
    ProductTagsPageModule,
    HttpClientModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR
    }),
    PostsTagsPageModule,
    PostsPageModule,
  ],
  providers: [
    importProvidersFrom(HttpClientModule),
    AuthenticationService,
    MessageService,
    LanguagesManager,
    UserManager,
    DeploymentTypesManager,
    ConfirmationService,
    SubscriptionManager,
    ProductTagManager,
    ActiveUserManager,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
    ComponentsManager,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
