import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPostsTagPageComponent } from './add-posts-tag-page/add-posts-tag-page.component';
import { PostsTagsListPageComponent } from './posts-tags-list-page/posts-tags-list-page.component';
import { EditPostsTagPageComponent } from './edit-posts-tag-page/edit-posts-tag-page.component';
import { TableModule } from 'primeng/table';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ReactiveFormsModule } from '@angular/forms';
import { PickListModule } from 'primeng/picklist';
import { TabMenuModule } from 'primeng/tabmenu';
import { EditorModule } from '../editor/editor.module';
import { PostsManager } from '../posts-manager.service';
import { ImageModule } from 'primeng/image';



@NgModule({
  declarations: [
    AddPostsTagPageComponent,
    PostsTagsListPageComponent,
    EditPostsTagPageComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    RouterModule,
    TranslateModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    PickListModule,
    TabMenuModule,
    ImageModule,
  ],
  providers: [
    PostsManager,
  ],
})
export class PostsTagsPageModule { }
