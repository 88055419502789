import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";

export const atLeastOne: ValidatorFn = (target: AbstractControl) => {
  const group = (target as FormGroup)
  for(let control in group.controls) {
    if(group.controls[control].value !== '' && typeof group.controls[control].value !== 'undefined' && group.controls[control].value !== null) return null;
  }
  return {
    atLeastOne: true,
  };
}