import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { LanguagesManager } from 'src/app/languages-manager.service';

@Component({
  selector: 'app-add-subscription-page',
  templateUrl: './add-subscription-page.component.html',
  styleUrls: ['./add-subscription-page.component.scss']
})
export class AddSubscriptionPageComponent implements OnInit {
  tabs: MenuItem[] | undefined;

  activeTab: MenuItem | undefined;

  public isLoading: boolean = true;

  public languages: any[] = [];

  public formGroup!: FormGroup;
  public nameFormGroup!: FormGroup;
  public descriptionFormGroup!: FormGroup;
  
  constructor(
    private languageManager: LanguagesManager,
    private router: Router,
    private messageService: MessageService,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;

    const languagesPromise = this.languageManager.getLanguages(1, 0);

    this.languages = await languagesPromise;

    this.nameFormGroup = new FormGroup({}, {
      validators: [
        atLeastOne
      ],
    });

    this.descriptionFormGroup = new FormGroup({}, {
      validators: [atLeastOne]
    });

    const linksAndOptionsGroup = new FormGroup({
      sku: new FormControl('', [
        Validators.required,
      ]),
      terms: new FormGroup({}, {}),
    });

    this.tabs = [
      { label: 'Nazwa', icon: 'pi pi-fw pi-language', id: 'name', fragment: 'names', formGroup: this.nameFormGroup, },
      { label: 'Opis', icon: 'pi pi-fw pi-align-justify', id: 'description', fragment: 'descriptions', formGroup: this.descriptionFormGroup, },
      { label: 'Odnośniki i opcje', icon: 'pi pi-fw pi-external-link', id: 'external-links', fragment: 'links', formGroup: linksAndOptionsGroup },
  ];

  this.activeTab = this.tabs[0];

    this.languages.forEach((language) => {
      const nameControl = new FormControl('');

      this.nameFormGroup.addControl(language.alpha2, nameControl);

      const descriptionControl = new FormControl('');

      this.descriptionFormGroup.addControl(language.alpha2, descriptionControl);
    });


    this.formGroup = new FormGroup({
      names: this.nameFormGroup,
      descriptions: this.descriptionFormGroup,
      linksAndOptions: linksAndOptionsGroup,
    });

    this.isLoading = false;
  }

  public getEntriesOfNames() {
    return Object.keys(this.nameFormGroup.controls);
  }

  public getEntriesOfDescriptions() {
    return Object.keys(this.descriptionFormGroup.controls);
  }

  public getEntriesOfTerms() {
    const group = this.formGroup.get('linksAndOptions')!.get('terms') as FormGroup;
    return Object.keys(group.controls);
  }

  public addTermURL() {
    const group = this.formGroup.get('linksAndOptions')!.get('terms') as FormGroup;
    const amount = Object.keys(group.controls).length;

    group.addControl((amount).toString(), new FormControl('', [
      Validators.required,
    ]));
  }

  public removeTermURL(index: string) {
    const group = this.formGroup.get('linksAndOptions')!.get('terms') as FormGroup;

    group.removeControl(index);
  }

  public submit() {
    if(this.formGroup.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Błąd zapisu',
        detail: 'Niepoprawne dane do zapisu',
      });
      return;
    }

    try {
      this.isLoading = true;

      this.router.navigate(['/subscriptions']);
    } catch(err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Błąd zapisu',
        detail: 'Serwer nie mógł przetworzyć zapytania',
      });
    } finally {
      this.isLoading = false;
    }
  }

  onTabChange(event: MenuItem) {
    this.activeTab = event;
  }
}
