<div class="flex flex-column w-full h-full justify-content-center align-items-center surface-200" *ngIf="isLoading">
  <span class="font-bold text-4xl">
    Ładowanie danych
  </span>
</div>
<form class="flex flex-column w-full" [formGroup]="formGroup" *ngIf="!isLoading" (ngSubmit)="submit()">
  <div class="flex flex-row align-items-center justify-content-between px-3 pt-3 bg-white pb-2">
    <div class="flex flex-row align-items-center">
      <a [routerLink]="'..'" class="hover:text-blue-400 transition-colors transition-duration-200">
        <i class="pi pi-arrow-left text-lg"></i>
      </a>
      <p class="m-0 ml-3 font-bold text-2xl">Edytujesz użytkownika: {{ userId }}</p>
    </div>
    <div>
      <button pButton icon="pi pi-save" pRipple label="Zapisz" class="p-button-success" type="submit" [disabled]="formGroup.invalid || formGroup.pristine"></button>
    </div>
  </div>
  <div class="w-full bg-white">
    <p-tabMenu [model]="tabs" [activeItem]="activeTab" (activeItemChange)="onTabChange($event)"></p-tabMenu>
  </div>

  <!-- Name section -->
  <div *ngIf="activeTab && activeTab.id == 'name'" class="mt-3 flex flex-column w-full justify-content-between align-items-center gap-3 px-3">
    <div class="flex flex-row w-full gap-3" formGroupName="emails">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-at"></i>
        <input pInputText type="text" autocomplete="current-email" placeholder="Adres email" class="w-full" formControlName="email" />
      </span>
      <span class="p-input-icon-left w-full">
        <i class="pi pi-at"></i>
        <input pInputText type="text" autocomplete="new-email" placeholder="Powtórz adres email" class="w-full" formControlName="confirmEmail" />
      </span>
    </div>
    <span class="p-input-icon-left w-full">
      <i class="pi pi-user"></i>
      <input pInputText type="text" autocomplete="given-name" placeholder="Imię" class="w-full" formControlName="firstName" />
    </span>
    <span class="p-input-icon-left w-full">
      <i class="pi pi-user"></i>
      <input pInputText type="text" autocomplete="family-name" placeholder="Nazwisko" class="w-full" formControlName="lastName" />
    </span>
    <div class="flex flex-row w-full gap-3" formGroupName="passwords">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-lock"></i>
        <input pInputText type="password" autocomplete="current-password" placeholder="Hasło" class="w-full" formControlName="password" />
      </span>
      <span class="p-input-icon-left w-full">
        <i class="pi pi-lock"></i>
        <input pInputText type="password" autocomplete="new-password" placeholder="Powtórz hasło" class="w-full" formControlName="confirmPassword" />
      </span>
    </div>
  </div>

  <!-- Description section -->
  <div *ngIf="activeTab && activeTab.id == 'organization'" class="mt-3 flex flex-column w-full gap-3 px-3">
    <span class="p-input-icon-left w-full">
      <i class="pi pi-building"></i>
      <input pInputText type="text" placeholder="Nazwa organizacji" class="w-full" formControlName="organizationName" />
      </span>
  </div>

  <!-- External link section -->
  <div *ngIf="activeTab && activeTab.id == 'subscriptions'" class="mt-3 flex flex-row w-full gap-3 px-3">
    <p-pickList [source]="sourceProducts" [target]="targetProducts" sourceHeader="Dostępne" targetHeader="Wybrane" [dragdrop]="true" [responsive]="true"
      [showSourceControls]="false" [showTargetControls]="false" [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }" breakpoint="1400px" class="w-full">
      <ng-template let-product pTemplate="item">
      </ng-template>
    </p-pickList>
  </div>
</form>