import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TablePageEvent } from 'primeng/table';
import { DeploymentTypesManager } from 'src/app/deployment-types-manager.service';

@Component({
  selector: 'app-deployment-types-list-page',
  templateUrl: './deployment-types-list-page.component.html',
  styleUrls: ['./deployment-types-list-page.component.scss']
})
export class DeploymentTypesListPageComponent implements OnInit {

  public isLoading: boolean = false;

  public page: number = 1;
  public itemsPerPage: number = 25;
  public totalRecords: number = 200;

  public deploymentTypes: { id: string; deploymentsAmount: number; name: string; type: string; }[] = [];

  constructor(
    private deploymentTypeManager: DeploymentTypesManager,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  public ngOnInit(): void {
    this.refreshList();
  }

  public async refreshList() {
    this.isLoading = true;

    const response = await this.deploymentTypeManager.getDeploymentTypes(this.page, this.itemsPerPage);

    this.totalRecords = response.totalNumber;

    this.deploymentTypes = response.data;

    this.isLoading = false;
  }

  public deleteDeploymentType(id: string) {
    this.confirmationService.confirm({
      message: 'Czy chcesz usunąć zasób?',
      header: 'Potwierdzenie usunięcia',
      icon: 'pi pi-info-circle',
      accept: async () => {
        try {
          await this.deploymentTypeManager.deleteDeploymentType(id);
          this.messageService.add({
            severity: 'success',
            summary: 'Sukces',
            detail: 'Udało się zrealizować żądanie usunięcia zasobu',
          });
          await this.refreshList();
        } catch (err) {
          this.messageService.add({
            severity: 'error',
            summary: 'Błąd',
            detail: 'Błąd serwera. Spróbuj ponownie później',
          });
        }
      },
      acceptLabel: 'Tak',
      rejectLabel: 'Nie',
    });
    
  }

  public pageChange(event: TablePageEvent) {
    this.page = event.first/this.itemsPerPage + 1;
    this.refreshList();
  }

  public rowsChange(value: number) {
    console.log(value);
  }
}
