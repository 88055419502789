import { Injectable } from "@angular/core";
import { ApiClientService } from "./api-client.service";

@Injectable({
  providedIn: 'root'
})
export class LanguagesManager {

  constructor(
    private apiClient: ApiClientService,
  ) {}

  public getLanguages(page: number, itemsPerPage: number) {
    const params = new URLSearchParams();
    params.set('page', page.toString());
    params.set('itemsPerPage', itemsPerPage.toString());
    return this.apiClient.get<{
      alpha2: string;
      alpha3: string;
      name: string;
    }[]>('/admin/languages', params);
  }

  public getLanguage(id: string) {
    return this.apiClient.get(`/admin/languages/${id}`);
  }

  public updateLanguage(id: string, toUpdate: any) {
    return this.apiClient.put(`/admin/languages/${id}`, toUpdate);
  }

  public deleteLanguage(alpha2: string) {
    return this.apiClient.delete(`/admin/languages/${alpha2}`);
  }

  public createLanguage(toCreateOptions: {
    alpha2: string;
    alpha3: string;
    localName: string;
  }) {
    return this.apiClient.post(`/admin/languages`, toCreateOptions);
  }
}