<form class="relative flex flex-column flex-1 h-full" [formGroup]="formGroup" (ngSubmit)="submit()">
  <div class="bg-black-alpha-40 absolute top-0 left-0 right-0 bottom-0 z-5 text-center" *ngIf="isLoading">
    <i class="pi pi-spin pi-spinner top-50 absolute"></i>
  </div>
  <div class="flex flex-row align-items-center justify-content-between px-3 py-3 bg-white border-bottom-1 border-300">
    <div class="flex flex-row align-items-center">
      <a [routerLink]="'..'" class="hover:text-blue-400 transition-colors transition-duration-200">
        <i class="pi pi-arrow-left text-lg"></i>
      </a>
      <p class="m-0 ml-3 font-bold text-2xl">Dodaj język</p>
    </div>
    <div>
      <button pButton icon="pi pi-save" pRipple label="Zapisz" class="p-button-success" type="submit"></button>
    </div>
  </div>

  <!-- Name section -->
  <div class="mt-3 flex flex-row w-full justify-content-between gap-3 px-3">
    <div class="flex flex-column flex-grow-1 gap-4">
      <div>
        <span class="p-input-icon-left w-full">
          <i class="pi pi-flag"></i>
          <input pInputText type="text" placeholder="Kod Alfa-2" class="w-full" id="alpha2" formControlName="alpha2" />
        </span>
      </div>
      <div>
        <span class="p-input-icon-left w-full">
          <i class="pi pi-flag"></i>
          <input pInputText type="text" placeholder="Kod Alfa-3" class="w-full" id="alpha3" formControlName="alpha3" />
        </span>
      </div>
    </div>
    <div class="flex flex-column flex-grow-1">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-language"></i>
        <input pInputText type="text" placeholder="Nazwa języka" class="w-full" id="localName" formControlName="localName" />
      </span>
    </div>
  </div>
</form>