<div class="flex flex-column h-screen border-right-1 surface-border surface-section sticky top-0 sidebar">
    <div class="flex align-items-center justify-content-center mt-4 flex-shrink-0 relative">
        <img src="/assets/logo.svg" alt="Image" height="60">
        <span class="absolute" style="bottom: -15px; right: 1rem;">AdminPanel</span>
    </div>

    <div class="mt-4 flex flex-column">
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/dashboard']" routerLinkActive="link-active">
                <i class="pi pi-home pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.DASHBOARD' | translate}}</span>
            </a>
        </div>
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/products']" routerLinkActive="link-active">
                <i class="pi pi-file pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.PRODUCTS' | translate}}</span>
            </a>
        </div>
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/deployment-types']" routerLinkActive="link-active">
                <i class="pi pi-box pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.DEPLOYMENT_TYPES' | translate }}</span>
            </a>
        </div>
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/product-tags']" routerLinkActive="link-active">
                <i class="pi pi-hashtag pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.PRODUCT_TAGS' | translate }}</span>
            </a>
        </div>
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/posts']" routerLinkActive="link-active">
                <i class="pi pi-file-word pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.POSTS' | translate }}</span>
            </a>
        </div>
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/posts-tags']" routerLinkActive="link-active">
                <i class="pi pi-hashtag pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.POST_TAGS' | translate }}</span>
            </a>
        </div>
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/translations']" routerLinkActive="link-active">
                <i class="pi pi-globe pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.TRANSLATIONS' | translate }}</span>
            </a>
        </div>
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/languages']" routerLinkActive="link-active">
                <i class="pi pi-language pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.LANGUAGES' | translate }}</span>
            </a>
        </div>
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/subscriptions']" routerLinkActive="link-active">
                <i class="pi pi-wallet pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.SUBSCRIPTIONS' | translate }}</span>
            </a>
        </div>
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/users']" routerLinkActive="link-active">
                <i class="pi pi-users pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.USERS' | translate }}</span>
            </a>
        </div>
        <div>
            <a class="link w-full text-left outline-none side-menu" [routerLink]="['/servers']" routerLinkActive="link-active">
                <i class="pi pi-compass pr-2"></i>
                <span>{{'ADMIN.SIDEBAR.SERVER_STATUS' | translate }}</span>
            </a>
        </div>
    </div>

    <div class="mt-auto">
        <div class="py-2 px-2">
            <p-dropdown styleClass="w-full" [options]="languages" [(ngModel)]="selectedLanguage" (onChange)="onLangChange($event)" optionLabel="name" placeholder="Wybierz język">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedLanguage">
                        <div class="mr-4">{{ selectedLanguage.code | uppercase }}</div>
                        <div>{{ selectedLanguage.name }}</div>
                    </div>
                </ng-template>
                <ng-template let-language pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div class="mr-4">{{ language.code | uppercase }}</div>
                        <div>{{ language.name }}</div>
                    </div>
                </ng-template>
            </p-dropdown>
        </div>
        <div class="px-2">
            <a class="mt-2 link-outlined w-full border-blue-500 text-blue-500 font-bold border-1 flex flex-row gap-2 border-round-md no-underline align-items-center hover:bg-blue-50 transition-duration-200" [routerLink]="['/settings']">
                <i class="pi pi-cog"></i>
                <span>{{'ADMIN.SIDEBAR.SETTINGS' | translate }}</span>
            </a>
        </div>
        <hr class="mb-3 mx-3 border-top-1 border-none surface-border">
        <a class="m-3 flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors gap-2" (click)="menu.toggle($event)">
            <div class="relative" style="height: 1.5rem; width: 1.5rem;">
                <i class="absolute pi pi-circle text-2xl"></i>
                <i class="absolute pi pi-user" style="left: 4px; top: 2px;"></i>
            </div>
            <span class="font-medium">{{ getActiveUsername() }}</span>
        </a>
        <p-menu #menu [model]="items" [popup]="true"></p-menu>
    </div>
</div>
<div class="flex flex-column content flex-grow-1">
    <router-outlet></router-outlet>
</div>