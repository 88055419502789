import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { DeploymentTypesManager } from 'src/app/deployment-types-manager.service';
import { LanguagesManager } from 'src/app/languages-manager.service';

@Component({
  selector: 'app-add-deployment-type-page',
  templateUrl: './add-deployment-type-page.component.html',
  styleUrls: ['./add-deployment-type-page.component.scss']
})
export class AddDeploymentTypePageComponent implements OnInit {
  public tabs: MenuItem[] | undefined;

  public activeTab: MenuItem | undefined;

  public formGroup!: FormGroup;
  public nameFormGroup!: FormGroup;

  public isLoading: boolean = false;

  constructor(
    private langaugeManager: LanguagesManager,
    private deploymentTypesManager: DeploymentTypesManager,
    private messageService: MessageService,
    private router: Router,
  ) {}

  public async ngOnInit() {
    this.formGroup = new FormGroup({
      type: new FormControl('', [
        Validators.required,
      ]),
    });

    const languages = await this.langaugeManager.getLanguages(1, 0);

    this.nameFormGroup = new FormGroup({}, [
      atLeastOne,
    ]);

    for (let lang of languages) {
      this.nameFormGroup.addControl(lang.alpha2, new FormControl(''));
    }

    this.formGroup.addControl('names', this.nameFormGroup);

    this.tabs = [
      { label: 'Nazwa', icon: 'pi pi-fw pi-language', id: 'name' },
      { label: 'Typ', icon: 'pi pi-fw pi-th-large', id: 'type' },
    ];

    this.activeTab = this.tabs[0];
  }

  onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  public getEntriesOfNames() {
    return Object.keys(this.nameFormGroup.controls);
  }

  public async submit() {
    try {
      this.isLoading = true;
      if(this.formGroup.invalid) {
        this.messageService.add({
          severity: 'error',
          summary: 'Błąd',
          detail: 'Nie wszystkie elementy zostały uzupełnione',
        });
        return;
      }

      const names = Object.keys(this.nameFormGroup.controls).reduce<Record<string, string>>((acc, controlName) => {
        acc[controlName] = this.nameFormGroup.controls[controlName].value;
        return acc;
      }, {});

      const response = await this.deploymentTypesManager.createDeploymentType({
        type: this.formGroup.get('type')?.value,
        names,
      });

      this.router.navigate(['/deployment-types']);
      this.isLoading = false;
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Błąd',
        detail: 'Po stronie serwera pojawiły się problemy. Spróbuj ponownie później.',
      })
    }
  }
}
