import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostListPageComponent } from './post-list-page/post-list-page.component';
import { AddPostPageComponent } from './add-post-page/add-post-page.component';
import { EditPostPageComponent } from './edit-post-page/edit-post-page.component';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { PickListModule } from 'primeng/picklist';
import { TabMenuModule } from 'primeng/tabmenu';
import { ReactiveFormsModule } from '@angular/forms';
import { RippleModule } from 'primeng/ripple';
import { EditorComponent } from '../editor/editor.component';
import { EditorModule } from '../editor/editor.module';
import { ImageModule } from 'primeng/image';

@NgModule({
  declarations: [
    PostListPageComponent,
    AddPostPageComponent,
    EditPostPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    ButtonModule,
    TranslateModule,
    InputTextModule,
    PickListModule,
    TabMenuModule,
    ReactiveFormsModule,
    RippleModule,
    EditorModule,
    ImageModule,
  ]
})
export class PostsPageModule { }
