import { ApplicationRef, ChangeDetectorRef, Component } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { DeploymentTypesManager } from 'src/app/deployment-types-manager.service';
import { LanguagesManager } from 'src/app/languages-manager.service';
import { FormErrorMessage, ServerErrorMessage } from 'src/app/messages';
import { ProductTagManager } from 'src/app/product-tag-manager.service';
import { ProductsManager } from 'src/app/products-manager.service';
import { TagToAdd } from '../tag-to-add';
import { HttpClient } from '@angular/common/http';
import { PickListMoveAllToSourceEvent, PickListMoveAllToTargetEvent, PickListMoveToSourceEvent, PickListMoveToTargetEvent } from 'primeng/picklist';

@Component({
  selector: 'app-edit-product-page',
  templateUrl: './edit-product-page.component.html',
  styleUrls: ['./edit-product-page.component.scss']
})
export class EditProductPageComponent {
  public isLoading: boolean = true;

  tabs: MenuItem[] | undefined;
  deploymentTabs: MenuItem[] | undefined;

  activeTab: MenuItem | undefined;
  deploymentActiveTab: MenuItem | undefined;

  sourceProducts: TagToAdd[] = [];
  targetProducts: TagToAdd[] = [];

  formGroup!: FormGroup;
  nameFormGroup!: FormGroup;
  summaryFormGroup!: FormGroup;
  descriptionFormGroup!: FormGroup;
  deploymentsFormGroup!: FormGroup;
  licenseFormArray!: FormArray<FormGroup>;
  navLinksFormArray!: FormArray<FormGroup>;

  languages: any[] = [];
  deploymentTypes: any[] = [];
  productTags: any[] = [];
  products: string[] = [];

  selectedDeployment: string = '1';

  components: string[] = [];

  selectedTrait: string | null = null;
  reloadInstructionVisibility: boolean = true;

  uploadedScreenshotsControl!: FormArray<FormControl<string | ArrayBuffer | null>>;
  uploadedLogoControl!: FormControl<string | ArrayBuffer | null>;
  componentControl!: FormControl<string>;

  productVersionControl!: FormControl<string>;

  private translations: any;

  public productId!: string;

  constructor(
    private langaugesManager: LanguagesManager,
    private translate: TranslateService,
    private deploymentTypesManager: DeploymentTypesManager,
    private productTagManager: ProductTagManager,
    private productsManager: ProductsManager,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private appRef: ApplicationRef,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;

    this.translations = [];

    const { productId } = await firstValueFrom(this.route.params);

    this.productId = productId;

    const {
      tags,
      summary,
      screenshots,
      name,
      logo,
      description,
      deployments,
      componentId,
      licenses,
      productVersion,
      navLinks,
    } = await this.productsManager.getProduct(productId);

    this.formGroup = new FormGroup({});

    this.navLinksFormArray = new FormArray<any>([]);
    this.formGroup.addControl('navLinks', this.navLinksFormArray);

    this.componentControl = new FormControl();
    this.componentControl.setValue(componentId);
    this.formGroup.addControl('component', this.componentControl);


    this.licenseFormArray = new FormArray<FormGroup>([]);

    if (licenses) {
      for (const license of licenses) {
        this.licenseFormArray.push(
          new FormGroup({
            licenseName: new FormControl(license.licenseName),
            licenseUrl: new FormControl(license.licenseUrl),
          }),
        );
      }
    }

    this.formGroup.addControl('license', this.licenseFormArray);

    this.productVersionControl = new FormControl();
    this.productVersionControl.setValue(productVersion);
    this.formGroup.addControl('productVersion', this.productVersionControl);

    this.uploadedScreenshotsControl = new FormArray<FormControl<string | ArrayBuffer | null>>([]);
    this.formGroup.addControl('screenshots', this.uploadedScreenshotsControl);

    this.nameFormGroup = new FormGroup({}, [
      atLeastOne,
    ]);

    this.formGroup.addControl('names', this.nameFormGroup);

    this.deploymentsFormGroup = new FormGroup({});

    this.formGroup.addControl('deployments', this.deploymentsFormGroup);

    this.summaryFormGroup = new FormGroup({}, [
      atLeastOne
    ]);

    this.formGroup.addControl('summary', this.summaryFormGroup);

    this.descriptionFormGroup = new FormGroup({}, [
      atLeastOne
    ]);

    this.formGroup.addControl('description', this.descriptionFormGroup);

    this.formGroup.addControl('tags', new FormControl([]));

    this.uploadedLogoControl = new FormControl(null, [
      Validators.required,
    ]);

    this.formGroup.addControl('logo', this.uploadedLogoControl);

    firstValueFrom(this.http.get(logo, {observe: 'response', responseType: 'blob'})).then((response) => {
      const fileReader = new FileReader();
        fileReader.readAsDataURL(response.body!);

        fileReader.onload = (ev) => {
          this.uploadedLogoControl.setValue(ev.target?.result!);
        }
    });

    const screenshotPromises: Promise<void>[] = [];

    for(const screenshotUrl of screenshots) {
      const promise = firstValueFrom(this.http.get(screenshotUrl, {observe: 'response', responseType: 'blob'})).then((response) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(response.body!);

        fileReader.onload = (ev) => {
          const formControl = new FormControl(ev.target?.result!)
          this.uploadedScreenshotsControl.push(formControl);
        }
      });
      screenshotPromises.push(promise);
    }

    this.tabs = [
      {
        label: 'Nazwa',
        icon: 'pi pi-language',
        id: 'name',
      },
      {
        label: 'Podsumowanie',
        icon: 'pi pi-align-justify',
        id: 'summary',
      },
      {
        label: 'Opis',
        icon: 'pi pi-align-justify',
        id: 'description',
      },
      {
        label: 'Logo i zrzuty ekranu',
        icon: 'pi pi-images',
        id: 'images',
      },
      {
        label: 'Integracja',
        icon: 'pi pi-link',
        id: 'integration',
      },
      {
        label: 'Tagi',
        icon: 'pi pi-hashtag',
        id: 'tags',
      },
      {
        label: 'Środowiska',
        icon: 'pi pi-box',
        id: 'deployments',
      },
    ];
    this.activeTab = this.tabs[0];

    this.deploymentTabs = [
      {
        label: 'Nazwa',
        icon: 'pi pi-language',
        id: 'name',
      },
      {
        label: 'Typ',
        icon: 'pi pi-list',
        id: 'type',
      },
      {
        label: 'Cechy',
        icon: 'pi pi-info',
        id: 'traits',
      },
      {
        label: 'Dokumentacja',
        icon: 'bi bi-file-earmark-post',
        id: 'documentations',
      },
      {
        label: 'Instrukcja',
        icon: 'bi bi-list-check',
        id: 'instructions',
      },
      {
        label: 'Integracja',
        icon: 'pi pi-link',
        id: 'integration',
      },
    ];

    this.components = await this.productsManager.getComponentsHints();

    this.deploymentActiveTab = this.deploymentTabs[0];

    this.languages = await this.langaugesManager.getLanguages(1, 0);

    for(let language of this.languages) {
      this.translations[language.alpha2.toLowerCase()] = await firstValueFrom(this.translate.getTranslation(language.alpha2.toLowerCase()));

      this.nameFormGroup.addControl(language.alpha2, new FormControl(this.translations[language.alpha2.toLowerCase()][name] ?? ''));
      this.summaryFormGroup.addControl(language.alpha2, new FormControl(this.translations[language.alpha2.toLowerCase()][summary] ?? ''));
      this.descriptionFormGroup.addControl(language.alpha2, new FormControl(this.translations[language.alpha2.toLowerCase()][description] ?? ''));
    }

    for(const navLink of navLinks) {
      await this.addNavLink(navLink.name, navLink.href);
    }

    const deploymentTypesPromise = this.getDeploymentTypes();
    const productTagsPromise = this.getProductTags();

    await Promise.all([deploymentTypesPromise, productTagsPromise])

    this.targetProducts = tags;

    this.sourceProducts = this.sourceProducts.filter((sourceTag) => {
      return !this.targetProducts.some(targetTag => sourceTag.id === targetTag.id );
    });

    this.products = (await this.productsManager.getScarmProductsHints()).map((hint) => hint.name);

    this.prepareDeployments(deployments);
    this.isLoading = false;
  }

  public async addNavLink(name?: string, href?: string) {
    const nameFormGroup = new FormGroup({});

    for(let language of this.languages) {
      const langaugeFormControl = new FormControl('', [Validators.required]);
      if(name) {
        langaugeFormControl.setValue(this.translations[language.alpha2.toLowerCase()][name]);
      }
      nameFormGroup.addControl(language.alpha2, langaugeFormControl);
    }

    this.navLinksFormArray.push(new FormGroup({
      name: nameFormGroup,
      href: new FormControl(href ?? '', [Validators.required]),
    }));
  }

  public removeNavLink(index: number)  {
    this.navLinksFormArray.removeAt(index);
  }

  public getProductLicenses() {
    return this.licenseFormArray as FormArray<FormGroup>;
  }

  public deleteProductLicense(index: number) {
    this.licenseFormArray.removeAt(index);
    this.licenseFormArray.markAsDirty();
  }

  public addProductLicenses() {
    this.licenseFormArray.push(new FormGroup({
      'licenseName': new FormControl(''),
      'licenseUrl': new FormControl(''),
    }));
  }

  public onMoveToSource(evt: PickListMoveToSourceEvent) {
    const tagsFormControl = this.formGroup.get('tags') as FormControl<TagToAdd[]>;
    tagsFormControl.setValue(this.targetProducts);
    tagsFormControl.markAsDirty();
  }

  public onMoveAllToSource(evt: PickListMoveAllToSourceEvent) {
    const tagsFormControl = this.formGroup.get('tags') as FormControl<TagToAdd[]>;
    tagsFormControl.setValue(this.targetProducts);
    tagsFormControl.markAsDirty();
  }

  public onMoveAllToTarget(evt: PickListMoveAllToTargetEvent) {
    const tagsFormControl = this.formGroup.get('tags') as FormControl<TagToAdd[]>;
    tagsFormControl.setValue(this.targetProducts);
    tagsFormControl.markAsDirty();
  }

  public onMoveToTarget(evt: PickListMoveToTargetEvent) {
    const tagsFormControl = this.formGroup.get('tags') as FormControl<TagToAdd[]>;
    tagsFormControl.setValue(this.targetProducts);
    tagsFormControl.markAsDirty();
  }

  private async getProductTags() {
    let {data} = await this.productTagManager.getProductTags(1, 0);
    this.productTags = data;
    this.sourceProducts = this.productTags.map((deploymentType) => {
      return {
        id: deploymentType.id,
        name: deploymentType.name,
      };
    });
  }

  private async getDeploymentTypes() {
    let {data} = await this.deploymentTypesManager.getDeploymentTypes(1, 0);
    this.deploymentTypes = data;
    return;
  }

  public prepareDeployments(deployments: {
    id: string;
    isDefault: boolean;
    name: string;
    scarmId: string;
    traits: string[];
    typeId: string;
    url: string;
    instructions: Record<string, object>;
    documentations: {
      name: string;
      address: string;
    }[];
  }[]) {
    for(const deployment of deployments) {
      const deploymentFormGroup = this.addDeployment() as FormGroup;

      deploymentFormGroup.addControl('id', new FormControl(deployment.id));

      const namesFormGroup = deploymentFormGroup.get('names') as FormGroup;

      const nameFormGroupKeys = Object.keys(namesFormGroup.controls);

      for (const key of nameFormGroupKeys) {
        namesFormGroup.get(key)?.setValue(this.translations[key.toLowerCase()][deployment.name] ?? '');
      }

      const typeFormControl = deploymentFormGroup.get('type') as FormControl;

      typeFormControl.setValue(deployment.typeId);

      const documentationsFormArray = deploymentFormGroup.get('documentations') as FormArray;

      for(const documentation of deployment.documentations) {
        documentationsFormArray.push(new FormGroup({
          'name': new FormControl(documentation.name),
          'address': new FormControl(documentation.address),
        }));
      }

      const instructionsFormGroup = deploymentFormGroup.get('instructions') as FormGroup;

      for(const [key, object] of Object.entries(deployment.instructions)) {
        const language = instructionsFormGroup.get(key) as FormControl;
        language.setValue(object);
      }

      for(const trait of deployment.traits) {
        const formGroup = deploymentFormGroup.parent!.controls as Record<string, any>;
        const deploymentId = Object.keys(formGroup).find(name => deploymentFormGroup === formGroup[name]);
        const traitFormGroup = this.addTraitField(deploymentId);
        const traitFormGroupKeys = Object.keys(traitFormGroup.controls);

        for(const key of traitFormGroupKeys) {
          traitFormGroup.get(key)?.setValue(this.translations[key.toLowerCase()][trait] ?? '');
        }
      }

      const integrationFormControl = deploymentFormGroup.get('integration') as FormControl;
      integrationFormControl.setValue(deployment.scarmId);

      const isDefaultFormControl = deploymentFormGroup.get('default') as FormControl;
      isDefaultFormControl.setValue(deployment.isDefault);

      const urlFormControl = deploymentFormGroup.get('url') as FormControl;
      urlFormControl.setValue(deployment.url);
    }
  }

  public addDeployment() {
    const deploymentFormGroup = new FormGroup({});

    const namesFormGroup = new FormGroup({}, [
      atLeastOne
    ]);

    const typeFormControl = new FormControl(null, [
      Validators.required,
    ]);

    const integrationFormControl = new FormControl(null, [
      Validators.required,
    ]);

    const urlFormControl = new FormControl('', [
      Validators.required
    ]);

    const traitsFormGroup = new FormGroup({});

    const defaultEnvironmentFormControl = new FormControl(false);

    const documentationsFormArray = new FormArray<FormGroup>([]);

    const instructionsFormGroup = new FormGroup({});

    for(let language of this.languages) {
      namesFormGroup.addControl(language.alpha2, new FormControl());
      instructionsFormGroup.addControl(language.alpha2, new FormControl([]));
    }

    deploymentFormGroup.addControl('names', namesFormGroup);
    deploymentFormGroup.addControl('type', typeFormControl);
    deploymentFormGroup.addControl('integration', integrationFormControl);
    deploymentFormGroup.addControl('default', defaultEnvironmentFormControl);
    deploymentFormGroup.addControl('traits', traitsFormGroup);
    deploymentFormGroup.addControl('url', urlFormControl);
    deploymentFormGroup.addControl('instructions', instructionsFormGroup);
    deploymentFormGroup.addControl('documentations', documentationsFormArray);
    deploymentFormGroup.addControl('toDelete', new FormControl(false));

    const keys = Object.keys(this.deploymentsFormGroup.controls);
    const controlsAmount = keys.length;
    const lastKey = Object.keys(this.deploymentsFormGroup.controls)[keys.length - 1] ?? 0;

    this.deploymentsFormGroup.addControl((parseInt(lastKey) + 1).toString(), deploymentFormGroup);

    return deploymentFormGroup;
  }

  public deleteLinkFromDeployment(index: number) {
    const currentDeployment = this.getCurrentDeployment();

    const documentationsFormArray = currentDeployment.get('documentations') as FormArray<FormGroup>;

    documentationsFormArray.removeAt(index);
  }

  public addLinkToDeployment() {
    const currentDeployment = this.getCurrentDeployment();

    const documentationsFormArray = currentDeployment.get('documentations') as FormArray<FormGroup>;

    documentationsFormArray.push(new FormGroup({
      'name': new FormControl(''),
      'address': new FormControl(''),
    }));
  }

  public getDocsOfCurrentDeployment() {
    const currentDeployment = this.getCurrentDeployment();

    return currentDeployment.get('documentations') as FormArray<FormGroup>;
  }

  public getInstructionsOfCurrentDeployment() {
    const currentDeployment = this.getCurrentDeployment();

    return currentDeployment.get('instructions') as FormGroup<{ [name: string]: FormControl<{}>}>;
  }

  public getEntriesOfInstructionsOfCurrentDeployment() {
    return Object.keys(this.getInstructionsOfCurrentDeployment().controls);
  }

  public addTraitField(deploymentId: string = this.selectedDeployment) {
    const formGroup = this.deploymentsFormGroup.get(deploymentId) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;

    const keys = Object.keys(traitsFormGroup.controls);

    const traitIndex = !Number.isNaN(parseInt(keys[keys.length - 1]) + 1) ? parseInt(keys[keys.length - 1]) + 1 : 0;

    const traitNamesFormGroup = new FormGroup({}, [
      atLeastOne,
    ]);

    for(const language of this.languages) {
      traitNamesFormGroup.addControl(language.alpha2, new FormControl(''));
    }

    traitsFormGroup.addControl(traitIndex.toString(), traitNamesFormGroup);

    return traitNamesFormGroup;
  }

  public removeTraitField(traitId: string) {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;

    traitsFormGroup.removeControl(traitId);
    if(traitId === this.selectedTrait) this.selectedTrait = null;
  }

  public getNameOfTrait(traitId: string) {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;

    return traitsFormGroup.get(traitId)?.get(this.translate.currentLang.toUpperCase())?.value;
  }

  public selectTrait(traitId: string) {
    this.selectedTrait = traitId;
  }

  public getCurrentTrait() {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;
    return traitsFormGroup.get(this.selectedTrait!) as FormGroup;
  }

  public getTraitNameFields() {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;
    const traitNamesGroup = traitsFormGroup.get(this.selectedTrait!) as FormGroup;

    return Object.keys(traitNamesGroup.controls);
  }

  public getTraitsFields() {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;
    return Object.keys(traitsFormGroup.controls);
  }

  public getDeployments() {
    return Object.keys(this.deploymentsFormGroup.controls);
  }

  public onDeplymentTabChange(event: MenuItem) {
    this.deploymentActiveTab = event;
  }

  public onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  public getEntriesOfProductNames() {
    return Object.keys(this.nameFormGroup.controls);
  }

  public getEntriesOfNavlinkNames(navLink: FormGroup) {
    return Object.keys((navLink.get('name') as any).controls);
  }

  public getEntriesOfProductSummary() {
    return Object.keys(this.summaryFormGroup.controls);
  }

  public getEntriesOfProductDescription() {
    return Object.keys(this.descriptionFormGroup.controls);
  }

  public clickRemoveDeployment(id: string, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.removeDeployment(id);
  }

  public removeDeployment(id: string) {
    const toRemoveFormGroup = this.deploymentsFormGroup.get(id) as FormGroup;
    const toRemoveIdFormControl = toRemoveFormGroup.get('id');

    if(toRemoveIdFormControl) {
      toRemoveFormGroup.get('toDelete')!.setValue(!toRemoveFormGroup.get('toDelete')?.value);
      toRemoveFormGroup.get('toDelete')?.markAsDirty();
      return;
    }

    if(Object.keys(this.deploymentsFormGroup.controls).length < 1) return;
    this.deploymentsFormGroup.removeControl(id);
    this.selectDeployment(Object.keys(this.deploymentsFormGroup.controls)[0]);
    this.deploymentsFormGroup.markAsDirty();
  }

  public getNameOfDeployment(id: string) {
    const deploymentFormGroup = this.deploymentsFormGroup.get(id) as FormGroup;
    const namesFormGroup = deploymentFormGroup.get('names') as FormGroup;
    const nameFormControl = namesFormGroup.get(this.translate.currentLang.toUpperCase());

    return nameFormControl?.value;
  }

  public selectDeployment(id: string) {
    this.reloadInstructionVisibility = false;
    this.selectedTrait = null;
    this.selectedDeployment = id;
    this.appRef.tick();
    this.reloadInstructionVisibility = true;
  }

  public getCurrentDeployment() {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    return formGroup;
  }

  private parseDeploymentForRequest() {

    const deployments = [];

    for(const deploymentId of this.getDeployments()) {

      const deployment: any = {};

      const deploymentFormGroup = this.deploymentsFormGroup.get(deploymentId) as FormGroup;

      if(deploymentFormGroup.pristine) continue;

      const idFormControl = deploymentFormGroup.get('id');

      if(idFormControl) {
        deployment.id = idFormControl.value;
      }

      const namesFormGroup = deploymentFormGroup.get('names') as FormGroup;

      if(namesFormGroup.dirty) {
        const names = Object.keys(namesFormGroup.controls).reduce<Record<string, string>>((acc, alpha2) => {
          const translation = namesFormGroup.get(alpha2);
          if(!translation?.value) return acc;
          acc[alpha2] = translation.value;
          return acc;
        }, {});

        deployment.name = names;
      }

      const traitsFormGroup = deploymentFormGroup.get('traits') as FormGroup;

      if(traitsFormGroup.dirty) {
        const traits = Object.keys(traitsFormGroup.controls).reduce<Record<string, string>[]>((acc, traitId) => {
          const traitFormGroup = traitsFormGroup.get(traitId) as FormGroup;

          const translations = Object.keys(traitFormGroup.controls).reduce<Record<string, string>>((acc, alpha2) => {
            const translationControl = traitFormGroup.get(alpha2);
            if(translationControl && translationControl.value !== null && translationControl.value !== '') acc[alpha2] = traitFormGroup.get(alpha2)?.value;
            return acc;
          }, {});

          acc.push(translations);
          return acc;
        }, []);

        deployment.traits = traits;
      }

      const defaultFormControl = deploymentFormGroup.get('default') as FormControl;

      if(defaultFormControl.dirty) {
        deployment.isDefault = defaultFormControl.value;
      }

      const integrationFormControl = deploymentFormGroup.get('integration') as FormControl;

      if(integrationFormControl.dirty) {
        deployment.scarmId = integrationFormControl.value;
      }

      const typeFormControl = deploymentFormGroup.get('type') as FormControl;

      if(typeFormControl.dirty) {
        deployment.typeId = typeFormControl.value;
      }

      const urlFormControl = deploymentFormGroup.get('url') as FormControl;

      if(urlFormControl.dirty) {
        deployment.url = urlFormControl.value;
      }

      const toDeleteFormControl = deploymentFormGroup.get('toDelete') as FormControl;

      if(toDeleteFormControl.dirty) {
        deployment.toDelete = toDeleteFormControl.value;
      }

      const documentationsFormArray = deploymentFormGroup.get('documentations') as FormArray;

      if (documentationsFormArray.dirty) {
        deployment.documentations = documentationsFormArray.controls.map((documentation) => ({
          name: documentation.get('name')!.value,
          address: documentation.get('address')!.value,
        }));
      }

      const instructionsFormGroup = deploymentFormGroup.get('instructions') as FormGroup;

      if (instructionsFormGroup.dirty) {
        deployment.instructions = Object.entries(instructionsFormGroup.controls).reduce<Record<string, object>>((acc, [key, formControl]) => {
          acc[key] = formControl.value;
          return acc;
        }, {});
      }

      deployments.push(deployment);
    }

    return deployments;
  }

  public async submit() {
    try {
      this.isLoading = true;
      if(this.formGroup.invalid) {
        this.messageService.add(FormErrorMessage);
        return;
      }

      const payload: any = {};

      if(this.nameFormGroup.dirty) {
        const names = Object.keys(this.nameFormGroup.controls).reduce<Record<string, string>>((acc, alpha2) => {
          const translation = this.nameFormGroup.get(alpha2);
          if(!translation?.value) return acc;
          acc[alpha2] = translation.value;
          return acc;
        }, {});

        payload.name = names;
      }

      if(this.summaryFormGroup.dirty) {
        const summaries = Object.keys(this.summaryFormGroup.controls).reduce<Record<string, string>>((acc, alpha2) => {
          const translation = this.summaryFormGroup.get(alpha2);
          if(!translation?.value) return acc;
          acc[alpha2] = translation.value;
          return acc;
        }, {});

        payload.summary = summaries;
      }

      if(this.navLinksFormArray.dirty) {
        payload.navLinks = this.navLinksFormArray.controls.map((control) => ({
          name: control.get('name')?.value,
          href: control.get('href')?.value,
        }));
      }

      if(this.descriptionFormGroup.dirty) {
        const descriptions = Object.keys(this.descriptionFormGroup.controls).reduce<Record<string, string>>((acc, alpha2) => {
          const translation = this.descriptionFormGroup.get(alpha2);
          if(!translation?.value) return acc;
          acc[alpha2] = translation.value;
          return acc;
        }, {});

        payload.description = descriptions;
      }

      if(this.uploadedLogoControl.dirty) {
        const logo = this.uploadedLogoControl.value;
        payload.logo = logo;
      }

      if(this.uploadedScreenshotsControl.dirty) {
        const screenshots = this.uploadedScreenshotsControl.controls.map((screenshotControl) => screenshotControl.value);
        payload.screenshots = screenshots;
      }

      if(this.componentControl.dirty) {
        payload.componentId = this.componentControl.value;
      }

      if(this.licenseFormArray.dirty) {
        const licenseFormArray  = this.licenseFormArray as FormArray<FormGroup<{licenseName: FormControl<string>, licenseUrl: FormControl<string>}>>
        payload.licenses = licenseFormArray.controls
          .filter(formGroup => (formGroup.controls.licenseName.value))
          .map((formGroup) => ({
          licenseName: formGroup.controls.licenseName.value,
          licenseUrl: formGroup.controls.licenseUrl.value
        }))
      }

      if (this.productVersionControl.dirty) {
        payload.productVersion = this.productVersionControl.value;
      }

      if(this.deploymentsFormGroup.dirty) {
        const deployments = this.parseDeploymentForRequest();
        payload.deployments = deployments;
      }

      const tagsFormGroup = this.formGroup.get('tags') as FormControl;

      if(tagsFormGroup.dirty) {
        payload.tagIds = this.targetProducts.map((tag) => (tag.id));
      }

      const response = await this.productsManager.updateProduct(this.productId, payload);

      if(response) this.router.navigate(['/products']);
    } catch (err) {
      this.messageService.add(ServerErrorMessage);
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  public onLogoUpload(ev: Event) {
    this.uploadedLogoControl.markAsTouched();
    const event = ev as (Event & {target: HTMLInputElement & { files: FileList | null}})

    if(!event?.target?.files) return;

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (ev) => {
      this.uploadedLogoControl.setValue(ev.target?.result!);
      this.uploadedLogoControl.markAsDirty();
    }

    event.target.value = '';
  }

  public onScreenshotsUpload(ev: Event) {
    const event = ev as (Event & {target: HTMLInputElement & { files: FileList | null}})
    if(!event?.target?.files) return;

    for(let file of event.target.files) {

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (ev) => {
        const formControl = new FormControl(ev.target?.result!)
        formControl.markAsDirty();
        this.uploadedScreenshotsControl.push(formControl);
      }
    }
    this.uploadedScreenshotsControl.markAsDirty()

    event.target.value = '';
  }

  public removeScreenshot(index: number) {
    this.uploadedScreenshotsControl.removeAt(index);
    this.uploadedScreenshotsControl.markAsDirty()
  }

  public getIntegrationFormControl() {
    return this.getCurrentDeployment().get('integration') as FormControl;
  }
}
