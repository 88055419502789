import { Component, OnInit } from '@angular/core';
import { HealthCheckManagerService } from 'src/app/health-check-manager.service';

@Component({
  selector: 'app-server-list-page',
  templateUrl: './server-list-page.component.html',
  styleUrls: ['./server-list-page.component.scss']
})
export class ServerListPageComponent implements OnInit {
  public statusesLoading: boolean = true;
  public frontendStatus: boolean = false;
  public scarmStatus: boolean = false;

  constructor(
    private healthCheckManagerService: HealthCheckManagerService,
  ) {}


  public async ngOnInit() {
      const servicesHealth = await this.healthCheckManagerService.getHealthCheck();
      this.statusesLoading = false;

      console.log(servicesHealth);
      (servicesHealth.details.frontend?.status === "up") ?
          this.frontendStatus = true :
          this.frontendStatus = false;
      (servicesHealth.details.scarm?.status === "up") ?
          this.scarmStatus = true :
          this.scarmStatus = false;
  }

}
