import { Component, OnInit } from '@angular/core';
import { UserManager } from '../../user-manager.service';
import { ConfirmEventType, ConfirmationService } from 'primeng/api';
import { TablePageEvent } from 'primeng/table';

@Component({
  selector: 'app-user-list-page',
  templateUrl: './user-list-page.component.html',
  styleUrls: ['./user-list-page.component.scss']
})
export class UserListPageComponent implements OnInit {
  public users: any[] = [];
  public isLoading: boolean = false;

  public itemsPerPage: number = 25;
  public page: number = 1;
  public totalRecords: number = 0;

  constructor(private userManager: UserManager, private confirmationService: ConfirmationService) {}

  public ngOnInit(): void {
    this.refreshList();
  }

  public async refreshList() {
    this.isLoading = true;
    const {data, totalAmount} = await this.userManager.getUsers(this.page, this.itemsPerPage);
    this.users = data;
    this.totalRecords = totalAmount;
    this.isLoading = false;
  }

  public onPageChange(event: TablePageEvent) {
    this.page = event.first/this.itemsPerPage + 1;
    this.refreshList();
  }

  public deleteUser(userId: string) {
    this.confirmationService.confirm({
      message: 'Czy chcesz usunąć użytkownika?',
      header: 'Potwierdzenie usunięcia',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.userManager.deleteUsers([userId]);
      },
      acceptLabel: 'Tak',
      rejectLabel: 'Nie',
    })
  }
}
