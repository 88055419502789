import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class UserManager {

  constructor(
    private apiClient: ApiClientService,
  ) {}

  public getUser(userId: string) {
    return this.apiClient.get<{email: string; firstName: string; lastName: string; organizationName: string; id: string}>(`/admin/users/${userId}`);
  }

  public getUsers(page: number, itemsPerPage: number) {
    const searchParams = new URLSearchParams();
    searchParams.set('page', page.toString());
    searchParams.set('itemsPerPage', itemsPerPage.toString());
    return <any> this.apiClient.get('/admin/users', searchParams);
  }

  public createUser(options: {
    email: string;
    password: string;
    firstName?: string;
    lastName?: string;
    organizationName?: string;
    activate?: boolean;
    role?: string,
  }) {
    return this.apiClient.post('/admin/users', options);
  }

  public updateUser(id: string, options: {
    firstName?: string;
    lastName?: string;
    organizationName?: string;
    password?: string;
  }) {
    return this.apiClient.put(`/admin/users/${id}`, options);
  }

  public deleteUsers(ids: string[]) {
    const searchParams = new URLSearchParams();
    searchParams.set('ids', ids.reduce((acc, id, index) => {
      acc = acc + id;
      if(index !== ids.length) acc = acc + ', ';
      return acc;
    }, ''));
    return this.apiClient.delete('/admin/users', searchParams);
  }
}
