<div class="relative flex-grow-1 flex flex-column">
  <div class="flex flex-row gap-3 bg-white border-noround-bottom border-bottom-none py-2 px-2" #toolbar>
    <div class="flex flex-row">
      <select name="font" title="font selection" class="ql-font border-none" [disabled]="!editor.hasFocus()">
        <option selected>Roboto</option>
        <option value="be-vietnam-pro">Be Vietnam Pro</option>
      </select>
      <select name="font" title="size selection" class="ql-size border-none" [disabled]="!editor.hasFocus()">
        <option value="small">Small</option>
        <option selected>Normal</option>
        <option value="large">Large</option>
        <option value="huge">Huge</option>
      </select>
    </div>

    <div>
      <button title="wyrównaj do lewej" type="button" class="bg-transparent border-none cursor-pointer" (click)="onAlignClick('left')" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-align-left text-xl"></i>
      </button>
      <button title="wyśrodkuj" type="button" class="bg-transparent border-none cursor-pointer" (click)="onAlignClick('center')" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-align-center text-xl"></i>
      </button>
      <button title="wyrównaj do prawej" type="button" class="bg-transparent border-none cursor-pointer" (click)="onAlignClick('right')" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-align-right text-xl"></i>
      </button>
      <button title="wyjustuj" type="button" class="bg-transparent border-none cursor-pointer" (click)="onAlignClick('justify')" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-align-justify text-xl"></i>
      </button>
    </div>

    <div>
      <button title="pogrubienie" type="button" class="bg-transparent border-none cursor-pointer ql-bold" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-bold text-xl"></i>
      </button>
      <button title="kursywa" type="button" class="bg-transparent border-none cursor-pointer ql-italic" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-italic text-xl"></i>
      </button>
      <button title="podkreślenie" type="button" class="bg-transparent border-none cursor-pointer ql-underline" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-underline text-xl"></i>
      </button>
      <button title="przekreślenie" type="button" class="bg-transparent border-none cursor-pointer ql-strike" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-strikethrough text-xl"></i>
      </button>
    </div>

    <div>
      <button title="kolor tekstu" type="button" class="bg-transparent border-none cursor-pointer" [disabled]="!editor.hasFocus()" (click)="textColor.showPicker($event)">
        <i class="mdi mdi-format-color-text text-xl"></i>
      </button>
      <color-picker #textColor (onColorPick)="setTextColor($event)"></color-picker>
      <button title="kolor wyróżnienia tekstu" type="button" class="bg-transparent border-none cursor-pointer" [disabled]="!editor.hasFocus()" (click)="backgroundColor.showPicker($event)">
        <i class="mdi mdi-format-color-highlight text-xl"></i>
      </button>
      <color-picker #backgroundColor (onColorPick)="setBackgroundColor($event)"></color-picker>
    </div>

    <div>
      <button title="indeks górny" type="button" class="bg-transparent border-none cursor-pointer ql-script" value="super" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-superscript text-xl"></i>
      </button>
      <button title="indeks dolny" type="button" class="bg-transparent border-none cursor-pointer ql-script" value="sub" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-subscript text-xl"></i>
      </button>
    </div>

    <div>
      <button title="nagłówek 1" type="button" class="bg-transparent border-none cursor-pointer" (click)="setHeader(1)" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-header-1 text-xl"></i>
      </button>
      <button title="nagłówek 2" type="button" class="bg-transparent border-none cursor-pointer" (click)="setHeader(2)" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-header-2 text-xl"></i>
      </button>
      <button title="nagłówek 3" type="button" class="bg-transparent border-none cursor-pointer" (click)="setHeader(3)" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-header-3 text-xl"></i>
      </button>
      <button title="nagłówek 4" type="button" class="bg-transparent border-none cursor-pointer" (click)="setHeader(4)" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-header-4 text-xl"></i>
      </button>
      <button title="cytat" type="button" class="bg-transparent border-none cursor-pointer ql-blockquote" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-quote-close text-xl"></i>
      </button>
      <button title="blok kodu" type="button" class="bg-transparent border-none cursor-pointer ql-code-block" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-xml text-xl"></i>
      </button>
    </div>

    <div>
      <button title="lista numerowana" type="button" class="bg-transparent border-none cursor-pointer" (click)="setListOrdered()" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-list-numbered text-xl"></i>
      </button>
      <button title="lista punktowana" type="button" class="bg-transparent border-none cursor-pointer" (click)="setListBullets()" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-list-bulleted text-xl"></i>
      </button>
      <button title="zmniejsz wcięcie" type="button" class="bg-transparent border-none cursor-pointer ql-indent" value="-1" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-indent-decrease text-xl"></i>
      </button>
      <button title="zwiększ wcięcie" type="button" class="bg-transparent border-none cursor-pointer ql-indent" value="+1" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-indent-increase text-xl"></i>
      </button>
    </div>

    <div>
      <button title="wstaw link" type="button" class="bg-transparent border-none ql-link cursor-pointer" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-link-variant text-xl"></i>
      </button>
      <button title="wstaw obraz" type="button" class="bg-transparent border-none cursor-pointer" (click)="insertImage()" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-image-outline text-xl"></i>
      </button>
      <button title="wstaw wideo" type="button" class="bg-transparent border-none ql-video cursor-pointer" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-file-video-outline text-xl"></i>
      </button>
      <button title="zwiększ funkcje" type="button" class="bg-transparent border-none ql-formula cursor-pointer" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-function-variant text-xl"></i>
      </button>
    </div>

    <div>
      <button title="usuń formatowanie" type="button" class="bg-transparent border-none ql-clean cursor-pointer" [disabled]="!editor.hasFocus()">
        <i class="mdi mdi-format-clear text-xl"></i>
      </button>
    </div>

  </div>
  <div class="bg-white border-noround-top border-gray-300 flex-grow-1 overflow-x-auto" #editorContainer></div>
</div>