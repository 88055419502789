<form class="flex flex-column" [formGroup]="formGroup" (ngSubmit)="submit()" *ngIf="!isLoading">
  <div class="flex flex-row align-items-center justify-content-between px-3 pt-3 bg-white pb-2">
    <div class="flex flex-row align-items-center">
      <a [routerLink]="'..'" class="hover:text-blue-400 transition-colors transition-duration-200">
        <i class="pi pi-arrow-left text-lg"></i>
      </a>
      <p class="m-0 ml-3 font-bold text-2xl">Dodaj subskrypcje</p>
    </div>
    <div>
      <button pButton icon="pi pi-save" pRipple label="Zapisz" class="p-button-success" type="submit" [disabled]="this.formGroup.invalid"></button>
    </div>
  </div>
  <div class="w-full bg-white">
    <p-tabMenu [model]="tabs" [activeItem]="activeTab" (activeItemChange)="onTabChange($event)">
      <ng-template pTemplate="item" let-item>
        <a class="p-menuitem-link flex justify-content-between align-items-center p-3">
            <div>
                <span [class]="item.icon"></span>
                <span> {{ item.label }}</span>
                <i class="pi pi-exclamation-triangle ml-2 text-red-500" *ngIf="item.formGroup?.invalid"></i>
            </div>
        </a>
    </ng-template></p-tabMenu>
  </div>

  <!-- Name section -->
  <div *ngIf="activeTab && activeTab.id == 'name'" class="mt-3 flex flex-column w-full px-3 gap-4" formGroupName="names">
    <small class="text-red-500" *ngIf="nameFormGroup.invalid">Przynajmniej jedno musi zostać uzupełnione.</small>
    <div class="flex flex-row w-full justify-content-between align-items-center gap-3" *ngFor="let language of getEntriesOfNames()">
      <div class="flex flex-column w-3rem">
        <span class="font-semibold text-3xl">
          {{ language }}
        </span>
      </div>
      <div class="flex flex-column flex-grow-1">
        <span class="p-input-icon-left ml-2 w-full">
          <i class="pi pi-language"></i>
          <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('name_' + language) | lowercase" [formControlName]="language" />
        </span>
      </div>
    </div>
  </div>

  <!-- Description section -->
  <div *ngIf="activeTab && activeTab.id == 'description'" class="mt-3 flex flex-column w-full gap-3 px-3" formGroupName="descriptions">
    <small class="text-red-500" *ngIf="descriptionFormGroup.invalid">Przynajmniej jedno musi zostać uzupełnione.</small>
    <div class="flex flex-column w-full gap-2" *ngFor="let language of getEntriesOfDescriptions()">
      <span class="font-semibold text-3xl">
        {{ language }}
      </span>
      <textarea rows="5" cols="30" pInputTextarea placeholder="Tłumaczenie" [formControlName]="language"></textarea>
    </div>
  </div>

  <!-- External link section -->
  <div *ngIf="activeTab && activeTab.id == 'external-links'" class="mt-3 flex flex-row w-full gap-3 px-3" formGroupName="linksAndOptions">
    <div class="flex flex-column gap-2">
      <div class="flex flex-row gap-4 align-items-center" style="height: 46px;">
        <span class="font-bold text-xl ml-2">SKU</span>
      </div>
      <span class="p-input-icon-left w-full">
        <i class="pi pi-language"></i>
        <input pInputText type="text" placeholder="Jednostka magazynowa (SKU)" class="w-full" formControlName="sku" />
      </span>
    </div>
    <div class="flex flex-column flex-grow-1 gap-2" formGroupName="terms">
      <div class="flex flex-row gap-4 align-items-center">
        <span class="font-bold text-xl ml-2">Odnośniki</span>
        <p-button icon="pi pi-plus" (onClick)="addTermURL()"></p-button>
      </div>
      <div class="flex flex-row gap-2" *ngFor="let term of getEntriesOfTerms()">
        <span class="p-input-icon-left w-full">
          <i class="pi pi-link"></i>
          <input pInputText type="text" placeholder="Odnośniki" class="w-full" [formControlName]="term" />
        </span>
        <p-button icon="pi pi-trash" severity="danger" (onClick)="removeTermURL(term)"></p-button>
      </div>
    </div>
  </div>
</form>