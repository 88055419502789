import { Component, OnInit } from '@angular/core';
import { LanguagesManager } from '../../languages-manager.service';
import { ConfirmationService } from 'primeng/api';
import { TablePageEvent } from 'primeng/table';

@Component({
  selector: 'app-language-list-page',
  templateUrl: './language-list-page.component.html',
  styleUrls: ['./language-list-page.component.scss']
})
export class LanguageListPageComponent implements OnInit {

  public itemsPerPage: number = 25;
  public page: number = 1;
  public totalRecords: number = 0;

  public languages: any[] = [];

  public isLoading: boolean = true;

  constructor(
    private languageManager: LanguagesManager,
    private confirmationService: ConfirmationService,
  ) {}

  public async ngOnInit() {
    this.refreshList()
  }

  public goToPage(page: number) {
    this.languageManager.getLanguages(1, 25);
  }

  public async refreshList() {
    this.isLoading = true;
    this.languages = await this.languageManager.getLanguages(1, 25);
    this.isLoading = false;
  }

  public onPageChange(event: TablePageEvent) {
    this.page = event.first/this.itemsPerPage + 1;
    this.refreshList();
  }

  public deleteLanguage(alpha2: string) {
    this.confirmationService.confirm({
      message: 'Czy chcesz usunąć język?',
      header: 'Potwierdzenie usunięcia',
      icon: 'pi pi-info-circle',
      accept: async () => {
        await this.languageManager.deleteLanguage(alpha2);
        await this.refreshList();
      },
      acceptLabel: 'Tak',
      rejectLabel: 'Nie',
    });
  }

}
