<div class="flex flex-column w-full h-full justify-content-center align-items-center surface-200" *ngIf="isLoading">
  <span class="font-bold text-4xl">
    Ładowanie danych
  </span>
</div>

<form class="flex flex-column w-full min-h-full" *ngIf="!isLoading && activeTab" [ngSwitch]="activeTab.id" [formGroup]="formGroup" (ngSubmit)="submit()">
  <div class="flex flex-row align-items-center justify-content-between bg-white pt-3 px-3">
    <div class="flex flex-row align-items-center">
      <a [routerLink]="'..'" class="hover:text-blue-400 transition-colors transition-duration-200">
        <i class="pi pi-arrow-left text-lg"></i>
      </a>
      <p class="m-0 ml-3 font-bold text-2xl">Dodaj produkt</p>
    </div>
    <div>
      <button title="Zapisz produkt" pButton pRipple label="Zapisz" class="p-button-success" type="submit" [disabled]="formGroup.invalid"></button>
    </div>
  </div>
  <div class="w-full bg-white">
    <p-tabMenu [model]="tabs" [activeItem]="activeTab" (activeItemChange)="onTabChange($event)"></p-tabMenu>
  </div>

  <div class="overflow-auto flex-grow-1 max-h-full">
    <div class="px-3 py-3 flex flex-column w-full justify-content-between align-items-center gap-3" *ngSwitchCase="'name'" [formGroup]="nameFormGroup">
      <div class="flex flex-row w-full justify-content-between align-items-center gap-3" *ngFor="let language of getEntriesOfProductNames()">
        <div class="flex flex-column w-3rem">
          <span class="font-semibold text-3xl">
            {{ language }}
          </span>
        </div>
        <div class="flex flex-column flex-grow-1">
          <span class="p-input-icon-left ml-2 w-full">
            <i class="pi pi-language"></i>
            <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('name_' + language) | lowercase" [formControlName]="language" />
          </span>
        </div>
      </div>
    </div>

    <div class="px-3 py-3 flex flex-row w-full justify-content-between gap-5" *ngSwitchCase="'integration'">
      <div class="flex flex-column w-6 gap-5">
        <div class="flex flex-row justify-content-between align-items-center gap-3">
          <div class="flex flex-column">
            <span class="font-semibold text-3xl">
              Nazwa komponentu
            </span>
          </div>
          <div class="flex flex-column flex-grow-1">
            <p-dropdown class="w-full" styleClass="w-full" [options]="components" formControlName="component" [showClear]="true" placeholder="Wybierz produkt"></p-dropdown>
          </div>
  
        </div>
  
        <div class="flex flex-row justify-content-between align-items-center gap-3">
          <div class="flex flex-column">
            <span class="font-semibold text-3xl">
              Wersja produktu
            </span>
          </div>
          <div class="flex flex-column flex-grow-1">
            <input pInputText id="productVersion" type="text" placeholder="Wersja" class="w-full" formControlName="productVersion">
          </div>
        </div>
  
        <div class="flex flex-column justify-content-between align-items-center gap-3" formGroupName="license">
          <div class="flex flex-column align-self-start">
            <span class="font-semibold text-3xl">
              Licencja
            </span>
          </div>
          <div class="relative flex flex-row border-1 border-round-lg w-full p-2 gap-2 align-items-stretch" style="border-color: #adb5bd;" *ngFor="let license of getProductLicenses().controls; index as index" [formGroupName]="index">
            <div class="absolute px-1 text-xs" style="left: 10px; top: -10px; color: #adb5bd; background-color: #f8f8f8;">
              #{{index + 1}}
            </div>
            <div class="flex flex-row w-full">
              <input pInputText id="licenceName" type="text" placeholder="Nazwa licencji" class="w-full" formControlName="licenseName">
            </div>
            <div class="flex flex-row w-full">
              <input pInputText id="licenceUrl" type="text" placeholder="Odnośnik do licencji" class="w-full" formControlName="licenseUrl">
            </div>
            <p-button severity="danger" class="h-full" styleClass="h-full" (onClick)="deleteProductLicense(index)">
              <i class="pi pi-times"></i>
            </p-button>
          </div>
          <div class="align-self-end">
            <p-button (onClick)="addProductLicenses()">Dodaj odnośnik</p-button>
          </div>
        </div>
      </div>

      <div class="flex flex-column w-6 gap-4" formArrayName="navLinks">
        <span class="font-semibold text-3xl">
          Dodatkowe łącza w nawigacji
        </span>
        <div class="flex flex-column gap-3">
          <div class="relative flex flex-row border-1 border-round-lg p-2 gap-2 align-items-start" style="border-color: #adb5bd;" *ngFor="let navLink of navLinksFormArray.controls; let index = index;" [formGroup]="navLink">
            <div class="absolute px-1 text-xs" style="left: 10px; top: -10px; color: #adb5bd; background-color: #f8f8f8;">
              #{{index + 1}}
            </div>
            <div class="flex flex-column w-6 gap-2" formGroupName="name">
              <div *ngFor="let language of getEntriesOfNavlinkNames(navLink)">
                <input pInputText type="text" [placeholder]="language + '|Nazwa łącza'" class="w-full" [formControlName]="language">
              </div>
            </div>
            <div class="flex flex-column w-6">
              <input pInputText type="text" placeholder="Adres łącza" class="w-full" formControlName="href">
            </div>
            <p-button severity="danger" (onClick)="removeNavLink(index)">
              <i class="pi pi-times"></i>
            </p-button>
          </div>
          <div class="align-self-end">
            <p-button (onClick)="addNavLink()">Dodaj odnośnik</p-button>
          </div>
        </div>
      </div>

    </div>

    <div class="px-3 py-3 flex flex-column w-full justify-content-between align-items-center gap-3" *ngSwitchCase="'summary'" [formGroup]="summaryFormGroup">
      <div class="flex flex-row w-full justify-content-between align-items-center gap-3" *ngFor="let language of getEntriesOfProductSummary()">
        <div class="flex flex-column w-3rem">
          <span class="font-semibold text-3xl">
            {{ language }}
          </span>
        </div>
        <div class="flex flex-column flex-grow-1">
          <span class="p-input-icon-left ml-2 w-full">
            <i class="pi pi-language"></i>
            <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('name_' + language) | lowercase" [formControlName]="language" />
          </span>
        </div>
      </div>
    </div>

    <div class="px-3 py-3 flex flex-column w-full justify-content-between align-items-center gap-3" *ngSwitchCase="'description'" [formGroup]="descriptionFormGroup">
      <div class="flex flex-row w-full justify-content-between align-items-center gap-3" *ngFor="let language of getEntriesOfProductDescription()">
        <div class="flex flex-column w-3rem">
          <span class="font-semibold text-3xl">
            {{ language }}
          </span>
        </div>
        <div class="flex flex-column flex-grow-1">
          <span class="p-input-icon-left ml-2 w-full">
            <i class="pi pi-language"></i>
            <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('name_' + language) | lowercase" [formControlName]="language" />
          </span>
        </div>
      </div>
    </div>

    <div class="px-3 py-3 flex flex-column w-full gap-3" *ngSwitchCase="'images'">
      <div class="flex flex-row gap-3">
        <div class="flex flex-column w-6 gap-2" #container>
          <div class="flex flex-row align-items-center gap-3">
            <span class="font-bold text-2xl">
              Logo
            </span>
            <input id="logoUpload" type="file" class="file-upload" (change)="onLogoUpload($event)" accept="image/*" class="hidden">
            <label for="logoUpload" class="cursor-pointer bg-primary button border-round-lg font-semibold">Wybierz logo</label>
          </div>
          <p-image *ngIf="uploadedLogoControl.value" [src]="uploadedLogoControl.value" [preview]="true" alt="logo_preview" [width]="container.offsetWidth.toString()">
            <ng-template pTemplate="indicator">
              <i class="pi pi-eye"></i>
            </ng-template>
          </p-image>
        </div>
        <div class="flex flex-column w-6 gap-2">
          <div class="flex flex-row gap-2 align-items-center">
            <span class="font-bold text-2xl">
              Zrzuty ekranu
            </span>
            <input id="screenshotUpload" type="file" class="file-upload" (change)="onScreenshotsUpload($event)" accept="image/*" class="hidden">
            <label for="screenshotUpload" class="cursor-pointer bg-primary button border-round-lg font-semibold">Dodaj zrzut ekranu</label>
          </div>
          <div class="flex flex-column">
            <ng-container *ngFor="let imgSrc of uploadedScreenshotsControl.controls; index as index">
              <div class="relative w-full" #container>
                <p-button [rounded]="true" [outlined]="true" severity="danger" icon="pi pi-trash" class="absolute top-0 right-0 z-5 mt-2 mr-2" (onClick)="removeScreenshot(index)"></p-button>
                <p-image *ngIf="imgSrc.value" [src]="imgSrc.value" [preview]="true" alt="logo_preview" [width]="container.offsetWidth.toString()">
                  <ng-template pTemplate="indicator">
                    <i class="pi pi-eye"></i>
                  </ng-template>
                </p-image>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="px-3 py-3 flex flex-row w-full justify-content-between align-items-center gap-3" *ngSwitchCase="'tags'">
      <p-pickList [source]="sourceProducts" [target]="targetProducts" sourceHeader="Dostępne" targetHeader="Wybrane" [dragdrop]="true" [responsive]="true" [showSourceControls]="false" [showTargetControls]="false" [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }" breakpoint="1400px" class="w-full">
        <ng-template let-tag pTemplate="item">
          <div class="flex flex-wrap p-2 font-bold">
            {{ tag.name | translate }}
          </div>
        </ng-template>
      </p-pickList>
    </div>

    <div *ngSwitchCase="'deployments'" formGroupName="deployments" class="min-h-full max-h-full flex flex-column">
      <div class="w-full flex flex-row p-2 gap-2 align-items-center bg-white flex-wrap">
        <p-chip *ngFor="let deployment of getDeployments()" styleClass="flex flex-row gap-1 cursor-pointer" (click)="selectDeployment(deployment)" [class.selected]="this.selectedDeployment === deployment">
          <span class="p-2" *ngIf="getNameOfDeployment(deployment)">
            {{ getNameOfDeployment(deployment) }}
          </span>
          <span class="p-2" *ngIf="!getNameOfDeployment(deployment)">
            Środowisko #{{deployment}}
          </span>
          <i class="pi pi-times-circle cursor-pointer" (click)="removeDeployment(deployment)"></i>
        </p-chip>
        <p-button pRipple size="small" label="Dodaj środowisko" icon="pi pi-plus" [rounded]="true" (click)="addDeployment()"></p-button>
      </div>

      <div class="w-full bg-white">
        <p-tabMenu [model]="deploymentTabs" [activeItem]="deploymentActiveTab" (activeItemChange)="onDeplymentTabChange($event)"></p-tabMenu>
      </div>

      <div *ngIf="deploymentActiveTab" [ngSwitch]="deploymentActiveTab.id" [formGroup]="this.getCurrentDeployment()" class="flex max-h-full overflow-auto flex-grow-1">
        <div class="px-3 py-3 flex flex-column w-full justify-content-between align-items-center gap-3" *ngSwitchCase="'name'" formGroupName="names">
          <div class="flex flex-row w-full justify-content-between align-items-center gap-3" *ngFor="let language of getEntriesOfProductDescription()">
            <div class="flex flex-column w-3rem">
              <span class="font-semibold text-3xl">
                {{ language }}
              </span>
            </div>
            <div class="flex flex-column flex-grow-1">
              <span class="p-input-icon-left ml-2 w-full">
                <i class="pi pi-language"></i>
                <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('name_' + language) | lowercase" [formControlName]="language" />
              </span>
            </div>
          </div>
        </div>

        <div class="px-3 py-3 flex flex-row w-full align-items-center gap-3 w-6" *ngSwitchCase="'type'">
          <div *ngFor="let deploymentType of deploymentTypes" class="field-checkbox">
            <p-radioButton [inputId]="deploymentType.id" [value]="deploymentType.id" formControlName="type"></p-radioButton>
            <label [for]="deploymentType.id" class="ml-2">{{ deploymentType.name | translate }}</label>
          </div>
        </div>

        <div class="flex flex-column w-full h-full gap-3" *ngSwitchCase="'traits'" formGroupName="traits">
          <div class="flex flex-row flex-wrap gap-2 align-items-center bg-white py-2 px-2 border-bottom-2 border-300">
            <p-chip *ngFor="let traitId of getTraitsFields()" styleClass="flex flex-row gap-1 cursor-pointer" (click)="selectTrait(traitId)" [class.selected]="this.selectedTrait === traitId">
              <span class="p-2" *ngIf="getNameOfTrait(traitId)">
                {{ getNameOfTrait(traitId) }}
              </span>
              <span class="p-2" *ngIf="!getNameOfTrait(traitId)">
                Cecha #{{traitId}}
              </span>
              <i class="pi pi-times-circle cursor-pointer" (click)="removeTraitField(traitId)"></i>
            </p-chip>
            <p-button pRipple size="small" label="Dodaj ceche" icon="pi pi-plus" [rounded]="true" (click)="addTraitField()"></p-button>
          </div>

          <div class="flex flex-column h-full w-full justify-content-center align-items-center" *ngIf="!this.selectedTrait">
            <span class="font-semibold text-4xl">
              Wybierz cechę do edycji
            </span>
          </div>

          <div class="flex flex-column gap-2 px-2" *ngIf="selectedTrait" [formGroup]="getCurrentTrait()">
            <div class="flex flex-row gap-2 align-items-center" *ngFor="let traitNameAlpha of getTraitNameFields()">
              <span class="font-semibold text-3xl w-3rem">
                {{ traitNameAlpha }}
              </span>
              <span class="p-input-icon-left ml-2 w-full">
                <i class="pi pi-language"></i>
                <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('trait_name_' + traitNameAlpha) | lowercase" [formControlName]="traitNameAlpha" />
              </span>
            </div>
          </div>
        </div>

        <div class="px-3 py-3 flex flex-column w-full gap-3 w-full" *ngSwitchCase="'documentations'" formArrayName="documentations">
          <div class="relative flex flex-row border-1 border-round-lg w-full p-2 gap-2 align-items-stretch" style="border-color: #adb5bd;" *ngFor="let documentations of getDocsOfCurrentDeployment().controls; index as index" [formGroupName]="index">
            <div class="absolute px-1 text-xs" style="left: 10px; top: -10px; color: #adb5bd; background-color: #f8f8f8;">
              #{{index + 1}}
            </div>
            <div class="flex flex-row w-full">
              <input type="text" placeholder="Nazwa" class="w-full" pInputText formControlName="name" />
            </div>
            <div class="flex flex-row w-full">
              <input type="text" placeholder="Odnośnik" class="w-full" pInputText formControlName="address" />
            </div>
            <p-button severity="danger" class="h-full" styleClass="h-full" (onClick)="deleteLinkFromDeployment(index)">
              <i class="pi pi-times"></i>
            </p-button>
          </div>
          <div>
            <p-button (onClick)="addLinkToDeployment()">Dodaj odnośnik</p-button>
          </div>
        </div>

        <div class="flex flex-column w-full align-items-center min-w-full max-w-full" *ngSwitchCase="'instructions'" formGroupName="instructions">
          <div class="relative flex flex-column w-full flex-grow-1 flex-shrink-0 border-gray-300" style="flex-basis: 50%; height: 50%;" *ngFor="let instruction of getEntriesOfInstructionsOfCurrentDeployment(); index as index; count as count" [ngClass]="{'border-top-1': index > 0}" [style]="{'z-index': count - index}">
            <editor class="flex flex-grow-1" style="height: 100%;" [formControlName]="instruction"></editor>
            <span class="absolute font-semibold text-3xl text-black-alpha-30" style="right: 10px; bottom: 10px; pointer-events: none;">
              {{instruction}}
            </span>
          </div>
        </div>

        <div class="px-3 py-3 flex flex-column w-full gap-2" *ngSwitchCase="'integration'">
          <div class="flex flex-row justify-content-between align-items-center gap-3">
            <div class="flex flex-column">
              <span class="font-semibold text-3xl">
                ID
              </span>
            </div>
            <div class="flex flex-column flex-grow-1">
              <p-dropdown class="w-full" styleClass="w-full" [options]="products" [formControl]="getIntegrationFormControl()" [showClear]="true" placeholder="Wybierz produkt"></p-dropdown>
            </div>
          </div>
          <div class="flex flex-row justify-content-between align-items-center gap-3">
            <div class="flex flex-column">
              <span class="font-semibold text-3xl">
                Czy domyślny?
              </span>
            </div>
            <div class="flex flex-column flex-grow-1 justify-content-center">
              <p-checkbox formControlName="default" [binary]="true" inputId="binary"></p-checkbox>
            </div>
          </div>
          <div class="flex flex-row justify-content-between align-items-center gap-3">
            <span class="p-input-icon-left w-full">
              <i class="pi pi-globe"></i>
              <input pInputText type="text" placeholder="Link do artefaktu" class="w-full" id="url" formControlName="url" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>