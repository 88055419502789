import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './editor.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';



@NgModule({
  declarations: [
    EditorComponent,
    ColorPickerComponent,
  ],
  exports: [
    EditorComponent,
  ],
  imports: [
    CommonModule,
    OverlayPanelModule,
  ]
})
export class EditorModule { }
