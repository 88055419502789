import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddProductPageComponent } from './add-product-page/add-product-page.component';
import { ProductListPageComponent } from './product-list-page/product-list-page.component';
import { EditProductPageComponent } from './edit-product-page/edit-product-page.component';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { PickListModule } from 'primeng/picklist';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ChipModule } from 'primeng/chip';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { EditorModule } from '../editor/editor.module';

@NgModule({
  declarations: [
    AddProductPageComponent,
    ProductListPageComponent,
    EditProductPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    PickListModule,
    TabMenuModule,
    InputTextModule,
    ChipModule,
    ButtonModule,
    InputTextareaModule,
    ReactiveFormsModule,
    RadioButtonModule,
    TranslateModule,
    CheckboxModule,
    DropdownModule,
    FileUploadModule,
    ImageModule,
    EditorModule,
  ]
})
export class ProductPageModule { }
