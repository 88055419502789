import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class ProductTagManager {

  constructor(private apiClient: ApiClientService) { }

  public getProductTags(page: number, itemsPerPage: number) {
    const searchParams = new URLSearchParams();

    searchParams.set('page', page.toString());
    searchParams.set('itemsPerPage', itemsPerPage.toString());

    return this.apiClient.get<{data: {}[], totalAmount: number}>('/admin/product-tags', searchParams);
  }

  public createProductTag(options: {
    names: Record<string, string>;
    products: string[];
  }) {
    return this.apiClient.post('/admin/product-tags', options);
  }

  public getProductTag(id: string) {
    return this.apiClient.get<{ id: string; name: string; }>(`/admin/product-tags/${id}`);
  }

  public updateProductTag(id: string, options: {
    names?: Record<string, string>;
  }) {
    return this.apiClient.put(`/admin/product-tags/${id}`, options);
  }

  public deleteProductTag(id: string) {
    return this.apiClient.delete(`/admin/product-tags/${id}`);
  }
}
