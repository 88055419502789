<div class="flex flex-column h-full flex-auto">
    <p-table styleClass="white-header" [value]="products" [loading]="isLoading" ngClass="h-full" [scrollable]="true" scrollHeight="flex" [paginator]="true" [rows]="itemsPerPage" [totalRecords]="totalRecords" [lazy]="true" (onPage)="pageChange($event)">
        <ng-template pTemplate="caption">
            <div class="flex flex-row justify-content-between">
                <div class="flex align-items-center">
                    <p class="m-0 font-bold text-2xl">Produkty</p>
                </div>
                <div class="flex flex-row">
                    <a [routerLink]="['add']"
                        class="no-underline flex flex-row gap-2 align-items-center border-1 border-round-md py-2 px-3 bg-primary">
                        <i class="pi pi-plus"></i>
                        <span>Dodaj</span>
                    </a>
                    <button title="Wyczyść" type="button" pButton label="Wyczyść" class="p-button-outlined ml-2"
                        icon="pi pi-filter-slash"></button>
                    <span class="p-input-icon-left ml-2">
                        <i class="pi pi-search"></i>
                        <input class="input-bg-grey" pInputText type="text" placeholder="Wyszukuj produkty" />
                    </span>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="200" class="text-center">
              Nie znaleziono produktów
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>
                    lp.
                </th>
                <th>
                    Nazwa
                </th>
                <th>
                    Tagi
                </th>
                <th>
                    Środowiska
                </th>
                <th>
                    Polubienia
                </th>
                <th>
                    Opcje
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
            <tr>
                <td>
                    {{ rowIndex + 1 }}
                </td>
                <td>
                    {{ product.name | translate }}
                </td>
                <td>
                    {{ product.tags }}
                </td>
                <td>
                    {{ product.deploymentTypes }}
                </td>
                <td>
                    {{ product.likes }}
                </td>
                <td class="flex flex-row flex-shrink">
                    <a [routerLink]="[product.id]" class="border-circle flex link justify-content-center align-items-center w-3rem h-3rem">
                        <i class="pi pi-eye"></i>
                    </a>
                    <p-button icon="pi pi-trash" [rounded]="true" [text]="true" severity="danger"
                        (onClick)="deleteProduct(product.id)"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
