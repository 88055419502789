import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddTranslationPageComponent } from './add-translation-page/add-translation-page.component';
import { TranslationListPageComponent } from './translation-list-page/translation-list-page.component';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { EditTranslationPageComponent } from './edit-translation-page/edit-translation-page.component';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    AddTranslationPageComponent,
    TranslationListPageComponent,
    EditTranslationPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    InputTextModule,
    ButtonModule,
    TooltipModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
  ]
})
export class TranslationPageModule { }
