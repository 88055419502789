import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddProductTagPageComponent } from './add-product-tag-page/add-product-tag-page.component';
import { ProductTagListPageComponent } from './product-tag-list-page/product-tag-list-page.component';
import { RouterModule } from '@angular/router';
import { TabMenuModule } from 'primeng/tabmenu';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { ReactiveFormsModule } from '@angular/forms';
import { EditProductTagPageComponent } from './edit-product-tag-page/edit-product-tag-page.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    AddProductTagPageComponent,
    ProductTagListPageComponent,
    EditProductTagPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TabMenuModule,
    PickListModule,
    TableModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    ReactiveFormsModule,
    TranslateModule,
  ]
})
export class ProductTagsPageModule { }
