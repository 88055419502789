import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { UserListPageComponent } from './user-page/user-list-page/user-list-page.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { UserProfilePageComponent } from './user-profile-page/user-profile-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoggedPageComponent } from './logged-page/logged-page.component';
import { authGuard } from './auth.guard';
import { RemindPasswordPageComponent } from './remind-password/remind-password-page.component';
import { ProductTagListPageComponent } from './product-tags-page/product-tag-list-page/product-tag-list-page.component';
import { TranslationListPageComponent } from './translation-page/translation-list-page/translation-list-page.component';
import { LanguageListPageComponent } from './language-page/language-list-page/language-list-page.component';
import { SubscriptionListPageComponent } from './subscription-page/subscription-list-page/subscription-list-page.component';
import { ServerListPageComponent } from './server-list-page/server-list-page.component';
import { AddUserPageComponent } from './user-page/add-user-page/add-user-page.component';
import { AddProductTagPageComponent } from './product-tags-page/add-product-tag-page/add-product-tag-page.component';
import { AddTranslationPageComponent } from './translation-page/add-translation-page/add-translation-page.component';
import { AddLanguagePageComponent } from './language-page/add-language-page/add-language-page.component';
import { AddSubscriptionPageComponent } from './subscription-page/add-subscription-page/add-subscription-page.component';
import { DeploymentTypesListPageComponent } from './deployment-type-page/deployment-types-list-page/deployment-types-list-page.component';
import { AddDeploymentTypePageComponent } from './deployment-type-page/add-deployment-type-page/add-deployment-type-page.component';
import { EditUserPageComponent } from './user-page/edit-user-page/edit-user-page.component';
import { ProductListPageComponent } from './product-page/product-list-page/product-list-page.component';
import { AddProductPageComponent } from './product-page/add-product-page/add-product-page.component';
import { EditProductPageComponent } from './product-page/edit-product-page/edit-product-page.component';
import { EditTranslationPageComponent } from './translation-page/edit-translation-page/edit-translation-page.component';
import { EditDeploymentTypePageComponent } from './deployment-type-page/edit-deployment-type-page/edit-deployment-type-page.component';
import { EditProductTagPageComponent } from './product-tags-page/edit-product-tag-page/edit-product-tag-page.component';
import { PostsTagsListPageComponent } from './posts-tags-page/posts-tags-list-page/posts-tags-list-page.component';
import { AddPostPageComponent } from './posts-page/add-post-page/add-post-page.component';
import { EditPostPageComponent } from './posts-page/edit-post-page/edit-post-page.component';
import { AddPostsTagPageComponent } from './posts-tags-page/add-posts-tag-page/add-posts-tag-page.component';
import { EditPostsTagPageComponent } from './posts-tags-page/edit-posts-tag-page/edit-posts-tag-page.component';
import { PostListPageComponent } from './posts-page/post-list-page/post-list-page.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'remind-password',
    component: RemindPasswordPageComponent,
  },
  {
    path: '',
    canActivate: [authGuard],
    component: LoggedPageComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardPageComponent,
      },
      {
        path: 'users',
        children: [
          {
            path: '',
            component: UserListPageComponent,
          },
          {
            path: 'add',
            component: AddUserPageComponent,
          },
          {
            path: ':userId',
            component: EditUserPageComponent,
          },
        ]
      },
      {
        path: 'posts',
        children: [
          {
            path: '',
            component: PostListPageComponent,
          },
          {
            path: 'add',
            component: AddPostPageComponent,
          },
          {
            path: ':postId',
            component: EditPostPageComponent,
          }
        ],
      },
      {
        path: 'posts-tags',
        children: [
          {
            path: '',
            component: PostsTagsListPageComponent,
          },
          {
            path: 'add',
            component: AddPostsTagPageComponent,
          },
          {
            path: ':postTagId',
            component: EditPostsTagPageComponent,
          },
        ],
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            component: ProductListPageComponent,
          },
          {
            path: 'add',
            component: AddProductPageComponent,
          },
          {
            path: ':productId',
            component: EditProductPageComponent,
          }
        ]
      },
      {
        path: 'deployment-types',
        children: [
          {
            path: '',
            component: DeploymentTypesListPageComponent,
          },
          {
            path: 'add',
            component: AddDeploymentTypePageComponent,
          },
          {
            path: ':deploymentTypeId',
            component: EditDeploymentTypePageComponent,
          },
        ],
      },
      {
        path: 'settings',
        component: SettingsPageComponent,
      },
      {
        path: 'profile',
        component: UserProfilePageComponent,
      },
      {
        path: 'product-tags',
        children: [
          {
            path: '',
            component: ProductTagListPageComponent,
          },
          {
            path: 'add',
            component: AddProductTagPageComponent,
          },
          {
            path: ':productTagId',
            component: EditProductTagPageComponent,
          }
        ]
      },
      {
        path: 'translations',
        children: [
          {
            path: '',
            component: TranslationListPageComponent,
          },
          {
            path: 'add',
            component: AddTranslationPageComponent,
          },
          {
            path: ':textContentId',
            component: EditTranslationPageComponent,
          },
        ]
      },
      {
        path: 'languages',
        children: [
          {
            path: '',
            component: LanguageListPageComponent,
          },
          {
            path: 'add',
            component: AddLanguagePageComponent,
          },
        ]
      },
      {
        path: 'subscriptions',
        children: [
          {
            path: '',
            component: SubscriptionListPageComponent,
          },
          {
            path: 'add',
            component: AddSubscriptionPageComponent,
          }
        ]
      },
      {
        path: 'servers',
        component: ServerListPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
