import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ProductToAdd } from '../../product-tags-page/product-to-add';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguagesManager } from 'src/app/languages-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { DeploymentTypesManager } from 'src/app/deployment-types-manager.service';
import { ProductTagManager } from 'src/app/product-tag-manager.service';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { ProductsManager } from 'src/app/products-manager.service';
import { FormErrorMessage, ServerErrorMessage } from 'src/app/messages';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-product-page',
  templateUrl: './add-product-page.component.html',
  styleUrls: ['./add-product-page.component.scss']
})
export class AddProductPageComponent implements OnInit {

  isLoading: boolean = false;

  tabs: MenuItem[] | undefined;
  deploymentTabs: MenuItem[] | undefined;

  activeTab: MenuItem | undefined;
  deploymentActiveTab: MenuItem | undefined;

  sourceProducts: ProductToAdd[] = [];
  targetProducts: ProductToAdd[] = [];

  formGroup!: FormGroup;
  nameFormGroup!: FormGroup;
  summaryFormGroup!: FormGroup;
  descriptionFormGroup!: FormGroup;
  deploymentsFormGroup!: FormGroup;
  licenseFormArray!: FormArray<FormGroup>;
  navLinksFormArray!: FormArray<FormGroup>;

  languages: any[] = [];
  deploymentTypes: any[] = [];
  productTags: any[] = [];
  products: string[] = [];

  selectedDeployment: string = '1';

  components: string[] = [];

  selectedTrait: string | null = null;

  uploadedScreenshotsControl!: FormArray<FormControl<string | ArrayBuffer | null>>;
  uploadedLogoControl!: FormControl<string | ArrayBuffer | null>;
  componentControl!: FormControl<string>;
  productVersionControl!: FormControl<string>;

  constructor(
    private langaugesManager: LanguagesManager,
    private translate: TranslateService,
    private deploymentTypesManager: DeploymentTypesManager,
    private productTagManager: ProductTagManager,
    private productsManager: ProductsManager,
    private messageService: MessageService,
    private router: Router,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;
    this.formGroup = new FormGroup({});

    this.navLinksFormArray = new FormArray<any>([]);
    this.formGroup.addControl('navLinks', this.navLinksFormArray);

    this.componentControl = new FormControl();
    this.formGroup.addControl('component', this.componentControl);

    this.licenseFormArray = new FormArray<FormGroup>([]);

    this.licenseFormArray.push(new FormGroup({
      'licenseName': new FormControl(),
      'licenseUrl': new FormControl(),
    }))

    this.formGroup.addControl('license', this.licenseFormArray);

    this.productVersionControl = new FormControl();
    this.formGroup.addControl('productVersion', this.productVersionControl);

    this.uploadedScreenshotsControl = new FormArray<FormControl<string | ArrayBuffer | null>>([]);
    this.formGroup.addControl('screenshots', this.uploadedScreenshotsControl);

    this.nameFormGroup = new FormGroup({}, [
      atLeastOne,
    ]);

    this.formGroup.addControl('names', this.nameFormGroup);

    this.deploymentsFormGroup = new FormGroup({});

    this.formGroup.addControl('deployments', this.deploymentsFormGroup);

    this.summaryFormGroup = new FormGroup({}, [
      atLeastOne
    ]);

    this.formGroup.addControl('summary', this.summaryFormGroup);

    this.descriptionFormGroup = new FormGroup({}, [
      atLeastOne
    ]);

    this.formGroup.addControl('description', this.descriptionFormGroup);

    this.formGroup.addControl('tags', new FormControl([]));

    this.uploadedLogoControl = new FormControl(null, [
      Validators.required,
    ]);

    this.formGroup.addControl('logo', this.uploadedLogoControl);

    this.tabs = [
      {
        label: 'Nazwa',
        icon: 'pi pi-language',
        id: 'name',
      },
      {
        label: 'Podsumowanie',
        icon: 'pi pi-align-justify',
        id: 'summary',
      },
      {
        label: 'Opis',
        icon: 'pi pi-align-justify',
        id: 'description',
      },
      {
        label: 'Logo i zrzuty ekranu',
        icon: 'pi pi-images',
        id: 'images',
      },
      {
        label: 'Integracja',
        icon: 'pi pi-link',
        id: 'integration',
      },
      {
        label: 'Tagi',
        icon: 'pi pi-hashtag',
        id: 'tags',
      },
      {
        label: 'Środowiska',
        icon: 'pi pi-box',
        id: 'deployments',
      },
    ];
    this.activeTab = this.tabs[0];

    this.deploymentTabs = [
      {
        label: 'Nazwa',
        icon: 'pi pi-language',
        id: 'name',
      },
      {
        label: 'Typ',
        icon: 'pi pi-list',
        id: 'type',
      },
      {
        label: 'Cechy',
        icon: 'pi pi-info',
        id: 'traits',
      },
      {
        label: 'Dokumentacja',
        icon: 'bi bi-file-earmark-post',
        id: 'documentations',
      },
      {
        label: 'Instrukcja',
        icon: 'bi bi-list-check',
        id: 'instructions',
      },
      {
        label: 'Integracja',
        icon: 'pi pi-link',
        id: 'integration',
      },
    ];

    this.deploymentActiveTab = this.deploymentTabs[0];

    this.languages = await this.langaugesManager.getLanguages(1, 0);

    for(let language of this.languages) {
      this.nameFormGroup.addControl(language.alpha2, new FormControl());
      this.summaryFormGroup.addControl(language.alpha2, new FormControl());
      this.descriptionFormGroup.addControl(language.alpha2, new FormControl());
    }

    const deploymentTypesPromise = this.getDeploymentTypes();
    const productTagsPromise = this.getProductTags();

    this.components = await this.productsManager.getComponentsHints();

    await Promise.all([deploymentTypesPromise, productTagsPromise])

    this.products = (await this.productsManager.getScarmProductsHints()).map((hint) => hint.name);

    this.addDeployment();
    this.isLoading = false;
  }

  public addNavLink() {
    const nameFormGroup = new FormGroup({});

    for(let language of this.languages) {
      const langaugeFormControl = new FormControl('', [Validators.required]);
      nameFormGroup.addControl(language.alpha2, langaugeFormControl);
    }

    this.navLinksFormArray.push(new FormGroup({
      name: nameFormGroup,
      href: new FormControl('', [Validators.required]),
    }));
  }

  public removeNavLink(index: number)  {
    this.navLinksFormArray.removeAt(index);
  }

  public getProductLicenses() {
    return this.licenseFormArray as FormArray<FormGroup>;
  }

  public deleteProductLicense(index: number) {
    this.licenseFormArray.removeAt(index);
  }

  public addProductLicenses() {

    this.licenseFormArray.push(new FormGroup({
      'licenseName': new FormControl(''),
      'licenseUrl': new FormControl(''),
    }));
  }

  private async getProductTags() {
    let {data} = await this.productTagManager.getProductTags(1, 0);
    this.productTags = data;
    this.sourceProducts = this.productTags.map((deploymentType) => {
      return {
        id: deploymentType.id,
        name: deploymentType.name,
      };
    })
  }

  private async getDeploymentTypes() {
    let {data} = await this.deploymentTypesManager.getDeploymentTypes(1, 0);
    this.deploymentTypes = data;
    return;
  }

  public addDeployment() {
    const namesFormGroup = new FormGroup({}, [
      atLeastOne
    ]);

    const typeFormControl = new FormControl(null, [
      Validators.required,
    ]);

    const integrationFormControl = new FormControl(null, [
      Validators.required,
    ]);

    const urlFormControl = new FormControl('', [
      Validators.required
    ]);

    const documentationsFormArray = new FormArray<FormGroup>([]);

    documentationsFormArray.push(new FormGroup({
      'name': new FormControl(''),
      'address': new FormControl(''),
    }));

    const instructionsFormGroup = new FormGroup({});

    const traitsFormGroup = new FormGroup({});

    const defaultEnvironmentFormControl = new FormControl(false);

    const deploymentFormGroup = new FormGroup({
      'names': namesFormGroup,
      'type': typeFormControl,
      'integration': integrationFormControl,
      'default': defaultEnvironmentFormControl,
      'traits': traitsFormGroup,
      'url': urlFormControl,
      'instructions': instructionsFormGroup,
      'documentations': documentationsFormArray,
    });

    for(let language of this.languages) {
      namesFormGroup.addControl(language.alpha2, new FormControl(''));
      instructionsFormGroup.addControl(language.alpha2, new FormControl([]));
    }

    const keys = Object.keys(this.deploymentsFormGroup.controls);
    const controlsAmount = keys.length;
    const lastKey = Object.keys(this.deploymentsFormGroup.controls)[controlsAmount - 1] ?? 0;

    this.deploymentsFormGroup.addControl((parseInt(lastKey) + 1).toString(), deploymentFormGroup);
  }

  public deleteLinkFromDeployment(index: number) {
    const currentDeployment = this.getCurrentDeployment();

    const documentationsFormArray = currentDeployment.get('documentations') as FormArray<FormGroup>;

    documentationsFormArray.removeAt(index);
  }

  public addLinkToDeployment() {
    const currentDeployment = this.getCurrentDeployment();

    const documentationsFormArray = currentDeployment.get('documentations') as FormArray<FormGroup>;

    documentationsFormArray.push(new FormGroup({
      'name': new FormControl(''),
      'address': new FormControl(''),
    }));
  }

  public getDocsOfCurrentDeployment() {
    const currentDeployment = this.getCurrentDeployment();

    return currentDeployment.get('documentations') as FormArray<FormGroup>;
  }

  public getInstructionsOfCurrentDeployment() {
    const currentDeployment = this.getCurrentDeployment();

    return currentDeployment.get('instructions') as FormGroup<{ [name: string]: FormControl<{}>}>;
  }

  public getEntriesOfInstructionsOfCurrentDeployment() {
    return Object.keys(this.getInstructionsOfCurrentDeployment().controls);
  }

  public addTraitField() {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;

    const keys = Object.keys(traitsFormGroup.controls);

    const traitIndex = !Number.isNaN(parseInt(keys[keys.length - 1]) + 1) ? parseInt(keys[keys.length - 1]) + 1 : 0;

    const traitNamesFormGroup = new FormGroup({}, [
      atLeastOne,
    ]);

    for(const language of this.languages) {
      traitNamesFormGroup.addControl(language.alpha2, new FormControl(''));
    }

    traitsFormGroup.addControl(traitIndex.toString(), traitNamesFormGroup);
  }

  public removeTraitField(traitId: string) {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;

    traitsFormGroup.removeControl(traitId);
    if(traitId === this.selectedTrait) this.selectedTrait = null;
  }

  public getNameOfTrait(traitId: string) {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;

    return traitsFormGroup.get(traitId)?.get(this.translate.currentLang.toUpperCase())?.value;
  }

  public selectTrait(traitId: string) {
    this.selectedTrait = traitId;
  }

  public getCurrentTrait() {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;
    return traitsFormGroup.get(this.selectedTrait!) as FormGroup;
  }

  public getTraitNameFields() {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;
    const traitNamesGroup = traitsFormGroup.get(this.selectedTrait!) as FormGroup;

    return Object.keys(traitNamesGroup.controls);
  }

  public getTraitsFields() {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    const traitsFormGroup = formGroup.get('traits') as FormGroup;
    return Object.keys(traitsFormGroup.controls);
  }

  public getDeployments() {
    return Object.keys(this.deploymentsFormGroup.controls);
  }

  public onDeplymentTabChange(event: MenuItem) {
    this.deploymentActiveTab = event;
  }

  public onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  public getEntriesOfProductNames() {
    return Object.keys(this.nameFormGroup.controls);
  }

  public getEntriesOfProductSummary() {
    return Object.keys(this.summaryFormGroup.controls);
  }

  public getEntriesOfProductDescription() {
    return Object.keys(this.descriptionFormGroup.controls);
  }

  public getEntriesOfNavlinkNames(navLink: FormGroup) {
    return Object.keys((navLink.get('name') as any).controls);
  }

  public removeDeployment(id: string) {
    if(Object.keys(this.deploymentsFormGroup.controls).length > 1) {
      this.deploymentsFormGroup.removeControl(id);
      this.deploymentsFormGroup.markAsDirty();
    }
    this.selectDeployment(Object.keys(this.deploymentsFormGroup.controls)[0]);
  }

  public getNameOfDeployment(id: string) {
    const deploymentFormGroup = this.deploymentsFormGroup.get(id) as FormGroup;
    const namesFormGroup = deploymentFormGroup.get('names') as FormGroup;
    const nameFormControl = namesFormGroup.get(this.translate.currentLang.toUpperCase());

    return nameFormControl?.value;
  }

  public selectDeployment(id: string) {
    this.selectedTrait = null;
    this.selectedDeployment = id;
  }

  public getCurrentDeployment() {
    const formGroup = this.deploymentsFormGroup.get(this.selectedDeployment) as FormGroup;
    return formGroup;
  }

  private parseDeploymentForRequest() {
    return this.getDeployments().map((deploymentId) => {
      const deploymentFormGroup = this.deploymentsFormGroup.get(deploymentId) as FormGroup;

      const namesFormGroup = deploymentFormGroup.get('names') as FormGroup;

      const traitsFormGroup = deploymentFormGroup.get('traits') as FormGroup;

      const names = Object.keys(namesFormGroup.controls).reduce<Record<string, string>>((acc, alpha2) => {
        const translation = namesFormGroup.get(alpha2);
        if(!translation?.value) return acc;
        acc[alpha2] = translation.value;
        return acc;
      }, {});

      const traits = Object.keys(traitsFormGroup.controls).reduce<Record<string, string>[]>((acc, traitId) => {
        const traitFormGroup = traitsFormGroup.get(traitId) as FormGroup;

        const translations = Object.keys(traitFormGroup.controls).reduce<Record<string, string>>((acc, alpha2) => {
          const translationControl = traitFormGroup.get(alpha2);
          if(translationControl && translationControl.value !== null && translationControl.value !== '') acc[alpha2] = traitFormGroup.get(alpha2)?.value;
          return acc;
        }, {});

        acc.push(translations);
        return acc;
      }, []);

      const instructionsFormGroup = deploymentFormGroup.get('instructions') as FormGroup<{[name: string]: FormControl}>;

      const instructions = Object.entries(instructionsFormGroup.controls).reduce<Record<string, any>>((acc, [key, formControl]) => {
        acc[key] = formControl.value;
        return acc;
      }, {});

      const documentationsFormArray = deploymentFormGroup.get('documentations') as FormArray<FormGroup<{name: FormControl<string>, address: FormControl<string>}>>;

      const documentations = documentationsFormArray.controls.map((formGroup) => ({
        name: formGroup.controls.name.value,
        address: formGroup.controls.address.value,
      }));

      return {
        name: names,
        isDefault: deploymentFormGroup.get('default')?.value,
        scarmId: deploymentFormGroup.get('integration')?.value,
        typeId: deploymentFormGroup.get('type')?.value,
        traits: traits,
        url: deploymentFormGroup.get('url')?.value,
        instructions,
        documentations,
      };

    });
  }

  public async submit() {
    try {
      this.isLoading = true;
      if(this.formGroup.invalid) {
        this.messageService.add(FormErrorMessage);
        return;
      }

      const names = Object.keys(this.nameFormGroup.controls).reduce<Record<string, string>>((acc, alpha2) => {
        const translation = this.nameFormGroup.get(alpha2);
        if(!translation?.value) return acc;
        acc[alpha2] = translation.value;
        return acc;
      }, {});

      const summaries = Object.keys(this.summaryFormGroup.controls).reduce<Record<string, string>>((acc, alpha2) => {
        const translation = this.summaryFormGroup.get(alpha2);
        if(!translation?.value) return acc;
        acc[alpha2] = translation.value;
        return acc;
      }, {});

      const descriptions = Object.keys(this.descriptionFormGroup.controls).reduce<Record<string, string>>((acc, alpha2) => {
        const translation = this.descriptionFormGroup.get(alpha2);
        if(!translation?.value) return acc;
        acc[alpha2] = translation.value;
        return acc;
      }, {});

      const licenseFormArray  = this.licenseFormArray as FormArray<FormGroup<{licenseName: FormControl<string>, licenseUrl: FormControl<string>}>>
      const licenses = licenseFormArray.controls
        .filter(formGroup => (formGroup.controls.licenseName.value))
        .map((formGroup) => ({
        licenseName: formGroup.controls.licenseName.value,
        licenseUrl: formGroup.controls.licenseUrl.value,
      }));

      const productVersion = this.productVersionControl.value;

      const logo = this.uploadedLogoControl.value;

      const screenshots = this.uploadedScreenshotsControl.controls.map((screenshotControl) => screenshotControl.value);

      const deployments = this.parseDeploymentForRequest();

      const navLinks = this.navLinksFormArray.controls.map((control) => ({
        name: control.get('name')?.value,
        href: control.get('href')?.value,
      }));

      const payload = {
        name: names,
        summary: summaries,
        description: descriptions,
        logo,
        screenshots,
        tagIds: this.targetProducts.map((tag) => (tag.id)),
        deployments,
        componentId: this.componentControl.value,
        licenses: licenses,
        navLinks,
        productVersion: productVersion,
      };

      const response = await this.productsManager.createProduct(payload);

      if(response) this.router.navigate(['/products']);
    } catch (err) {
      this.messageService.add(ServerErrorMessage);
    } finally {
      this.isLoading = false;
    }
  }

  public onLogoUpload(ev: Event) {
    this.uploadedLogoControl.markAsTouched();
    const event = ev as (Event & {target: HTMLInputElement & { files: FileList | null}})

    if(!event?.target?.files) return;

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (ev) => {
      this.uploadedLogoControl.setValue(ev.target?.result!);
      this.uploadedLogoControl.markAsDirty();
    }

    event.target.value = '';
  }

  public onScreenshotsUpload(ev: Event) {
    const event = ev as (Event & {target: HTMLInputElement & { files: FileList | null}})
    if(!event?.target?.files) return;

    for(let file of event.target.files) {

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (ev) => {
        const formControl = new FormControl(ev.target?.result!)
        this.uploadedScreenshotsControl.push(formControl);
      }
    }

    event.target.value = '';
  }

  public removeScreenshot(index: number) {
    this.uploadedScreenshotsControl.removeAt(index);
  }

  public getIntegrationFormControl() {
    return this.getCurrentDeployment().get('integration') as FormControl;
  }

}
