import { Component, OnInit } from '@angular/core';
import { StatisticsService } from '../statistics.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {

  public productsAmount: number = 0;
  public deploymentTypesAmount: number = 0;
  public productTagsAmount: number = 0;
  public translationsAmount: number = 0;
  public languagesAmount: number = 0;
  public subscriptionsAmount: number = 0;
  public usersAmount: number = 0;

  constructor(private statistics: StatisticsService) {}

  public async ngOnInit() {
    const {
      deploymentsTypes,
      languages,
      productTags,
      products,
      subscriptions,
      translations,
      users,
    } = await this.statistics.getStatistics();

    this.productsAmount = products;
    this.deploymentTypesAmount = deploymentsTypes;
    this.productTagsAmount = productTags;
    this.translationsAmount = translations;
    this.languagesAmount = languages;
    this.subscriptionsAmount = subscriptions;
    this.usersAmount = users;
  }
}
