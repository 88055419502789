<div class="flex flex-column h-full max-h-full flex-auto">
    <p-table styleClass="white-header" [value]="translations" [loading]="isLoading" ngClass="h-full max-h-full" [scrollable]="true" scrollHeight="flex" [paginator]="true" [rows]="itemsPerPage" [totalRecords]="totalRecords" [lazy]="true" (onPage)="onPageChange($event)" dataKey="id">
        <ng-template pTemplate="caption">
            <div class="flex flex-row justify-content-between">
                <div class="flex align-items-center">
                    <p class="m-0 font-bold text-2xl">
                        {{ 'ADMIN.TRANSLATIONS.HEADER.TITLE' | translate }} {{ totalRecords }}
                    </p>
                </div>
                <div class="flex flex-row">
                    <button pButton class="outline-none" (click)="refreshList()">
                        <i class="pi pi-refresh"></i>
                    </button>
                    <a [routerLink]="['add']"
                        class="ml-2 no-underline flex flex-row gap-2 align-items-center border-1 border-primary border-round-md bg-primary" style="padding: 12px 20px;">
                        <i class="pi pi-plus"></i>
                        <span>{{ 'ADMIN.TRANSLATIONS.ADD_BUTTON' | translate }}</span>
                    </a>
                    <button title="Wyczyść" type="button" pButton label="Wyczyść" class="p-button-outlined ml-2"
                        icon="pi pi-filter-slash"></button>
                    <span class="p-input-icon-left ml-2">
                        <i class="pi pi-search"></i>
                        <input class="input-bg-grey" pInputText type="text" placeholder="Wyszukuj tagów" [(ngModel)]="fullTextSearch" (input)="onSearchTextChange()" />
                    </span>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="200" class="text-center">
              Nie znaleziono tłumaczeń
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>
                    {{ 'ADMIN.TRANSLATIONS.TABLE.HEADER.NO' | translate }}
                </th>
                <th>
                    {{ 'ADMIN.TRANSLATIONS.TABLE.HEADER.ID' | translate }}
                </th>
                <th>
                    {{ 'ADMIN.TRANSLATIONS.TABLE.HEADER.TRANSLATIONS' | translate }}
                </th>
                <th>
                    {{ 'ADMIN.TRANSLATIONS.TABLE.HEADER.TRANSLATIONS_AMOUNT' | translate }}
                </th>
                <th>
                    {{ 'ADMIN.TRANSLATIONS.TABLE.HEADER.ACTIONS' | translate }}
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-translation let-rowIndex="rowIndex">
            <tr>
                <td>
                    {{ rowIndex + 1 }}
                </td>
                <td>
                    {{ translation.id }}
                </td>
                <td>
                    <span *ngFor="let translation of translation.translations; let last = last;" [pTooltip]="translation.alpha2" tooltipPosition="bottom">
                        {{ translation.value }}
                        <span *ngIf="!last">/</span>
                    </span>
                </td>
                <td>
                    {{ translation.translations.length }}
                </td>
                <td class="flex flex-row flex-shrink">
                    <a [routerLink]="[translation.id]" pRipple class="w-3rem flex flex-column justify-content-center align-items-center no-underline icon-button">
                        <i class="pi pi-eye"></i>
                    </a>
                    <p-button icon="pi pi-trash" [rounded]="true" [text]="true" severity="danger"
                        (onClick)="deleteTranslation(translation.id)"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
