import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditUserPageComponent } from './edit-user-page/edit-user-page.component';
import { UserListPageComponent } from './user-list-page/user-list-page.component';
import { AddUserPageComponent } from './add-user-page/add-user-page.component';
import { BrowserModule } from '@angular/platform-browser';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { RouterModule } from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';


@NgModule({
  declarations: [
    EditUserPageComponent,
    UserListPageComponent,
    AddUserPageComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    TabMenuModule,
    ButtonModule,
    PickListModule,
    TableModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    InputSwitchModule,
    PasswordModule,
    RadioButtonModule,
  ],
})
export class UserPageModule { }
