import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { LanguagesManager } from 'src/app/languages-manager.service';
import { ServerErrorMessage } from 'src/app/messages';
import { PostsManager } from 'src/app/posts-manager.service';

@Component({
  selector: 'app-add-post-page',
  templateUrl: './add-post-page.component.html',
  styleUrls: ['./add-post-page.component.scss']
})
export class AddPostPageComponent implements OnInit {
  public tabs: MenuItem[] | undefined;

  public activeTab: MenuItem | undefined;

  public sourcePostTags: any[] = [];
  public targetPostTags: any[] = [];

  public formGroup!: FormGroup;
  public mainImageFormControl!: FormControl;

  public isLoading: boolean = true;
  public isSaving: boolean = false;

  constructor(
    private langaugeManager: LanguagesManager,
    private messageService: MessageService,
    private postsManager: PostsManager,
    private router: Router,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;

    this.formGroup = new FormGroup({
      'author': new FormControl('', [Validators.required]),
      'url': new FormControl(''),
    });

    const languages = await this.langaugeManager.getLanguages(1, 0);

    const tagsPromise = this.postsManager.getPostsTags().then(({data}) => {
      this.sourcePostTags = data;
    });

    const nameFormGroup = new FormGroup({}, [atLeastOne]);
    const contentFormGroup = new FormGroup({}, [atLeastOne]);
    const summaryFormGroup = new FormGroup({}, [atLeastOne]);
    const leadingsFormGroup = new FormGroup({}, [atLeastOne]);

    this.mainImageFormControl = new FormControl('', [
      Validators.required,
    ]);

    for (const language of languages) {
      nameFormGroup.addControl(language.alpha2, new FormControl(''));
      contentFormGroup.addControl(language.alpha2, new FormControl([]));
      summaryFormGroup.addControl(language.alpha2, new FormControl(undefined));
      leadingsFormGroup.addControl(language.alpha2, new FormControl());
    }

    this.formGroup.addControl('names', nameFormGroup);
    this.formGroup.addControl('contents', contentFormGroup);
    this.formGroup.addControl('summaries', summaryFormGroup);
    this.formGroup.addControl('leadings', leadingsFormGroup);

    this.tabs = [
      {
        label: 'Główny obrazek',
        icon: 'pi pi-fw pi-image',
        id: 'leadingImage',
      },
      {
        label: 'Tytuł',
        icon: 'pi pi-fw pi-language',
        id: 'name'
      },
      {
        label: 'Podsumowanie',
        icon: 'pi pi-fw pi-language',
        id: 'summary'
      },
      {
        label: 'Zajawka',
        icon: 'pi pi-fw pi-language',
        id: 'leading'
      },
      {
        label: 'Treść',
        icon: 'pi pi-fw pi-language',
        id: 'content'
      },
      {
        label: 'Autor',
        icon: 'pi pi-fw pi-user',
        id: 'author'
      },
      {
        label: 'Url',
        icon: 'pi pi-fw pi-link',
        id: 'url'
      },
      {
        label: 'Tagi',
        icon: 'pi pi-fw pi-hashtag',
        id: 'tags'
      },
    ];

    this.activeTab = this.tabs[0];

    await tagsPromise;

    this.isLoading = false;
  }

  public async submit() {
    try {
      this.isSaving = true;

      const nameFormGroup = this.formGroup.get('names') as FormGroup;
      const summaryFormGroup = this.formGroup.get('summaries') as FormGroup;
      const leadingFormGroup = this.formGroup.get('leadings') as FormGroup;
      const authorFormControl = this.formGroup.get('author') as FormControl;
      const urlFormControl = this.formGroup.get('url') as FormControl;
      const contentFormGroup = this.formGroup.get('contents') as FormGroup;

      const data = {
        name: Object.entries(nameFormGroup.controls).reduce<Record<string, string>>((acc, [key, formControl]) => {
          if(formControl.value) acc[key] = formControl.value;
          return acc;
        }, {}),
        summary: Object.entries(summaryFormGroup.controls).reduce<Record<string, string>>((acc, [key, formControl]) => {
          if(formControl.value) acc[key] = formControl.value;
          return acc;
        }, {}),
        leading: Object.entries(leadingFormGroup.controls).reduce<Record<string, string>>((acc, [key, formControl]) => {
          if(formControl.value) acc[key] = formControl.value;
          return acc;
        }, {}),
        author: authorFormControl.value,
        url: urlFormControl.value,
        image: this.mainImageFormControl.value,
        content: Object.entries(contentFormGroup.controls).reduce<Record<string, object>>((acc, [key, formControl]) => {
          if(formControl.value?.ops?.length > 0) acc[key] = formControl.value;
          return acc;
        }, {}),
        tagIds: this.targetPostTags.map((tag) => tag.id),
      };
      const response = await this.postsManager.addPost(data);
      if(response) this.router.navigate(['/posts']);
    } catch (err) {
      console.error(err);
      this.messageService.add(ServerErrorMessage);
    } finally {
      this.isSaving = false;
    }
  }

  public onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  public getFormControlsByName(name: string) {
    return Object.keys((this.formGroup.get(name) as FormGroup).controls);
  }

  public onMainImageUpload(ev: Event) {
    this.mainImageFormControl.markAsTouched();
    const event = ev as (Event & {target: HTMLInputElement & { files: FileList | null}})

    if(!event?.target?.files) return;

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (ev) => {
      this.mainImageFormControl.setValue(ev.target?.result!);
      this.mainImageFormControl.markAsDirty();
    }

    event.target.value = '';
  }
}
