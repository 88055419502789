import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionManager {

  constructor(
    private apiClient: ApiClientService,
  ) {}

  public createSubscription(options: {
    name: Record<string, string>;
    SKU: string;
    terms: string[];
  }) {
    return this.apiClient.post('/admin/subscriptions', options);
  }

  public getSubscription(subscriptionId: string) {
    return <any> this.apiClient.get(`/admin/subscriptions/${subscriptionId}`);
  }

  public getSubscriptions(page: number, itemsPerPage: number) {
    const params = new URLSearchParams();
    params.set('page', page.toString());
    params.set('itemsPerPage', itemsPerPage.toString());
    return <any> this.apiClient.get('/admin/subscriptions', params);
  }

  public deleteSubscriptions(subscriptionIds: string[]) {
    const params = new URLSearchParams();
    params.set('ids', subscriptionIds.reduce((acc, value, index) => {
      acc = acc + value;
      if(subscriptionIds.length !== index) acc += ', ';
      return acc;
    }, ''))
    return this.apiClient.delete('/admin/subscriptions', params);
  }

  public updateSubscription(subscriptionId: string, options: {
    name: Record<string, string>;
    SKU: string;
    terms: string[];
  }) {
    return this.apiClient.put(`/admin/subscriptions/${subscriptionId}`, options);
  }
}
