import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { LanguagesManager } from 'src/app/languages-manager.service';
import { ServerErrorMessage } from 'src/app/messages';
import { PostsManager } from 'src/app/posts-manager.service';

@Component({
  selector: 'app-add-posts-tag-page',
  templateUrl: './add-posts-tag-page.component.html',
  styleUrls: ['./add-posts-tag-page.component.scss']
})
export class AddPostsTagPageComponent implements OnInit {
  public tabs!: MenuItem[];

  public activeTab!: MenuItem;

  public formGroup!: FormGroup;
  public nameFormGroup!: FormGroup;

  public isLoading: boolean = true;
  public isSaving: boolean = false;

  public imageFormControl!: FormControl;

  constructor(
    private langaugeManager: LanguagesManager,
    private messageService: MessageService,
    private postsManager: PostsManager,
    private router: Router,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;

    this.imageFormControl = new FormControl('');

    this.formGroup = new FormGroup({
      'author': new FormControl(''),
      'image': this.imageFormControl,
    });

    const languages = await this.langaugeManager.getLanguages(1, 0);

    const nameFormGroup = this.nameFormGroup = new FormGroup({}, [atLeastOne]);

    for (const language of languages) {
      nameFormGroup.addControl(language.alpha2, new FormControl(''));
    }

    this.formGroup.addControl('names', nameFormGroup);

    this.tabs = [
      {
        label: 'Nazwa',
        icon: 'pi pi-fw pi-language',
        id: 'name'
      },
      {
        label: 'Logo',
        icon: 'pi pi-fw pi-image',
        id: 'logo'
      },
    ];

    this.activeTab = this.tabs[0];

    this.isLoading = false;
  }

  public async submit() {
    try {
      this.isSaving = true;
      const name: Record<string, string> = {};

      const namesFormGroup = this.formGroup.get('names') as FormGroup;

      for(const [key, formControl] of Object.entries(namesFormGroup.controls)) {
        if(formControl.value && formControl.value !== '') name[key] = formControl.value;
      }

      const logo = this.imageFormControl.value;

      const response = await this.postsManager.addPostTag({
        name,
        image: logo,
      });

      if (response) this.router.navigate(['/posts-tags']);
    } catch (err) {
      this.messageService.add(ServerErrorMessage);
    } finally {
      this.isSaving = false;
    }
  }

  public onImageUpload(ev: Event) {
    this.imageFormControl.markAsTouched();
    const event = ev as (Event & {target: HTMLInputElement & { files: FileList | null}})

    if(!event?.target?.files) return;

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (ev) => {
      this.imageFormControl.setValue(ev.target?.result!);
      this.imageFormControl.markAsDirty();
    }

    event.target.value = '';
  }

  public onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  public getFormControlsByName(name: string) {
    return Object.keys((this.formGroup.get(name) as FormGroup).controls);
  }
}

