import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { DeploymentTypesManager } from 'src/app/deployment-types-manager.service';
import { LanguagesManager } from 'src/app/languages-manager.service';

@Component({
  selector: 'app-edit-deployment-type-page',
  templateUrl: './edit-deployment-type-page.component.html',
  styleUrls: ['./edit-deployment-type-page.component.scss']
})
export class EditDeploymentTypePageComponent {
  public tabs: MenuItem[] | undefined;

  public activeTab: MenuItem | undefined;

  public formGroup!: FormGroup;
  public nameFormGroup!: FormGroup;

  public isLoading: boolean = true;

  public deploymentTypeId: string = '';

  constructor(
    private langaugeManager: LanguagesManager,
    private deploymentTypesManager: DeploymentTypesManager,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;

    this.tabs = [
      { label: 'Nazwa', icon: 'pi pi-fw pi-language', id: 'name' },
      { label: 'Typ', icon: 'pi pi-fw pi-th-large', id: 'type' },
    ];

    this.activeTab = this.tabs[0];

    const { deploymentTypeId } = await firstValueFrom(this.route.params);

    this.deploymentTypeId = deploymentTypeId;

    const {environmentType, name} = await this.deploymentTypesManager.getDeploymentType(deploymentTypeId);

    this.formGroup = new FormGroup({
      type: new FormControl(environmentType, [
        Validators.required,
      ]),
    });

    const languages = await this.langaugeManager.getLanguages(1, 0);

    this.nameFormGroup = new FormGroup({}, [
      atLeastOne,
    ]);

    for (let lang of languages) {
      const values = await firstValueFrom(this.translate.getTranslation(lang.alpha2));
      this.nameFormGroup.addControl(lang.alpha2, new FormControl(values[name]));
    }

    this.formGroup.addControl('names', this.nameFormGroup);

    this.isLoading = false;
  }

  onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  public getEntriesOfNames() {
    return Object.keys(this.nameFormGroup.controls);
  }

  public async submit() {
    try {
      this.isLoading = true;
      if(this.formGroup.invalid) {
        this.messageService.add({
          severity: 'error',
          summary: 'Błąd',
          detail: 'Nie wszystkie elementy zostały uzupełnione',
        });
        return;
      }

      const names = Object.keys(this.nameFormGroup.controls).reduce<Record<string, string>>((acc, controlName) => {
        const formControl = this.nameFormGroup.controls[controlName];
        if(formControl.dirty) acc[controlName] = formControl.value;
        return acc;
      }, {});

      const options = {
        type: this.formGroup.get('type')?.value,
        names,
      };

      console.log(options);

      const response = await this.deploymentTypesManager.createDeploymentType(options);

      this.router.navigate(['/deployment-types']);
      this.isLoading = false;
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Błąd',
        detail: 'Po stronie serwera pojawiły się problemy. Spróbuj ponownie później.',
      })
    }
  }
}
