import { Component } from '@angular/core';

@Component({
  selector: 'app-remind-password-page',
  templateUrl: './remind-password-page.component.html',
  styleUrls: ['./remind-password-page.component.scss']
})
export class RemindPasswordPageComponent {

}
