import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private apiClient: ApiClientService,
    private sessionService: SessionService,
  ) {
    this.checkIsLoggedIn();
  }

  public async checkIsLoggedIn() {
    try {
      const response = await this.apiClient.get<{ id: string; firstName: string | null; lastName: string | null; email: string; organizationName: string | null}>('/sessions');
      this.sessionService.publishNewState(true);
      return true;
    } catch (err) {
      console.error(err);
      this.sessionService.publishNewState(false);
      return false;
    }
  }

  public async login(username: string, password: string) {
    try {
      const response = await this.apiClient.post('/sessions', {
        email: username,
        password,
      });
      this.sessionService.publishNewState(true);
      return true;
    } catch (error) {
      return false;
    }
  }

  public async logout() {
    const response = await this.apiClient.delete('/sessions');
    this.sessionService.publishNewState(false);
    return response;
  }
}
