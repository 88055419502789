import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class DeploymentTypesManager {
  constructor(private apiClient: ApiClientService) {}

  public getDeploymentTypes(
    page: number = 1,
    itemsPerPage: number = 25,
  ) {
    const searchParams = new URLSearchParams();

    searchParams.set('page', page.toString());
    searchParams.set('itemsPerPage', itemsPerPage.toString());

    return this.apiClient.get<{
      data: any[],
      totalNumber: number,
    }>('/admin/deployment-types', searchParams);
  }

  public getDeploymentType(deploymentTypeId: string) {
    return this.apiClient.get<{environmentType: string; id: string; name: string;}>(`/admin/deployment-types/${deploymentTypeId}`);
  }

  public updateDeploymentType(deploymentTypeId: string, options: {
    name: Record<string, string>;
    typeId: string;
  }) {
    return this.apiClient.put(`/admin/deployment-types/${deploymentTypeId}`, options);
  }

  public createDeploymentType(options: {
    names: Record<string, string>,
    type: 'ON_PREM' | 'CONTAINERS' | 'CLOUD',
  }) {
    return this.apiClient.post('/admin/deployment-types', options);
  }

  public deleteDeploymentType(id: string) {
    return this.apiClient.delete(`/admin/deployment-types/${id}`);
  }
}
