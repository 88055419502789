import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";

export const isEmail = (options?: {
  onlyDirty: boolean,
}): ValidatorFn => {
  const regex = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
  return (target: AbstractControl) => {
    if(options?.onlyDirty && target.pristine) return null;
    return regex.test(target.value) ? null : { isEmail: true };
  }
}