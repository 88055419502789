import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TablePageEvent } from 'primeng/table';
import { ProductTagManager } from 'src/app/product-tag-manager.service';

@Component({
  selector: 'app-product-tag-list-page',
  templateUrl: './product-tag-list-page.component.html',
  styleUrls: ['./product-tag-list-page.component.scss']
})
export class ProductTagListPageComponent implements OnInit {

  public page: number = 1;
  public itemsPerPage: number = 25;
  public totalRecords: number = 0;

  public productTags: any[] = [];

  public isLoading: boolean = true;

  constructor(
    private productTagManager: ProductTagManager,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  public ngOnInit(): void {
    this.refreshList();
  }

  public async refreshList() {
    this.isLoading = true;

    const { data, totalAmount } = await this.productTagManager.getProductTags(this.page, this.itemsPerPage);

    this.productTags = data;
    this.totalRecords = totalAmount;

    this.isLoading = false;
  }

  public onPageChange(event: TablePageEvent) {
    this.page = event.first/this.itemsPerPage + 1;
    this.refreshList();
  }

  public deleteTag(tagId: string) {
    this.confirmationService.confirm({
      message: 'Czy chcesz usunąć tag produktu?',
      header: 'Potwierdzenie usunięcia',
      icon: 'pi pi-info-circle',
      accept: async () => {
        try {
          await this.productTagManager.deleteProductTag(tagId);
          this.messageService.add({
            severity: 'success',
            summary: 'Sukces',
            detail: 'Udało się zrealizować żądanie usunięcia zasobu',
          });
          await this.refreshList()
        } catch (err) {
          this.messageService.add({
            severity: 'error',
            summary: 'Błąd',
            detail: 'Błąd serwera. Spróbuj ponownie później',
          });
        }
      },
      acceptLabel: 'Tak',
      rejectLabel: 'Nie',
    });
    
  }
}
