<div class="flex flex-column h-full flex-auto">
    <p-table styleClass="white-header" [value]="productTags" [loading]="isLoading" ngClass="h-full" [scrollable]="true" scrollHeight="flex" [paginator]="true" [rows]="itemsPerPage" [totalRecords]="totalRecords" [lazy]="true" (onPage)="onPageChange($event)" dataKey="id">
        <ng-template pTemplate="caption">
            <div class="flex flex-row justify-content-between">
              <div class="flex align-items-center">
                <p class="m-0 font-bold text-2xl">Tagi produktów</p>
              </div>
              <div class="flex flex-row">
                <a [routerLink]="['add']" class="no-underline flex flex-row gap-2 align-items-center border-1 border-round-md py-2 px-3 bg-primary">
                  <i class="pi pi-plus"></i>
                  <span>Dodaj</span>
                </a>
                <button title="Wyczyść" type="button" pButton label="Wyczyść" class="p-button-outlined ml-2" icon="pi pi-filter-slash"></button>
                <span class="p-input-icon-left ml-2">
                    <i class="pi pi-search"></i>
                    <input class="input-bg-grey" pInputText type="text" placeholder="Wyszukuj tagów" />
                </span>
              </div>
            </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="200" class="text-center">
              Nie znaleziono tagów
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>
                    lp.
                </th>
                <th>
                    ID
                </th>
                <th>
                    Nazwa
                </th>
                <th>
                    Ilość produktów
                </th>
                <th>
                    Opcje
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-tag let-rowIndex="rowIndex">
            <tr>
                <td>
                    {{ rowIndex + 1 }}
                </td>
                <td>
                    {{ tag.id }}
                </td>
                <td>
                    {{ tag.name | translate }}
                </td>
                <td>
                    {{ tag.count }}
                </td>
                <td class="flex flex-row flex-shrink">
                    <a [routerLink]="[tag.id]" pRipple class="w-3rem flex flex-column justify-content-center align-items-center no-underline icon-button">
                        <i class="pi pi-eye"></i>
                    </a>
                    <p-button icon="pi pi-trash" [rounded]="true" [text]="true" severity="danger"
                        (onClick)="deleteTag(tag.id)"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
