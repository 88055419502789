import { Message } from "primeng/api";

export const ServerErrorMessage: Message = {
  severity: 'error',
  summary: 'Błąd zapisu',
  detail: 'Serwer nie mógł przetworzyć zapytania.',
};

export const FormErrorMessage: Message = {
  severity: 'error',
  summary: 'Błąd zapisu',
  detail: 'Niepoprawne dane do zapisu',
};

export const LoginErrorMessage: Message = {
  severity: 'error',
  summary: 'Błąd logowania',
  detail: 'Niepoprawne dane logowania',
}