import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";

export const sameValues = (sameWith: string[], options?: { onlyDirty?: boolean}): ValidatorFn => {
  if(sameWith.length < 2) throw Error('Minimum two fields to check');
  const [sourceName, targetName] = sameWith;
  return (target: AbstractControl) => {
    const sourceControl = target.get(sourceName);
    const targetControl = target.get(targetName);

    if(options?.onlyDirty && target.pristine) return null;
    return sourceControl?.value === targetControl?.value ? null : { notSame: true };  
  }
}