<form class="flex flex-column" [formGroup]="formGroup" (ngSubmit)="submit()">
  <div class="flex flex-row justify-content-between align-items-center bg-white px-3 py-3 border-bottom-1 border-300">
    <div class="flex flex-row align-items-center">
      <a [routerLink]="'..'" class="hover:text-blue-400 transition-colors transition-duration-200">
        <i class="pi pi-arrow-left text-lg"></i>
      </a>
      <p class="m-0 ml-3 font-bold text-2xl">Dodaj tłumaczenie</p>
    </div>
    <div>
      <button pButton icon="pi pi-save" pRipple label="Zapisz" class="p-button-success" type="submit" [disabled]="this.formGroup.invalid" ></button>
    </div>
  </div>
  <div class="px-2 pt-2 w-full">
    <span class="p-input-icon-left w-full">
      <i class="pi pi-hashtag"></i>
      <input pInputText type="text" placeholder="Łańcuch identyfikacyjny" class="w-full" formControlName="id" />
    </span>
  </div>
  <ng-container formGroupName="translations">
    <div class="flex flex-row w-full justify-content-between align-items-center gap-3 px-2 pt-2" *ngFor="let alpha of getTranslationFields()">
      <div class="flex flex-column w-3rem align-items-center">
        <span class="font-semibold text-3xl">
          {{alpha}}
        </span>
      </div>
      <div class="flex flex-column flex-grow-1">
        <span class="p-input-icon-left w-full">
          <i class="pi pi-language"></i>
          <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [formControlName]="alpha" />
        </span>
      </div>
    </div>
  </ng-container>
</form>