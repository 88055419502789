import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditDeploymentTypePageComponent } from './edit-deployment-type-page/edit-deployment-type-page.component';
import { AddDeploymentTypePageComponent } from './add-deployment-type-page/add-deployment-type-page.component';
import { DeploymentTypesListPageComponent } from './deployment-types-list-page/deployment-types-list-page.component';
import { RouterModule } from '@angular/router';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    EditDeploymentTypePageComponent,
    AddDeploymentTypePageComponent,
    DeploymentTypesListPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TabMenuModule,
    TableModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    RadioButtonModule,
    TranslateModule,
  ]
})
export class DeploymentTypePageModule { }
