import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { LanguagesManager } from 'src/app/languages-manager.service';
import { firstValueFrom } from 'rxjs';
import { TranslationManager } from 'src/app/translation-manager.service';
import { MessageService } from 'primeng/api';
import { FormErrorMessage, ServerErrorMessage } from 'src/app/messages';

@Component({
  selector: 'app-edit-translation-page',
  templateUrl: './edit-translation-page.component.html',
  styleUrls: ['./edit-translation-page.component.scss']
})
export class EditTranslationPageComponent implements OnInit {

  public isLoading: boolean = true;
  public formGroup!: FormGroup;
  public textContentId: string = '';
  public oldTextContentId: string = '';

  constructor(
    private route: ActivatedRoute,
    private translationManager: TranslationManager,
    private messageService: MessageService,
    private router: Router,
  ) {}

  public async ngOnInit() {
    this.formGroup = new FormGroup({
      id: new FormControl('', [
        Validators.required,
      ]),
      translations: new FormGroup({}, [
        atLeastOne,
      ]),
    }, [atLeastOne]);
    const { textContentId } = await firstValueFrom(this.route.params)
    this.oldTextContentId = textContentId;

    const { id, translations } = await this.translationManager.getTranslation(this.oldTextContentId);

    this.textContentId = id;

    const group: FormGroup = this.formGroup.get('translations') as FormGroup;

    group.controls = {};

    this.formGroup.get('id')?.setValue(id);

    for(const translation of translations) {
      group.addControl(translation.alpha2, new FormControl(translation.value));
    }
  }

  public getTranslationFields() {
    const group = this.formGroup.get('translations')! as FormGroup;
    return Object.keys(group.controls);
  }

  public async submit() {
    try {
      this.isLoading = true;
      if(this.formGroup.invalid) {
        this.messageService.add(FormErrorMessage);
        return;
      }
  
      const group: FormGroup = this.formGroup.get('translations') as FormGroup;
  
      const alphaCodes = Object.keys(group.controls);
  
      const translations: Record<string, string> = {};
  
      for(const alpha of alphaCodes) {
        translations[alpha] = group.get(alpha)?.value;
      }
  
      const response = await this.translationManager.updateTranslation(this.oldTextContentId, { translations, id: this.formGroup.get('id')?.value });
  
      if(response) await this.router.navigate(['/translations']);
      return;
    } catch(err) {
      this.messageService.add(ServerErrorMessage);
    } finally {
      this.isLoading = false;
    }
  }
}
