import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ProductToAdd } from '../product-to-add';
import { FormControl, FormGroup } from '@angular/forms';
import { LanguagesManager } from 'src/app/languages-manager.service';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { ProductTagManager } from 'src/app/product-tag-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-product-tag-page',
  templateUrl: './add-product-tag-page.component.html',
  styleUrls: ['./add-product-tag-page.component.scss']
})
export class AddProductTagPageComponent implements OnInit {
  tabs: MenuItem[] | undefined;

  activeTab: MenuItem | undefined;

  sourceProducts: ProductToAdd[] = [];
  targetProducts: ProductToAdd[] = [];

  public formGroup!: FormGroup;
  public nameFormGroup!: FormGroup;

  public isLoading: boolean = true;

  constructor(
    private langaugeManager: LanguagesManager,
    private productTagManager: ProductTagManager,
    private messageService: MessageService,
    private router: Router,
  ) {}

  public async ngOnInit() {

    this.formGroup = new FormGroup({});

    this.tabs = [
        { label: 'Nazwa', icon: 'pi pi-fw pi-language', id: 'name' },
        { label: 'Produkty', icon: 'pi pi-fw pi-table', id: 'products' },
    ];

    this.activeTab = this.tabs[0];

    this.isLoading = true;

    const languages = await this.langaugeManager.getLanguages(1, 0);

    this.nameFormGroup = new FormGroup({}, [
      atLeastOne,
    ]);

    for(let lang of languages) {
      this.nameFormGroup.addControl(lang.alpha2, new FormControl(''));
    }

    this.formGroup.addControl('names', this.nameFormGroup);

    this.isLoading = false;
  }

  onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  public getEntriesOfNames() {
    return Object.keys(this.nameFormGroup.controls);
  }

  public async submit() {
    if(this.formGroup.invalid) return;

    try {
      this.isLoading = true;

      const translations = Object.keys(this.nameFormGroup.controls).reduce<Record<string,string>>((acc, key) => {
        const formControl = this.nameFormGroup.get(key);
        const value = formControl?.value;
        if(value === null || value === '' || typeof value === 'undefined' || formControl?.pristine) return acc;
        acc[key] = value;
        return acc;
      }, {});

      const options = {
        names: translations,
        products: [],
      };
  
      const response = await this.productTagManager.createProductTag(options);

      if(response) this.router.navigate(['/product-tags']);
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Błąd zapisu',
        detail: 'Serwer nie mógł przetworzyć zapytania',
      });
    } finally {
      this.isLoading = false;
    }

    

    
  }
}
