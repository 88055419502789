import { Component, OnInit } from '@angular/core';
import { TablePageEvent } from 'primeng/table';
import { ProductsManager } from 'src/app/products-manager.service';

@Component({
  selector: 'app-product-list-page',
  templateUrl: './product-list-page.component.html',
  styleUrls: ['./product-list-page.component.scss']
})
export class ProductListPageComponent implements OnInit {
  public isLoading: boolean = false;

  public page: number = 1;
  public itemsPerPage: number = 25;
  public totalRecords: number = 0;

  public products: any[] = [];

  constructor(
    private productsManager: ProductsManager,
  ) {}

  public ngOnInit(): void {
    this.refreshList();
  }

  public async refreshList() {
    this.isLoading = true;

    const {totalAmount, data} = await this.productsManager.getProducts(this.page, this.itemsPerPage);

    this.products = data;
    this.totalRecords = totalAmount;

    this.isLoading = false;
  }

  public async deleteProduct(id: string) {
    await this.productsManager.deleteProduct(id);
    await this.refreshList();
  }

  public async deleteProducts(ids: string[]) {
    await this.productsManager.deleteProducts(ids);
    await this.refreshList();
  }

  public pageChange(event: TablePageEvent) {
    this.page = event.first/this.itemsPerPage + 1;
    this.refreshList();
  }
}
