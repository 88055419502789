<div class="flex flex-column w-full h-full justify-content-center align-items-center surface-200" *ngIf="isLoading">
  <span class="font-bold text-4xl">
    Ładowanie danych
  </span>
</div>

<div class="flex flex-column w-full h-full justify-content-center align-items-center surface-200" *ngIf="isSaving">
  <span class="font-bold text-4xl">
    Trwa zapisywanie danych
  </span>
</div>

<form class="flex flex-column min-w-full" [formGroup]="formGroup" (ngSubmit)="submit()" *ngIf="!isLoading && !isSaving">
  <div class="flex flex-row align-items-center justify-content-between px-3 pt-3 bg-white pb-2">
    <div class="flex flex-row align-items-center">
      <a [routerLink]="'..'" class="hover:text-blue-400 transition-colors transition-duration-200">
        <i class="pi pi-arrow-left text-lg"></i>
      </a>
      <p class="m-0 ml-3 font-bold text-2xl">Edytujesz post: {{ postId }}</p>
    </div>
    <div>
      <button pButton pRipple title="Zapisz" label="Zapisz" class="p-button-success" type="submit" [disabled]="formGroup.invalid"></button>
    </div>
  </div>
  <div class="w-full bg-white">
    <p-tabMenu [model]="tabs" [activeItem]="activeTab" (activeItemChange)="onTabChange($event)"></p-tabMenu>
  </div>

  <!-- Main image section -->
  <div *ngIf="activeTab && activeTab.id == 'leadingImage'" class="mt-3 flex flex-column w-full gap-3 px-3">
    <div class="flex flex-row w-3">
      <input id="screenshotUpload" type="file" class="file-upload" (change)="onMainImageUpload($event)" accept="image/*" class="hidden">
      <label for="screenshotUpload" class="cursor-pointer border-3 border-primary button border-round-lg font-semibold">Wybierz zdjęcie</label>
    </div>
    <div class="flex flex-row w-6">
      <p-image *ngIf="mainImageFormControl.value" [src]="mainImageFormControl.value" [preview]="true" alt="logo_preview" imageClass="w-full">
        <ng-template pTemplate="indicator">
          <i class="pi pi-eye"></i>
        </ng-template>
      </p-image>
    </div>
  </div>

  <!-- Name section -->
  <div *ngIf="activeTab && activeTab.id == 'name'" class="mt-3 flex flex-column w-full gap-3 px-3" formGroupName="names">
    <!-- <small class="text-red-500" *ngIf="nameFormGroup.invalid">Przynajmniej jedno musi zostać uzupełnione.</small> -->
    <div class="flex flex-row w-full justify-content-between align-items-center gap-3" *ngFor="let language of getFormControlsByName('names')">
      <div class="flex flex-column w-3rem">
        <span class="font-semibold text-3xl">
          {{ language }}
        </span>
      </div>
      <div class="flex flex-column flex-grow-1">
        <span class="p-input-icon-left ml-2 w-full">
          <i class="pi pi-language"></i>
          <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('name_' + language) | lowercase" [formControlName]="language" />
        </span>
      </div>
    </div>
  </div>


  <!-- Summary section -->
  <div *ngIf="activeTab && activeTab.id == 'summary'" class="mt-3 flex flex-column w-full gap-3 px-3" formGroupName="summaries">
    <!-- <small class="text-red-500" *ngIf="nameFormGroup.invalid">Przynajmniej jedno musi zostać uzupełnione.</small> -->
    <div class="flex flex-row w-full justify-content-between align-items-center gap-3" *ngFor="let language of getFormControlsByName('summaries')">
      <div class="flex flex-column w-3rem">
        <span class="font-semibold text-3xl">
          {{ language }}
        </span>
      </div>
      <div class="flex flex-column flex-grow-1">
        <span class="p-input-icon-left ml-2 w-full">
          <i class="pi pi-language"></i>
          <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('name_' + language) | lowercase" [formControlName]="language" />
        </span>
      </div>
    </div>
  </div>

  <!-- Leading section -->
  <div *ngIf="activeTab && activeTab.id == 'leading'" class="mt-3 flex flex-column w-full gap-3 px-3" formGroupName="leadings">
    <!-- <small class="text-red-500" *ngIf="nameFormGroup.invalid">Przynajmniej jedno musi zostać uzupełnione.</small> -->
    <div class="flex flex-row w-full justify-content-between align-items-center gap-3" *ngFor="let language of getFormControlsByName('leadings')">
      <div class="flex flex-column w-3rem">
        <span class="font-semibold text-3xl">
          {{ language }}
        </span>
      </div>
      <div class="flex flex-column flex-grow-1">
        <span class="p-input-icon-left ml-2 w-full">
          <i class="pi pi-language"></i>
          <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('leading_' + language) | lowercase" [formControlName]="language" />
        </span>
      </div>
    </div>
  </div>

  <!-- Content section -->
  <div *ngIf="activeTab && activeTab.id == 'content'" class="flex flex-column w-full flex-grow-1" formGroupName="contents">
    <div class="relative flex flex-row w-full justify-content-between align-items-start gap-3 flex-grow-1 flex-shrink-0 border-gray-300" style="flex-basis: 50%; height: 50%;" *ngFor="let language of getFormControlsByName('contents'); let index = index; let count = count" [ngClass]="{'border-top-1': index > 0}" [style]="{'z-index': count - index}">
      <editor class="flex flex-grow-1" style="height: 100%;" [formControlName]="language"></editor>
      <div class="absolute flex flex-column w-3rem" style="right: 10px; bottom: 10px; pointer-events: none;">
        <span class="font-semibold text-3xl text-black-alpha-30">
          {{ language }}
        </span>
      </div>
    </div>
  </div>

  <!-- Author section -->
  <div *ngIf="activeTab && activeTab.id == 'author'" class="mt-3 flex flex-column w-full gap-3 px-3">
    <div class="flex flex-row justify-content-between align-items-center gap-3 w-6">
      <div class="flex flex-column flex-grow-1">
        <span class="p-input-icon-left ml-2 w-full">
          <i class="pi pi-user"></i>
          <input pInputText type="text" placeholder="Dane autora" class="w-full" id="author" formControlName="author" />
        </span>
      </div>
    </div>
  </div>

  <!-- Url section -->
  <div *ngIf="activeTab && activeTab.id == 'url'" class="mt-3 flex flex-column w-full gap-3 px-3">
    <div class="flex flex-row justify-content-between align-items-center gap-3 w-6">
      <div class="flex flex-column flex-grow-1">
        <span class="p-input-icon-left ml-2 w-full">
          <i class="pi pi-link"></i>
          <input pInputText type="text" placeholder="Url" class="w-full" id="url" formControlName="url" />
        </span>
      </div>
    </div>
  </div>

  <!-- Tags section -->
  <div *ngIf="activeTab && activeTab.id == 'tags'" class="mt-3 flex flex-row w-full justify-content-between align-items-center gap-3 px-3">
    <p-pickList [source]="sourcePostTags" [target]="targetPostTags" sourceHeader="Dostępne" targetHeader="Wybrane" [dragdrop]="true" [responsive]="true" [showSourceControls]="false" [showTargetControls]="false" [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }" breakpoint="1400px" class="w-full">
      <ng-template let-tag pTemplate="item">
        <div>
          {{ tag.name | translate }}
        </div>
      </ng-template>
    </p-pickList>
  </div>
</form>