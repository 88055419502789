<div class="flex flex-row w-full py-4 px-4 gap-4 flex-wrap">

  <div style="height: 200px; width: 200px;" class="border-round-lg border-1 flex flex-column flex-initial hover:shadow-2 transition-duration-200"
       [ngClass]="{'border-gray-600': (statusesLoading),
                   'border-red-600': (!statusesLoading && !frontendStatus),
                   'border-green-600': (!statusesLoading && frontendStatus)}">
      <div [ngClass]="{'border-gray-600 bg-gray-600' : (statusesLoading),
                       'border-red-600 bg-red-600': (!statusesLoading && !frontendStatus),
                       'border-green-600 bg-green-600': (!statusesLoading && frontendStatus)}"
           class="p-2 border-bottom-1 border-round-md border-noround-bottom flex flex-row justify-content-between align-items-center">
      <span class="font-semibold text-white">
        Frontend
      </span>
      <div>
        <i class="pi pi-spin pi-spinner text-white" *ngIf="statusesLoading"></i>
      </div>
    </div>
    <div class="p-2 flex flex-column align-items-center justify-content-center w-full h-full">
      <i class="pi text-7xl"
         [ngClass]="{'pi-times text-red-600': (!statusesLoading && !frontendStatus),
                     'pi-check text-green-600': (!statusesLoading && frontendStatus)}"></i>
    </div>
  </div>

  <div style="height: 200px; width: 200px;" class="border-round-lg border-1 flex flex-column flex-initial hover:shadow-2 transition-duration-200"
       [ngClass]="{'border-gray-600' : (statusesLoading),
                   'border-red-600': (!statusesLoading && !scarmStatus),
                   'border-green-600': (!statusesLoading && scarmStatus)}">
    <div [ngClass]="{'border-gray-600 bg-gray-600' : (statusesLoading),
                     'border-red-600 bg-red-600': (!statusesLoading && !scarmStatus),
                     'border-green-600 bg-green-600': (!statusesLoading && scarmStatus)}"
         class="p-2 border-bottom-1 border-round-md border-noround-bottom flex flex-row justify-content-between align-items-center">
      <span class="font-semibold text-white">
        REST API SCARM
      </span>
      <div>
        <i class="pi pi-spin pi-spinner text-white" *ngIf="statusesLoading"></i>
      </div>
    </div>
    <div class="p-2 flex flex-column align-items-center justify-content-center w-full h-full">
      <i class="pi text-7xl"
         [ngClass]="{'pi-times text-red-600': (!statusesLoading && !scarmStatus),
                     'pi-check text-green-600': (!statusesLoading && scarmStatus)}"></i>
    </div>
  </div>

</div>
