import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationManager {

  constructor(private apiClient: ApiClientService) {}

  public getTranslations(page: number, itemsPerPage: number, fullTextSearch?: string) {
    const params = new URLSearchParams();
    params.set('page', page.toString());
    params.set('itemsPerPage', itemsPerPage.toString());
    if(fullTextSearch) {
      params.set('searchText', fullTextSearch);
    }
    return this.apiClient.get<{data: {
      id: string;
      translations: {
        alpha2: string;
        value: string;
      }[]
    }[];
    totalAmount: number}>('/admin/translations', params);
  }

  public getTranslation(contentId: string) {
    return this.apiClient.get<{id: string; translations: {alpha2: string; value: string;}[]}>(`/admin/translations/${contentId}`);
  }

  public createTranslation(options: {
    id: string;
    translations: Record<string, string>;
  }) {
    return this.apiClient.post<{}>(`/admin/translations`, options);
  }

  public updateTranslation(translationId: string, options: {translations: Record<string, string>, id: string, }) {
    return this.apiClient.put<{}>(`/admin/translations/${translationId}`, options);
  }

  public deleteTranslation(translationId: string) {
    return this.apiClient.delete(`/admin/translations/${translationId}`);
  }
}
