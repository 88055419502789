import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Quill from 'quill';
import { Delta } from 'quill/core';
import ResizeModule from "@ssumo/quill-resize-module";
import hljs from 'highlight.js';

@Component({
  selector: 'editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorComponent),
      multi: true,
    }
  ]
})
export class EditorComponent implements OnInit, ControlValueAccessor {

  public editor!: Quill;

  @ViewChild('toolbar', {static: true})
  public toolbarElement!: ElementRef<HTMLElement>;

  @ViewChild('editorContainer', {static: true})
  public editorElement!: ElementRef<HTMLElement>;

  ngOnInit(): void {
    const Font = Quill.import('formats/font') as any;

    Font.whitelist = ['roboto', 'be-vietnam-pro'];

    Quill.register(Font, true);
    Quill.register("modules/resize", ResizeModule);

    this.editor = new Quill(this.editorElement.nativeElement, {
      formats: null,
      modules: {
        syntax: { hljs },
        toolbar: this.toolbarElement.nativeElement,
        resize: {
          locale: {
            altTip: 'Przytrzymaj alt aby zwiększyć proporcjonalnie',
            inputTip: 'Wprowadź tekst',
            floatLeft: 'Do lewej',
            floatRight: 'Do prawej',
            center: 'Wyśrodkuj',
            restore: 'Przywróć',
            delete: 'Usuń'
          }
        },
      },
    });

    this.editor.format('font', 'roboto');
  }

  public setTextColor(setColor: string) {
    const { color } = this.editor.getFormat() as { color: string };
    if(color?.toUpperCase() === setColor.toUpperCase()) {
      this.editor.format('color', false);
      return;
    }
    this.editor.format('color', setColor);
    return;
  }

  public setBackgroundColor(setColor: string) {
    const { background } = this.editor.getFormat() as { background: string };
    if(background?.toUpperCase() === setColor.toUpperCase()) {
      this.editor.format('background', false);
      return;
    }
    this.editor.format('background', setColor);
    return;
  }

  public setHeader(headerLevel: number) {
    const { header } = this.editor.getFormat();
    if(headerLevel === header) {
      return this.editor.format('header', false);
    }
    return this.editor.format('header', headerLevel);
  }

  public setListOrdered() {
    const { list } = this.editor.getFormat();
    if(list === 'ordered') {
      return this.editor.format('list', false);
    }
    return this.editor.format('list', 'ordered');
  }

  public setListBullets() {
    const { list } = this.editor.getFormat();
    if(list === 'bullet') {
      return this.editor.format('list', false);
    }
    return this.editor.format('list', 'bullet');
  }

  public onAlignClick(direction: string) {
    switch(direction) {
      case 'right':
        this.editor.format('align', 'right');
        break;
      case 'center':
        this.editor.format('align', 'center');
        break;
      case 'justify':
        this.editor.format('align', 'justify');
        break;
      case 'left':
      default:
        this.editor.format('align', null);
    }
  }

  public insertImage() {
    var input: HTMLInputElement = document.createElement('input');

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      let file = input.files![0];
      if(file) {
        var reader = new FileReader();
        reader.onload = (e) => {
          var range = this.editor.getSelection();
          this.editor.insertEmbed(range!.index, 'image', e.target?.result);
        }
        reader.readAsDataURL(file);
      }
    }
  }

  async writeValue(obj: Delta) {
    this.editor.setContents(obj);
  }

  async registerOnChange(fn: any) {
    this.editor.on('text-change', (delta, oldContent, source) => {
      fn(this.editor.getContents());
    });
  }

  async registerOnTouched(fn: any) {
    this.editorElement.nativeElement.addEventListener('focusin', () => {
      fn();
    });
  }

  async setDisabledState?(isDisabled: boolean) {
    isDisabled ? this.editor.disable() : this.editor.enable();
  }
}
