import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsManager {

  constructor(
    private apiClient: ApiClientService,
  ) {}

  public getProducts(page: number, itemsPerPage: number) {
    const params = new URLSearchParams();
    params.set('page', page.toString());
    params.set('itemsPerPage', itemsPerPage.toString());

    return this.apiClient.get<{
      data: any[],
      totalAmount: number,
    }>('/admin/products', params);
  }

  public getProduct(id: string) {
    return this.apiClient.get<{
      tags: {id: string; name: string}[];
      summary: string;
      screenshots: string[];
      name: string;
      logo: string;
      id: string;
      description: string;
      componentId: string;
      navLinks: {
        name: string;
        href: string;
      }[];
      deployments: {
        id: string;
        isDefault: boolean;
        name: string;
        scarmId: string;
        traits: string[];
        typeId: string;
        url: string;
        instructions: Record<string, object>;
        documentations: {
          name: string;
          address: string;
        }[];
      }[],
      licenses: {
        licenseName: string,
        licenseUrl: string
      }[];
      productVersion: string;
    }>(`/admin/products/${id}`);
  }

  public deleteProduct(id: string) {
    return this.apiClient.delete<{}>(`/admin/products/${id}`);
  }

  public deleteProducts(id: string[]) {
    const params = new URLSearchParams();
    params.set('ids', id.toString());

    return this.apiClient.delete<{}>(`/admin/products`);
  }

  public async getScarmProductsHints(page?: number, itemsPerPage?: number) {
    const params = new URLSearchParams();

    if(page && itemsPerPage) {
      params.set('page', page.toString());
      params.set('itemsPerPage', itemsPerPage.toString());
    }

    return (await this.apiClient.get<{components: {name: string}[]}>('/admin/products/hints', params)).components;
  }

  public async getComponentsHints() {
    return (await this.apiClient.get<string[]>('/admin/components'));
  }

  public updateProduct(id: string, options: {
    name: Record<string, string>;
    deployments: {}[];
    description: Record<string, string>;
    tags: string[];
    licenses: Record<string, string | null>[];
    productVersion: string;
  }) {
    return this.apiClient.put(`/admin/products/${id}`, options);
  }

  public createProduct(options: {
    name: Record<string, string>;
    summary: Record<string, string>;
    deployments: {
      name: Record<string, string>;
      isDefault: boolean;
      scarmId: string;
      typeId: string;
    }[];
    description: Record<string, string>;
    tagIds: string[];
    licenses: Record<string, string | null>[];
    productVersion: string;
  }) {
    return this.apiClient.post(`/admin/products`, options);
  }
}
