import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { SubscriptionToAdd } from './subscription-to-add';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { sameValues } from 'src/app/same-values.validator';
import { Router } from '@angular/router';
import { UserManager } from 'src/app/user-manager.service';
import { ServerErrorMessage } from 'src/app/messages';
import { strongPassword } from 'src/app/strong-password.validator';

@Component({
  selector: 'app-add-user-page',
  templateUrl: './add-user-page.component.html',
  styleUrls: ['./add-user-page.component.scss']
})
export class AddUserPageComponent implements OnInit {
  tabs: MenuItem[] | undefined;

  activeTab: MenuItem | undefined;

  sourceProducts: SubscriptionToAdd[] = [];
  targetProducts: SubscriptionToAdd[] = [];

  isLoading: boolean = false;

  formGroup!: FormGroup;
  emailsFormGroup!: FormGroup;
  passwordsFormGroup!: FormGroup;

  constructor(
    private userManager: UserManager,
    private router: Router,
    private messageService: MessageService,
  ) {}

  public ngOnInit() {

    this.emailsFormGroup = new FormGroup({
      email: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      confirmEmail: new FormControl('', []),
    }, [
      sameValues(['email', 'confirmEmail'], { onlyDirty: true }),
    ]);

    this.passwordsFormGroup = new FormGroup({
      password: new FormControl('', [
        strongPassword(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
      ]),
      confirmPassword: new FormControl('', []),
    }, [
      sameValues(['password', 'confirmPassword'], { onlyDirty: true }),
    ]);

    this.formGroup = new FormGroup({
      emails: this.emailsFormGroup,
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      passwords: this.passwordsFormGroup,
      organizationName: new FormControl(''),
      pickedSubscriptions: new FormControl([]),
      activateAccount: new FormControl(false),
      role: new FormControl(null),
    });

    this.tabs = [
      { label: 'Dane osobowe', icon: 'pi pi-fw pi-language', id: 'name' },
      { label: 'Rola', icon: 'pi pi-fw pi-lock', id: 'role' },
      { label: 'Dane organizacji', icon: 'pi pi-fw pi-align-justify', id: 'organization' },
      { label: 'Subskrypcje', icon: 'pi pi-fw pi-external-link', id: 'subscriptions' },
      { label: 'Inne', icon: 'pi pi-fw pi-wrench', id: 'other' },
    ];

    this.activeTab = this.tabs[0];
  }

  onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  public async submit() {
    try {
      this.isLoading = true;

      const emailsGroup = this.formGroup.get('emails') as FormGroup;

      const email = emailsGroup.get('email')?.value;

      const passwordsGroup = this.formGroup.get('passwords') as FormGroup;

      const password = passwordsGroup.get('password')?.value;
      const firstName = this.formGroup.get('firstName')?.value;
      const lastName = this.formGroup.get('lastName')?.value;
      const organizationName = this.formGroup.get('organizationName')?.value;
      const activate = this.formGroup.get('activateAccount')?.value;
      const role = this.formGroup.get('role')?.value as string;

      const response = await this.userManager.createUser({
        email,
        password,
        firstName,
        lastName,
        organizationName,
        activate,
        role: role.toUpperCase(),
      });

      if(response) this.router.navigate(['/users']);

    } catch (err) {
      this.messageService.add(ServerErrorMessage);
    } finally {
      this.isLoading = false;
    }
  }
}
