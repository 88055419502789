import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public publishNewState(state: boolean) {
    this.isLoggedInSubject.next(state);
  }

  public isLoggedIn() {
    return this.isLoggedInSubject.getValue();
  }
}
