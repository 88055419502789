import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

@Injectable({
  providedIn: 'root'
})
export class ComponentsManager {
  constructor(
    private apiClient: ApiClientService
  ) {}

  public getComponentsList() {
    return this.apiClient.get('/admin/components');
  }
}
