import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageListPageComponent } from './language-list-page/language-list-page.component';
import { AddLanguagePageComponent } from './add-language-page/add-language-page.component';
import { BrowserModule } from '@angular/platform-browser';
import { TableModule } from 'primeng/table';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    LanguageListPageComponent,
    AddLanguagePageComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    TableModule,
    RouterModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class LanguagePageModule { }
