import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SessionService } from './session.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesManager } from './languages-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'mvportal-backoffice';
  items: MenuItem[] | undefined;

  public isLoggedIn: boolean = false;
  private subscriptionToLogged!: Subscription;

  constructor(private sessionService: SessionService,
    private router: Router,
    private translate: TranslateService,
    private languagesManager: LanguagesManager,
    private appRef: ApplicationRef,
  ) {}

  async ngOnInit() {
    const languagePromise = this.loadLanguages();
    const redirectPromise = this.redirectRoute();

    await Promise.all([redirectPromise, languagePromise]);
  }

  private async redirectRoute() {
    const lastRoute: RouterEvent = <RouterEvent> await firstValueFrom(this.router.events);

    this.subscriptionToLogged = this.sessionService.isLoggedInSubject.subscribe((value) => {
      if(!this.isLoggedIn && value) {
        this.router.navigate([lastRoute.url]);
      }
      if(this.isLoggedIn && !value) {
        this.router.navigate(['/login']);
      }
      this.isLoggedIn = value;
    });
  }

  private async loadLanguages() {
    try {
      const languages = await this.languagesManager.getLanguages(1, 0);

      this.translate.addLangs(languages.map((language) => (language.alpha2)));
      this.translate.setDefaultLang('en');
      this.translate.use('en');

    } catch (err) {
      console.error(err);
    }
    this.translate.onTranslationChange.subscribe(() => {
      setTimeout(() => {
        this.appRef.tick();
      });
    })
  }

  ngOnDestroy(): void {
    this.subscriptionToLogged.unsubscribe();
  }

}
