import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LanguagesManager } from 'src/app/languages-manager.service';

@Component({
  selector: 'app-add-language-page',
  templateUrl: './add-language-page.component.html',
  styleUrls: ['./add-language-page.component.scss']
})
export class AddLanguagePageComponent implements OnInit {
  constructor(private languageManager: LanguagesManager, private messageService: MessageService, private router: Router,) {}

  formGroup!: FormGroup;

  public isLoading: boolean = false;

  public languages: any[] = [];

  private getFormValidationErrors() {
    return Object.keys(this.formGroup.controls).reduce<any[]>((acc, key) => {
      const controlErrors: ValidationErrors = <ValidationErrors> this.formGroup.get(key)!.errors;
      if (controlErrors != null) {
        const errors = Object.keys(controlErrors).map(keyError => {
          return {
            key,
            keyError
          };
        });
        acc.push(...errors);
      }
      return acc;
    }, []);
  }

  public async ngOnInit() {
    this.formGroup = new FormGroup({
        alpha2: new FormControl('', [
          Validators.minLength(2),
          Validators.maxLength(2),
          Validators.required,
        ]),
        alpha3: new FormControl('', [
          Validators.minLength(3),
          Validators.maxLength(3),
          Validators.required,
        ]),
        localName: new FormControl('', [
          Validators.required,
        ]),
    });
  }

  public async submit() {
    if(this.formGroup.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Błąd zapisu',
        detail: 'Niepoprawne dane do zapisu',
      });
      return;
    }

    try {
      this.isLoading = true;
      const response = await this.languageManager.createLanguage({
        alpha2: this.formGroup.get('alpha2')!.value,
        alpha3: this.formGroup.get('alpha3')!.value,
        localName: this.formGroup.get('localName')!.value,
      });
  
      if(response) this.router.navigate(['/languages']);
    } catch (err) {
      this.messageService.add({
        severity: 'error',
        summary: 'Błąd zapisu',
        detail: 'Serwer nie mógł przetworzyć zapytania.',
      });
    } finally {
      this.isLoading = false;
    }
  }
}
