<form class="flex flex-column" [formGroup]="formGroup" (ngSubmit)="submit()">
  <div class="flex flex-row align-items-center justify-content-between px-3 pt-3 bg-white pb-2">
    <div class="flex flex-row align-items-center">
      <a [routerLink]="'..'" class="hover:text-blue-400 transition-colors transition-duration-200">
        <i class="pi pi-arrow-left text-lg"></i>
      </a>
      <p class="m-0 ml-3 font-bold text-2xl">Dodaj tag</p>
    </div>
    <div>
      <button pButton pRipple label="Zapisz" class="p-button-success" type="submit"></button>
    </div>
  </div>
  <div class="w-full bg-white">
    <p-tabMenu [model]="tabs" [activeItem]="activeTab" (activeItemChange)="onTabChange($event)"></p-tabMenu>
  </div>

  <!-- Name section -->
  <div *ngIf="activeTab && activeTab.id == 'name'" class="mt-3 flex flex-column w-full gap-3 px-3" formGroupName="names">
    <small class="text-red-500" *ngIf="nameFormGroup.invalid">Przynajmniej jedno musi zostać uzupełnione.</small>
    <div class="flex flex-row w-full justify-content-between align-items-center gap-3" *ngFor="let language of getEntriesOfNames()">
      <div class="flex flex-column w-3rem">
        <span class="font-semibold text-3xl">
          {{ language }}
        </span>
      </div>
      <div class="flex flex-column flex-grow-1">
        <span class="p-input-icon-left ml-2 w-full">
          <i class="pi pi-language"></i>
          <input pInputText type="text" placeholder="Tłumaczenie" class="w-full" [id]="('name_' + language) | lowercase" [formControlName]="language" />
        </span>
      </div>
    </div>
  </div>

  <!-- Products section -->
  <div *ngIf="activeTab && activeTab.id == 'products'" class="mt-3 flex flex-row w-full justify-content-between align-items-center gap-3 px-3">
    <p-pickList [source]="sourceProducts" [target]="targetProducts" sourceHeader="Dostępne" targetHeader="Wybrane" [dragdrop]="true" [responsive]="true"
      [showSourceControls]="false" [showTargetControls]="false" [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }" breakpoint="1400px" class="w-full">
      <ng-template let-product pTemplate="item">
          <div class="flex flex-wrap p-2 align-items-center gap-3">
              <img class="w-4rem shadow-2 flex-shrink-0 border-round" src="https://primefaces.org/cdn/primeng/images/demo/product/{{ product.image }}" alt="{item.name}" />
              <div class="flex-1 flex flex-column gap-2">
                  <span class="font-bold">{{ product.name }}</span>
                  <div class="flex align-items-center gap-2">
                      <i class="pi pi-tag text-sm"></i>
                      <span>{{ product.category }}</span>
                  </div>
              </div>
              <span class="font-bold text-900">{{ '$' + product.price }}</span>
          </div>
      </ng-template>
    </p-pickList>
  </div>
</form>