import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

interface HealthChecksResponse {
  status: string;
  details: {
      frontend?: { status: string; message?: string };
      scarm?: { status: string; message?: string };
  };
  error?: {
      frontend?: { status: string; message?: string };
      scarm?: { status: string; message?: string };
  };
  info?: {
      frontend?: { status: string; message?: string };
      scarm?: { status: string; message?: string };
  };
}

@Injectable({
  providedIn: 'root'
})
export class HealthCheckManagerService {

  constructor(
    private apiClient: ApiClientService,
  ) {}

  public async getHealthCheck() {
    return this.apiClient.get<HealthChecksResponse>('/health-checks');
  }
}
