<div class="flex flex-row w-full">
    <div class="flex flex-column w-6 justify-content-center align-items-center z-5 shadow-5">
        <div class="relative">
            <img src="assets/logo.svg" width="380" alt="logo">
            <span class="absolute" style="bottom: -5px; right: 0px;">
                AdminPanel
            </span>
        </div>
        <div class="flex flex-column mt-4 align-items-center" *ngIf="isLoading">
            <div>
                <i class="pi pi-spin pi-spinner text-gray-500" style="font-size: 10rem"></i>
            </div>
            <span class="font-bold text-3xl mt-3">
                Trwa logowanie
            </span>
        </div>
        <form [formGroup]="formGroup" (ngSubmit)="submitLogin()" class="login-box mt-6" *ngIf="!isLoading">
            <div class="grid">
                <div class="col-12">
                    <input pInputText placeholder="Email" formControlName="email" class="w-full" />
                </div>
                <div class="col-12">
                    <input pPassword type="password" placeholder="Hasło" formControlName="password" [feedback]="false" class="w-full" />
                </div>
            </div>
            <div class="flex flex-row justify-content-end mt-2">
                <div class="w-6">
                    <p-button label="Zaloguj" icon="pi pi-lock" styleClass="w-full" type="submit"></p-button>
                </div>
            </div>
        </form>
    </div>
    <div class="flex flex-row w-full h-full image"></div>
</div>