import { Component, OnInit } from '@angular/core';
import { TableLazyLoadEvent, TablePageEvent } from 'primeng/table';
import { Subject, debounceTime } from 'rxjs';
import { TranslationManager } from 'src/app/translation-manager.service';

@Component({
  selector: 'app-translation-list-page',
  templateUrl: './translation-list-page.component.html',
  styleUrls: ['./translation-list-page.component.scss']
})
export class TranslationListPageComponent implements OnInit {
  public isLoading: boolean = false;

  public page: number = 1;
  public itemsPerPage: number = 25;
  public totalRecords: number = 200;

  public fullTextSearch: string = '';
  private fullTextSearchChanged: Subject<string> = new Subject();

  public translations: {
    id: string;
    translations: {
      alpha2: string;
      value: string;
    }[]
  }[] = [];

  constructor(
    private translationManager: TranslationManager,
  ) {}

  public onSearchTextChange() {
    this.fullTextSearchChanged.next(this.fullTextSearch);
  }

  public ngOnInit(): void {
    this.refreshList();
    this.fullTextSearchChanged.pipe((debounceTime(1000))).subscribe(() => {
      this.refreshList();
    });
  }

  public async refreshList() {
    this.isLoading = true;
    const {data, totalAmount} = await this.translationManager.getTranslations(this.page, this.itemsPerPage, this.fullTextSearch);
    this.totalRecords = totalAmount;
    this.translations = data;
    this.isLoading = false;
  }

  public async onPageChange(event: TablePageEvent) {
    this.page = event.first! / event!.rows! + 1;
    this.refreshList();
  }

  public async deleteTranslation(id: string) {
    await this.translationManager.deleteTranslation(id);
    await this.refreshList();
  }
}
