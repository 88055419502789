<form class="flex flex-column relative flex-1" [formGroup]="formGroup" (ngSubmit)="submit()">
  <div class="bg-black-alpha-40 absolute top-0 left-0 right-0 bottom-0 z-5 text-center" *ngIf="isLoading">
    <i class="pi pi-spin pi-spinner top-50 absolute"></i>
  </div>
  <div class="flex flex-row align-items-center justify-content-between px-3 pt-3 bg-white pb-2">
    <div class="flex flex-row align-items-center">
      <a [routerLink]="'..'" class="hover:text-blue-400 transition-colors transition-duration-200">
        <i class="pi pi-arrow-left text-lg"></i>
      </a>
      <p class="m-0 ml-3 font-bold text-2xl">Dodaj użytkownika</p>
    </div>
    <div>
      <button pButton icon="pi pi-save" pRipple label="Zapisz" class="p-button-success" [disabled]="formGroup.invalid"></button>
    </div>
  </div>
  <div class="w-full bg-white">
    <p-tabMenu [model]="tabs" [activeItem]="activeTab" (activeItemChange)="onTabChange($event)"></p-tabMenu>
  </div>

  <!-- Name section -->
  <div *ngIf="activeTab && activeTab.id == 'name'" class="mt-3 flex flex-column w-full justify-content-between align-items-center gap-3 px-3">
    <div class="flex flex-row w-full gap-3" formGroupName="emails">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-at"></i>
        <input pInputText type="text" formControlName="email" placeholder="Adres email*" class="w-full" />
      </span>
      <span class="p-input-icon-left w-full">
        <i class="pi pi-at"></i>
        <input pInputText type="text" formControlName="confirmEmail" placeholder="Powtórz adres email*" class="w-full" />
      </span>
    </div>
    <small class="align-self-start text-red-500" *ngIf="emailsFormGroup.invalid && emailsFormGroup.dirty">Adres email muszą być takie same</small>
    <span class="p-input-icon-left w-full">
      <i class="pi pi-user"></i>
      <input pInputText type="text" formControlName="firstName" placeholder="Imię" class="w-full" />
    </span>
    <span class="p-input-icon-left w-full">
      <i class="pi pi-user"></i>
      <input pInputText type="text" formControlName="lastName" placeholder="Nazwisko" class="w-full" />
    </span>
    <div class="flex flex-row w-full gap-3" formGroupName="passwords">
      <span class="p-input-icon-left w-full">
        <i class="pi pi-lock"></i>
        <input pInputText type="password" formControlName="password" placeholder="Hasło*" class="w-full" />
      </span>
      <span class="p-input-icon-left w-full">
        <i class="pi pi-lock"></i>
        <input pInputText type="password" formControlName="confirmPassword" placeholder="Powtórz hasło*" class="w-full" />
      </span>
    </div>
    <small class="align-self-start text-red-500" *ngIf="passwordsFormGroup.invalid && passwordsFormGroup.dirty">Hasła muszą być takie same</small>
  </div>

  <!-- Role section -->
  <div *ngIf="activeTab && activeTab.id == 'role'" class="mt-3 flex flex-row w-full gap-3 px-3">
    <div class="flex align-items-center">
      <p-radioButton value="user" formControlName="role" inputId="user"></p-radioButton>
      <label for="user" class="ml-2">Użytkownik</label>
    </div>
    <div class="flex align-items-center">
      <p-radioButton value="admin" formControlName="role" inputId="admin"></p-radioButton>
      <label for="admin" class="ml-2">Administrator</label>
    </div>
  </div>

  <!-- Description section -->
  <div *ngIf="activeTab && activeTab.id == 'organization'" class="mt-3 flex flex-column w-full gap-3 px-3">
    <span class="p-input-icon-left w-full">
      <i class="pi pi-building"></i>
      <input pInputText type="text" formControlName="organizationName" placeholder="Nazwa organizacji" class="w-full" />
    </span>
  </div>

  <!-- External link section -->
  <div *ngIf="activeTab && activeTab.id == 'subscriptions'" class="mt-3 flex flex-row w-full gap-3 px-3">
    <p-pickList [source]="sourceProducts" [target]="targetProducts" sourceHeader="Dostępne" targetHeader="Wybrane" [dragdrop]="true" [responsive]="true"
      [showSourceControls]="false" [showTargetControls]="false" [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }" breakpoint="1400px" class="w-full">
      <ng-template let-product pTemplate="item">
          <div class="flex flex-wrap p-2 align-items-center gap-3">
              <img class="w-4rem shadow-2 flex-shrink-0 border-round" src="https://primefaces.org/cdn/primeng/images/demo/product/{{ product.image }}" alt="{item.name}" />
              <div class="flex-1 flex flex-column gap-2">
                  <span class="font-bold">{{ product.name }}</span>
                  <div class="flex align-items-center gap-2">
                      <i class="pi pi-tag text-sm"></i>
                      <span>{{ product.category }}</span>
                  </div>
              </div>
              <span class="font-bold text-900">{{ '$' + product.price }}</span>
          </div>
      </ng-template>
    </p-pickList>
  </div>

  <!-- Other section -->
  <div *ngIf="activeTab && activeTab.id == 'other'" class="mt-3 flex flex-row w-full gap-3 px-3">
    <div class="flex flex-row w-full align-items-center gap-3">
      <p-inputSwitch formControlName="activateAccount" id="activateAccount"></p-inputSwitch>
      <label for="activateAccount">Czy aktywować?</label>
    </div>
  </div>
</form>