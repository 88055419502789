import { Component } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { SubscriptionToAdd } from '../add-user-page/subscription-to-add';
import { UserManager } from 'src/app/user-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { sameValues } from 'src/app/same-values.validator';
import { FormErrorMessage, ServerErrorMessage } from 'src/app/messages';
import { strongPassword } from 'src/app/strong-password.validator';
import { isEmail } from 'src/app/is-email.validator';

@Component({
  selector: 'app-edit-user-page',
  templateUrl: './edit-user-page.component.html',
  styleUrls: ['./edit-user-page.component.scss']
})
export class EditUserPageComponent {
  tabs: MenuItem[] | undefined;

  activeTab: MenuItem | undefined;

  isLoading: boolean = false;

  sourceProducts: SubscriptionToAdd[] = [];
  targetProducts: SubscriptionToAdd[] = [];

  userId!: string;

  formGroup!: FormGroup;

  constructor(
    private userManager: UserManager,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;

    const { userId } = await firstValueFrom(this.route.params);

    this.userId = userId;

    const { email, firstName, lastName, organizationName } = await this.userManager.getUser(userId);

    this.formGroup = new FormGroup({
      emails: new FormGroup({
        email: new FormControl(email, [
          isEmail({ onlyDirty: true }),
        ]),
        confirmEmail: new FormControl('', []),
      }, [
        sameValues(['email', 'confirmEmail'], { onlyDirty: true }),
      ]),
      firstName: new FormControl(firstName),
      lastName: new FormControl(lastName),
      organizationName: new FormControl(organizationName),
      passwords: new FormGroup({
        password: new FormControl('', [
          strongPassword(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, { onlyDirty: true }),
        ]),
        confirmPassword: new FormControl('', []),
      }, [
        sameValues(['password', 'confirmPassword'], { onlyDirty: true})
      ]),
    });

    this.tabs = [
      { label: 'Dane osobowe', icon: 'pi pi-fw pi-language', id: 'name' },
      { label: 'Dane organizacji', icon: 'pi pi-fw pi-align-justify', id: 'organization' },
      { label: 'Subskrypcje', icon: 'pi pi-fw pi-external-link', id: 'subscriptions' },
    ];

    this.activeTab = this.tabs[0];

    this.isLoading = false;
  }

  onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  async submit() {
    try {
      this.isLoading = true;

      const options: Record<string, string> = {};

      const emailsGroup = this.formGroup.get('emails') as FormGroup;

      const emailControl = emailsGroup.get('email') as FormControl;
      const firstNameControl = this.formGroup.get('firstName') as FormControl;
      const lastNameControl = this.formGroup.get('lastName') as FormControl;
      const organizationNameControl = this.formGroup.get('organizationName') as FormControl;

      const passwordsGroup = this.formGroup.get('passwords') as FormGroup;

      const passwordControl = passwordsGroup.get('password') as FormControl;

      if(emailsGroup.dirty) options['email'] = emailControl.value;
      if(firstNameControl.dirty) options['firstName'] = firstNameControl.value;
      if(lastNameControl.dirty) options['lastName'] = lastNameControl.value;
      if(organizationNameControl.dirty) options['organizationName'] = organizationNameControl.value;
      if(passwordsGroup.dirty) options['newPassword'] = passwordControl.value;

      const response = await this.userManager.updateUser(this.userId, options);

      if(response) this.router.navigate(['/users']);
      
      return;

    } catch (err) {
      this.messageService.add(ServerErrorMessage);
    } finally {
      this.isLoading = false;
    }
  }
}
