import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SessionService } from './session.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const sessionService: SessionService = inject(SessionService)
  const router: Router = inject(Router);
  if (sessionService.isLoggedIn()) {
    return true;
  }
  router.navigate(['login']);
  return false;
};
