<div class="flex flex-row w-full py-4 px-4 gap-4 flex-wrap">

  <div class="relative border-round-lg border-1 flex flex-column flex-initial hover:shadow-2 transition-duration-200 card border-bluegray-200">
    <div class="p-3 flex flex-row justify-content-between align-items-center">
      <span class="font-semibold text-2xl text-bluegray-500">
        Produkty
      </span>
      <div class="flex flex-row gap-3">
        <a [routerLink]="['/products']" class="no-underline text-bluegray-500">
          <i class="pi pi-list font-bold"></i>
        </a>
        <a [routerLink]="['/products/add']" class="no-underline text-bluegray-500">
          <i class="pi pi-plus font-bold"></i>
        </a>
      </div>
    </div>
    <div class="p-2 flex flex-column align-items-center justify-content-center w-full h-full ">
      <span class="text-7xl font-semibold text-bluegray-700">
        {{ productsAmount }}
      </span>
    </div>
  </div>

  <div class="relative border-round-lg border-1 flex flex-column flex-initial hover:shadow-2 transition-duration-200 card border-bluegray-200">
    <div class="p-3 flex flex-row justify-content-between align-items-center">
      <span class="font-semibold text-2xl text-bluegray-500">
        Środowiska
      </span>
      <div class="flex flex-row gap-3">
        <a [routerLink]="['/deployment-types']" class="no-underline text-bluegray-500">
          <i class="pi pi-list font-bold"></i>
        </a>
        <a [routerLink]="['/deployment-types/add']" class="no-underline text-bluegray-500">
          <i class="pi pi-plus font-bold"></i>
        </a>
      </div>
    </div>
    <div class="p-2 flex flex-column align-items-center justify-content-center w-full h-full ">
      <span class="text-7xl font-semibold text-bluegray-700">
        {{ deploymentTypesAmount }}
      </span>
    </div>
  </div>
  

  <div class="border-round-lg border-1 flex flex-column flex-initial hover:shadow-2 transition-duration-200 card border-bluegray-200">
    <div class="p-3 flex flex-row justify-content-between align-items-center">
      <span class="font-semibold text-2xl text-bluegray-500">
        Tagi
      </span>
      <div class="flex flex-row gap-3">
        <a [routerLink]="['/product-tags']" class="no-underline text-bluegray-500">
          <i class="pi pi-list font-bold"></i>
        </a>
        <a [routerLink]="['/product-tags/add']" class="no-underline text-bluegray-500">
          <i class="pi pi-plus font-bold"></i>
        </a>
      </div>
    </div>
    <div class="p-2 flex flex-column align-items-center justify-content-center w-full h-full ">
      <span class="text-7xl font-semibold text-bluegray-700">
        {{ productTagsAmount }}
      </span>
    </div>
  </div>

  <div class="border-round-lg border-1 flex flex-column flex-initial hover:shadow-2 transition-duration-200 card border-bluegray-200">
    <div class="p-3 flex flex-row justify-content-between align-items-center">
      <span class="font-semibold text-2xl text-bluegray-500">
        Tłumaczenia
      </span>
      <div class="flex flex-row gap-3">
        <a [routerLink]="['/translations']" class="no-underline text-bluegray-500">
          <i class="pi pi-list font-bold"></i>
        </a>
        <a [routerLink]="['/translations/add']" class="no-underline text-bluegray-500">
          <i class="pi pi-plus font-bold"></i>
        </a>
      </div>
    </div>
    <div class="p-2 flex flex-column align-items-center justify-content-center w-full h-full ">
      <span class="text-7xl font-semibold text-bluegray-700">
        {{ translationsAmount }}
      </span>
    </div>
  </div>

  <div class="border-round-lg border-1 flex flex-column flex-initial hover:shadow-2 transition-duration-200 card border-bluegray-200">
    <div class="p-3 flex flex-row justify-content-between align-items-center">
      <span class="font-semibold text-2xl text-bluegray-500">
        Języki
      </span>
      <div class="flex flex-row gap-3">
        <a [routerLink]="['/languages']" class="no-underline text-bluegray-500">
          <i class="pi pi-list font-bold"></i>
        </a>
        <a [routerLink]="['/languages/add']" class="no-underline text-bluegray-500">
          <i class="pi pi-plus font-bold"></i>
        </a>
      </div>
    </div>
    <div class="p-2 flex flex-column align-items-center justify-content-center w-full h-full ">
      <span class="text-7xl font-semibold text-bluegray-700">
        {{ languagesAmount }}
      </span>
    </div>
  </div>

  <div class="border-round-lg border-1 flex flex-column flex-initial hover:shadow-2 transition-duration-200 card border-bluegray-200">
    <div class="p-3 flex flex-row justify-content-between align-items-center">
      <span class="font-semibold text-2xl text-bluegray-500">
        Subskrypcje
      </span>
      <div class="flex flex-row gap-3">
        <a [routerLink]="['/subscriptions']" class="no-underline text-bluegray-500">
          <i class="pi pi-list font-bold"></i>
        </a>
        <a [routerLink]="['/subscriptions/add']" class="no-underline text-bluegray-500">
          <i class="pi pi-plus font-bold"></i>
        </a>
      </div>
    </div>
    <div class="p-2 flex flex-column align-items-center justify-content-center w-full h-full ">
      <span class="text-7xl font-semibold text-bluegray-700">
        {{ subscriptionsAmount }}
      </span>
    </div>
  </div>

  <div class="border-round-lg border-1 flex flex-column flex-initial hover:shadow-2 transition-duration-200 card border-bluegray-200">
    <div class="p-3 flex flex-row justify-content-between align-items-center">
      <span class="font-semibold text-2xl text-bluegray-500">
        Użytkownicy
      </span>
      <div class="flex flex-row gap-3">
        <a [routerLink]="['/users']" class="no-underline text-bluegray-500">
          <i class="pi pi-list font-bold"></i>
        </a>
        <a [routerLink]="['/users/add']" class="no-underline text-bluegray-500">
          <i class="pi pi-plus font-bold"></i>
        </a>
      </div>
    </div>
    <div class="p-2 flex flex-column align-items-center justify-content-center w-full h-full ">
      <span class="text-7xl font-semibold text-bluegray-700">
        {{ usersAmount }}
      </span>
    </div>
  </div>

</div>