import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  private baseUrl: URL;

  constructor(
    private http: HttpClient,
  ) {
    const protocol = environment.secure ? 'https://' : 'http://';
    this.baseUrl = new URL(`${protocol}${environment.apiUrl}`);
  }

  public get<TResponseBody>(endpoint: string, params?: URLSearchParams): Promise<TResponseBody> {
    const url = new URL(this.baseUrl);
    url.pathname = endpoint;
    if(params) {
      url.search = `?${params.toString()}`;
    }
    return lastValueFrom(this.http.get<TResponseBody>(url.toString(), {
      withCredentials: true,
    }));
  }

  public post<TResponseBody>(endpoint: string, body?: Record<string, any> | undefined): Promise<TResponseBody> {
    const url = new URL(this.baseUrl);
    url.pathname = endpoint;
    return lastValueFrom(this.http.post<TResponseBody>(url.toString(), body, {
      withCredentials: true,
    }));
  }

  public put<TResponseBody>(endpoint: string, body: Record<string, any>, params?: URLSearchParams): Promise<TResponseBody> {
    const url = new URL(this.baseUrl);
    url.pathname = endpoint;
    if(params) {
      url.search = `?${params.toString()}`;
    }
    return lastValueFrom(this.http.put<TResponseBody>(url.toString(), body, {
      withCredentials: true,
    }));
  }

  public delete<TResponseBody>(endpoint: string, params?: URLSearchParams): Promise<TResponseBody> {
    const url = new URL(this.baseUrl);
    url.pathname = endpoint;
    if(params) {
      url.search = `?${params.toString()}`;
    }
    return lastValueFrom(this.http.delete<TResponseBody>(url.toString(), {
      withCredentials: true,
    }));
  }
}
