import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { atLeastOne } from 'src/app/at-least-one.validator';
import { LanguagesManager } from 'src/app/languages-manager.service';
import { ServerErrorMessage } from 'src/app/messages';
import { PostsManager } from 'src/app/posts-manager.service';

@Component({
  selector: 'app-edit-posts-tag-page',
  templateUrl: './edit-posts-tag-page.component.html',
  styleUrls: ['./edit-posts-tag-page.component.scss']
})
export class EditPostsTagPageComponent {
  public tabs!: MenuItem[];

  public activeTab!: MenuItem;

  public formGroup!: FormGroup;
  public nameFormGroup!: FormGroup;

  public isLoading: boolean = true;
  public isSaving: boolean = false;

  public postTagId: string = '';

  public imageFormControl!: FormControl;

  constructor(
    private langaugeManager: LanguagesManager,
    private messageService: MessageService,
    private postsManager: PostsManager,
    private router: Router,
    private route: ActivatedRoute,
    private translator: TranslateService,
  ) {}

  public async ngOnInit() {
    this.isLoading = true;

    this.formGroup = new FormGroup({});

    const { postTagId } = await firstValueFrom(this.route.params);

    this.postTagId = postTagId;

    const nameFormGroup = this.nameFormGroup = new FormGroup({}, [atLeastOne]);

    const languagesPromise = this.langaugeManager.getLanguages(1, 0);
    const valuesPromise = this.postsManager.getPostsTag(postTagId);

    const [languages, postTag] = await Promise.all([languagesPromise, valuesPromise]);

    this.imageFormControl = new FormControl(postTag.image);

    this.formGroup.addControl('image', this.imageFormControl);

    for (const language of languages) {
      const translation = await firstValueFrom(this.translator.getTranslation(language.alpha2));
      nameFormGroup.addControl(language.alpha2, new FormControl(translation[postTag.name] ?? ''));
    }

    this.formGroup.addControl('names', nameFormGroup);

    this.tabs = [
      {
        label: 'Nazwa',
        icon: 'pi pi-fw pi-language',
        id: 'name'
      },
      {
        label: 'Logo',
        icon: 'pi pi-fw pi-image',
        id: 'logo'
      },
    ];

    this.activeTab = this.tabs[0];

    this.isLoading = false;
  }

  public async submit() {
    try {
      this.isSaving = true;
      const payload:any = {};

      const namesFormGroup = this.formGroup.get('names') as FormGroup;

      if(namesFormGroup.dirty) {
        payload['name'] = {};
        for(const [key, formControl] of Object.entries(namesFormGroup.controls)) {
          if(formControl.value && formControl.value !== '') payload['name'][key] = formControl.value;
        }
      }

      if(this.imageFormControl.dirty) {
        payload['image'] = this.imageFormControl.value;
      }

      const response = await this.postsManager.editPostTag(this.postTagId, payload);

      if (response) this.router.navigate(['/posts-tags']);
    } catch (err) {
      console.error(err);
      this.messageService.add(ServerErrorMessage);
    } finally {
      this.isSaving = false;
    }
  }

  public onImageUpload(ev: Event) {
    this.imageFormControl.markAsTouched();
    const event = ev as (Event & {target: HTMLInputElement & { files: FileList | null}})

    if(!event?.target?.files) return;

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (ev) => {
      this.imageFormControl.setValue(ev.target?.result!);
      this.imageFormControl.markAsDirty();
    }

    event.target.value = '';
  }

  public onTabChange(event: MenuItem) {
    this.activeTab = event;
  }

  public getFormControlsByName(name: string) {
    return Object.keys((this.formGroup.get(name) as FormGroup).controls);
  }
}
