import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TabMenuModule } from 'primeng/tabmenu';
import { ButtonModule } from 'primeng/button';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddSubscriptionPageComponent } from './add-subscription-page/add-subscription-page.component';
import { SubscriptionListPageComponent } from './subscription-list-page/subscription-list-page.component';
import { InputTextareaModule } from 'primeng/inputtextarea';



@NgModule({
  declarations: [
    AddSubscriptionPageComponent,
    SubscriptionListPageComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    TabMenuModule,
    ButtonModule,
    PickListModule,
    TableModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
  ]
})
export class SubscriptionPageModule { }
